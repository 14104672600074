import sortBy from 'lodash-es/sortBy';
import moment from 'moment';

import {
    Component, ElementRef, Input, NgZone, OnInit, QueryList, ViewChild, ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import { IScreenShotSettingsC2C } from '@profis-engineering/c2c-gl-model/gl-model';
import { LoadsVisibilityInfo, View2dModeType } from '@profis-engineering/gl-model/gl-model';
import {
    CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    CodeList, getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { Language } from '@profis-engineering/pe-ui-common/entities/code-lists/language';
import {
    ReportPaperSize as ReportPaperSizeCodeList
} from '@profis-engineering/pe-ui-common/entities/code-lists/report-paper-size';
import {
    IExportReportCompanyLayoutComponentInput
} from '@profis-engineering/pe-ui-common/entities/export-report-company-layout';
import {
    IExportReportProjectDetailsInput
} from '@profis-engineering/pe-ui-common/entities/export-report-project-details';
import {
    IExportTrimbleConnectComponentInput
} from '@profis-engineering/pe-ui-common/entities/export-trimble-connect';
import {
    Feature
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    ReportTemplateEntity, ReportTemplateOptionEntity
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate';
import {
    PaperSize, ReportType, TemplateOptionLocationType, TemplateOptionType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate.Enums';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import {
    SimpleCheckboxButtonHelper
} from '@profis-engineering/pe-ui-common/helpers/simple-checkbox-button-helper';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import {
    CalculationServiceBase
} from '@profis-engineering/pe-ui-common/services/calculation.common';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { LanguageCulture } from '@profis-engineering/pe-ui-common/services/localization.common';

import {
    DesignMethodGroup as DesignMethodGroupC2C
} from '../../../shared/entities/code-lists/design-method-group';
import {
    ReportType as ReportTypeCodeListC2C
} from '../../../shared/entities/code-lists/report-type';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import {
    ApplicationType, CalculationSettings, ConnectionType, DesignMethod,
    DesignStandard as DesignStandardC2CEnum, ReportPaperSize, ReportType as ReportTypeC2C
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import {
    ReportOptionEntityC2C, ReportTemplateOptionEntityC2C
} from '../../../shared/generated-modules/Hilti.PE.ReportService.Shared.Entities';
import {
    ReportTemplateOptionLocationType, ReportTemplateOptionType, UploadedPictureType
} from '../../../shared/generated-modules/Hilti.PE.ReportService.Shared.Enums';
import { FeatureFlagTypes } from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.FeatureFlags';
import {
    getUKDesignMethodKey, isHnaAnchoringBarYieldMethod
} from '../../../shared/helpers/design-standard-methods-helper';
import { PropertyMetaDataC2C } from '../../../shared/properties/properties';
import { isHnaBasedDesignStandard } from '../../../shared/services/calculation.common';
import { IUploadedPicture } from '../../../shared/services/report.common';
import { ReportDetails } from '../../entities/export-report';
import { covertCommonReportTypeToC2C } from '../../helpers/common-enum-converter-helper';
import { LoadsComponentHelper } from '../../helpers/loads-component-helper';
import { BrowserService } from '../../services/browser.service';
import { CalculationServiceC2C } from '../../services/calculation-c2c.service';
import { CodeListService } from '../../services/code-list.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { DateTimeService } from '../../services/date-time.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { FeaturesVisibilityService } from '../../services/features-visibility.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { ReportTemplateService } from '../../services/report-template.service';
import { ReportService } from '../../services/report.service';
import { TranslationFormatService } from '../../services/translation-format.service';
import { TrimbleConnectService } from '../../services/trimble-connect.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { includeSprites, Sprite } from '../../sprites';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';

const customPicturesMaxUploadSize: number = 2 * 1024 * 1024;
const customTemplateId = Number.MAX_VALUE;

interface ISectionCollapse {
    summary: boolean;
    projectDetails: boolean;
    commentAndNotes: boolean;
    layout: boolean;
    trimbleConnect: boolean;
    customPicturesAvailable: boolean;
    calculations: boolean;
}

enum ValueWithLengthFormatMode {
    None = 0,
    Hef = 1,
    HNom = 2
}

export interface IExportReportSupportMethods {
    createGlModelScreenshot2D: (extraInfo: IScreenShotSettingsC2C, view2dMode: View2dModeType) => Promise<string>;
    createGlModelScreenshot: (extraInfo: IScreenShotSettingsC2C) => Promise<string>;
    createZoneDiagramScreenshot: () => Promise<string>;
}

export interface IExportReportComponentInput extends IExportReportSupportMethods {

    onModelImagesCreated?: () => void;
    onModelImagesCreated2D?: () => void;
}

@Component({
    templateUrl: './export-report.component.html',
    styleUrls: ['./export-report.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ExportReportComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance<IExportReportComponentInput>;

    @ViewChild('modelImage')
    modelImageElement!: ElementRef;

    @ViewChildren('customPicturesInput')
    customPicturesInputs!: QueryList<ElementRef>;

    public isLoaded = false;
    public submitted = false;
    public isSectionCollapsed!: ISectionCollapse;
    public connectionTypeEnum = {
        ConcreteOverlay: ConnectionType.ConcreteOverlay,
        MissingRebars: ConnectionType.MissingRebars,
        Splices: ConnectionType.Splices,
        StructuralJoints: ConnectionType.StructuralJoints,
        Unknown: ConnectionType.Unknown
    };
    public modelImageReport!: string;
    public modelImage2dReport!: string;
    public modelImage2dReportSideView!: string;
    public modelImage2dReportFrontView!: string;
    public zoneAnalysisDiagramContent = '';
    public noteLoadCaseResultingRebarForces!: string;

    // summary
    public rebarType!: string;
    public baseMaterialExistingStructure!: string;
    public baseMaterialNewStructure!: string;
    public evaluationService!: string;
    public evaluationServiceTitle!: string;
    public anchorImage!: string;
    public issued!: string;
    public proof!: string;
    public embedmentDepthValue!: string;
    public rebarSize!: string;

    // project details
    public exportReportProjectDetailsInputs!: IExportReportProjectDetailsInput;

    // calculations
    public devSpliceLengthCalculationsCheckbox!: CheckboxButtonProps<boolean>;
    public yieldLengthCalculationsCheckbox!: CheckboxButtonProps<boolean>;
    public anchorageCalculationsCheckbox!: CheckboxButtonProps<boolean>;
    public calculationSettingsDisabled!: boolean;

    // comment and notes
    public noteShearLoad!: string;
    public noteInstallationData!: string;

    // pictures
    public customPictures: Map<number, { imgUrl: string; imgSize: number }> = new Map<number, { imgUrl: string; imgSize: number }>();
    private customPicturesUploadSize!: number;
    private customPicturesChanged!: boolean;
    public customPictureIds!: string[];
    public picturesToUploadC2C: IUploadedPicture[] = [];

    // company layout
    public exportReportCompanyLayoutInputs!: IExportReportCompanyLayoutComponentInput;

    // trimble-connect
    public exportTrimbleConnectInput!: IExportTrimbleConnectComponentInput;

    // helpers
    public loadsHelper: LoadsComponentHelper;

    constructor(
        public localizationService: LocalizationService,
        private elementRef: ElementRef<HTMLElement>,
        private userService: UserService,
        private featuresVisibilityInfoService: FeaturesVisibilityInfoService,
        private modalService: ModalService,
        private ngZone: NgZone,
        private browserService: BrowserService,
        private reportService: ReportService,
        private unitService: UnitService,
        private featuresVisibilityService: FeaturesVisibilityService,
        private userSettingsService: UserSettingsService,
        private dateTimeService: DateTimeService,
        private codeListService: CodeListService,
        private numberService: NumberService,
        private reportTemplateService: ReportTemplateService,
        private commonCodeListService: CommonCodeListService,
        private calculationServiceC2C: CalculationServiceC2C,
        private commonCodeList: CommonCodeListService,
        private trimbleConnectService: TrimbleConnectService,
        translationFormatService: TranslationFormatService,
    ) {
        this.loadsHelper = new LoadsComponentHelper(
            localizationService,
            userService.design,
            unitService,
            modalService,
            translationFormatService,
            codeListService
        );
    }

    public get formValid() {
        const exportReportCompanyLayoutValid = this.exportReportCompanyLayoutInputs.templateDropdown.selectedValue != customTemplateId
            || this.exportReportCompanyLayoutInputs.reportEmailValid;

        return exportReportCompanyLayoutValid;
    }

    public get rebarTypeTranslation() {
        return this.localizationService.getString(this.isC2COverlayEu ? 'Agito.Hilti.C2C.ExportReport.Summary.ConnectorType' : 'Agito.Hilti.C2C.ExportReport.Summary.RebarType');
    }

    public get isC2COverlayEu() {
        return this.isConcreteOverlay && this.isETAGC2C;
    }

    public get isConcreteOverlay() {
        return this.design.connectionType == ConnectionType.ConcreteOverlay;
    }

    public get design() {
        return this.userService.design;
    }

    public get isETAGC2C() {
        return this.design.designStandardC2C?.id == DesignStandardC2CEnum.ETAG;
    }

    public get connectionType() {
        return this.design.connectionType;
    }

    public get showRebarSize() {
        return this.connectionType == ConnectionType.ConcreteOverlay || this.isHNA;
    }

    public get isHNA() {
        return this.design.designStandardC2C?.id == DesignStandardC2CEnum.ACI || this.design.designStandardC2C?.id == DesignStandardC2CEnum.CSA;
    }

    public get rebarSizeTranslation() {
        return this.localizationService.getString(this.isC2COverlayEu ? 'Agito.Hilti.C2C.ExportReport.Summary.ConnectorSize' : 'Agito.Hilti.C2C.ExportReport.Summary.RebarSize');
    }

    public get reportDisabled() {
        return this.featuresVisibilityInfoService.isDisabled(Feature.Application_Report, this.design.region.id);
    }

    public get disableCustomImagesC2C() {
        return this.userService.hasfloatingLimitReached || this.userService.hasFreeLicense || this.userService.hasOnlyBasic;
    }

    public get disableTrimbleConnect() {
        return !this.trimbleConnectService.isEnabled;
    }

    public get reportTemplateDisabledTooltip() {
        return this.featuresVisibilityInfoService.tooltip(Feature.Application_ReportTemplate);
    }

    public get formatedReportType() {
        return this.formatReportType(this.templateReportType());
    }

    public get formatedPaperSize() {
        const paperSize = this.exportReportCompanyLayoutInputs.reportTemplate?.PaperSize;
        return paperSize ? this.formatPaperSize(paperSize) : undefined;
    }

    public onReportTypeChange(reportTemplate: ReportTemplateEntity | undefined) {
        this.exportReportCompanyLayoutInputs.reportTemplate = reportTemplate;
    }

    private get customPicturesInput1() {
        return this.customPicturesInputs.get(0)?.nativeElement as HTMLInputElement;
    }

    private get customPicturesInput2() {
        return this.customPicturesInputs.get(1)?.nativeElement as HTMLInputElement;
    }

    private get customPicturesInput3() {
        return this.customPicturesInputs.get(2)?.nativeElement as HTMLInputElement;
    }

    private get isGenericOverlay() {
        return this.design.applicationType == ApplicationType.GenericReinforcement;
    }

    private get reportTypesC2C(): ReportTypeCodeListC2C[] {
        return this.codeListService.projectCodeListsC2C[ProjectCodeList.ReportTypeC2C] as ReportTypeCodeListC2C[];
    }

    private get rebarApplicationTranslation() {
        return this.localizationService.getString(this.isC2COverlayEu ? 'Agito.Hilti.C2C.ExportReport.Details.OverlayApplication' : 'Agito.Hilti.C2C.ExportReport.Details.RebarApplication');
    }

    private get reportTemplateDisabled() {
        return this.featuresVisibilityInfoService.isDisabled(Feature.Application_ReportTemplate, this.design.region.id);
    }

    public get displayLoadCombinationDropdownC2C() {
        return this.design.loadCombinationsC2C
            && this.design.loadCombinationsC2C.length > 1
            && (this.design.isC2CHNA || (this.design.isPirEuOrAus && !this.loadsHelper.isPerBarPir) || this.isC2COverlayEu);
    }

    private get isLoadCombinationDropdownVisibleC2C() {
        return this.displayLoadCombinationDropdownC2C && this.design.designData.projectDesignC2C?.loads.showLoadInputs;
    }

    private get languages() {
        const languages = (this.commonCodeListService.commonCodeLists[CommonCodeList.Language] as Language[]).filter(language => language.culture != LanguageCulture.pseudoLanguage);
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);
        return sortBy(this.allowedValues(languages, this.design.properties.get(PropertyMetaDataC2C.Option_C2C_ReportLanguageId.id).allowedValues as number[]), (reportLanguage) => (reportLanguage.getTranslatedNameText(codeListDeps) || '').toLowerCase());
    }

    public get reportPaperSizes() {
        const reportPaperSizes = this.commonCodeListService.commonCodeLists[CommonCodeList.ReportPaperSize] as ReportPaperSizeCodeList[];
        return this.allowedValues(reportPaperSizes, this.design.properties.get(PropertyMetaDataC2C.Option_C2C_ReportPaperSize.id).allowedValues as number[]);
    }

    private get reportTemplate() {
        return this.design.reportTemplateId === -1 ?
            this.reportTemplateService.templates.find((template) => template.Name === 'Default') :
            this.reportTemplateService.templates.find((template) => template.Id === this.design.reportTemplateId);
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-lines-expanded',
            'sprite-lines',
            'sprite-x'
        );

        this.exportReportProjectDetailsInputs = {
            designName: this.userService.project.getDisplayName(this.localizationService) + ', ' + this.design.designName,
            fasteningPoint: this.design.reportFateningPoint,
            fasteningPointTitle: this.rebarApplicationTranslation,
            fasteningPointId: 'export-report-details-section-rebarapplication-textbox',
            notes: this.design.reportNotes,
            reportDisabled: this.reportDisabled,
            includeDetailsInReport: {
                items: [],
                title: 'Agito.Hilti.Profis3.ExportReport.ProjectDetails.IncludeDesign',
                selectedValues: new Set()
            }
        } as IExportReportProjectDetailsInput;

        if (this.design?.isFeatureEnabled(FeatureFlagTypes.Sustainability) && this.design.isPirEu) {
            this.exportReportProjectDetailsInputs.includeSustainabilityInReport = {
                items: [],
                title: 'Agito.Hilti.Profis3.ExportReport.ProjectDetails.Sustainability',
                selectedValues: new Set()
            };
        }

        this.isSectionCollapsed = {
            summary: false,
            commentAndNotes: true,
            layout: true,
            projectDetails: true,
            trimbleConnect: true,
            customPicturesAvailable: true,
            calculations: true,
        };

        this.customPicturesUploadSize = customPicturesMaxUploadSize;
        this.customPicturesChanged = false;

        this.customPictureIds = [...(this.design.customPicturesC2C ?? [])];

        // model screenshots;
        const previewLoadsVisibilityInfo: LoadsVisibilityInfo = {
            preview: true,
            modifyLoads: false,
        };

        // model preview screenshot
        const screenShotSettings: IScreenShotSettingsC2C = {
            imgHeight: 250,
            imgWidth: 250,
            zoomed: false,
            preview: true,
            loadsVisibilityInfo: previewLoadsVisibilityInfo
        };

        const imgPreview = new Promise<void>((resolve, reject) => {
            this.createModelImage(screenShotSettings)
                .then((modelImage?: string) => {
                    (this.modelImageElement.nativeElement as HTMLImageElement).src = modelImage ?? '';
                    resolve();
                })
                .catch((err: any) => {
                    reject(err);
                });
        });

        // report model image should have no connectors
        const resolution = 2000;
        this.createModelImage({
            showConnectorsC2C: true,
            imgHeight: resolution,
            imgWidth: resolution,
            zoomed: false,
            preview: false,
            loadsVisibilityInfo: previewLoadsVisibilityInfo
        }).then((image) => {
            this.modelImageReport = image ?? '';
        });

        const resolution2d = { width: 2000, height: 1800 };
        this.createModelImage2D({
            showConnectorsC2C: true,
            imgHeight: resolution2d.height,
            imgWidth: resolution2d.width,
            zoomed: false,
            preview: false,
            loadsVisibilityInfo: previewLoadsVisibilityInfo
        }, View2dModeType.Top).then((image) => {
            this.modelImage2dReport = image ?? '';
            this.createModelImage2D({
                showConnectorsC2C: true,
                imgHeight: resolution2d.height,
                imgWidth: resolution2d.width,
                zoomed: false,
                preview: false,
                loadsVisibilityInfo: previewLoadsVisibilityInfo
            }, View2dModeType.Side).then((sideImage) => {
                this.modelImage2dReportSideView = sideImage ?? '';
                this.createModelImage2D({
                    showConnectorsC2C: true,
                    imgHeight: resolution2d.height,
                    imgWidth: resolution2d.width,
                    zoomed: false,
                    preview: false,
                    loadsVisibilityInfo: previewLoadsVisibilityInfo
                }, View2dModeType.Front).then((frontImage) => {
                    this.modelImage2dReportFrontView = frontImage ?? '';
                });
            });
        });

        if (this.modalInstance.input?.onModelImagesCreated != null) {
            Promise.all([imgPreview])
                .catch((err: any) => {
                    this.logError(err);
                })
                .finally(this.modalInstance.input.onModelImagesCreated);
        }

        this.exportTrimbleConnectInput = {
            trimbleConnectChecked: false,
            trimbleConnectLocation: this.design.trimbleConnectLocation,
            trimbleConnectReportName: this.design.trimbleConnectReportName,
            trimbleConnectFolderId: this.design.trimbleConnectFolderId,
            trimbleConnectTooltip: this.reportExportDisabledTooltip(),
            isOfflineOnLine: this.browserService.isOfflineOnLine
        } as IExportTrimbleConnectComponentInput;

        // zone diagram screenshot
        this.createZoneDiagramImage();

        this.setCustomPicturesC2C();

        this.createTemplate();

        this.setIncludeDetailsAndSustainabilityItems();

        this.initC2CSpecifiCheckboxes();

        this.embedmentDepthValue = this.getEmbedmentDepthValue();

        this.rebarType = formatKeyValue(this.design.designData.reportDataC2C?.exportReport?.rebarType ?? '', { years: this.translate('Agito.Hilti.C2C.Product.Years') });

        this.setProof();
        this.setIssued();
        this.setEvaluationService();
        this.setAnchorImage();

        this.rebarSize = this.design.designData.reportDataC2C?.exportReport.rebarSize ?? '';
        this.setBaseMaterialExistingStructure();
        this.setBaseMaterialNewStructure();

        this.setInitialCheckboxStates();

        this.setExportReportCompanyLayoutInputs();

        this.isLoaded = true;
    }

    public save() {
        if (this.submitted || !this.formValid) {
            return;
        }

        this.submitted = true;

        const designImages = [this.zoneAnalysisDiagramContent, this.modelImageReport, this.modelImage2dReport, this.modelImage2dReportSideView, this.modelImage2dReportFrontView];
        if (this.customPictures.size > 0 && this.customPicturesChanged) {
            // Upload custom images
            this.uploadPicturesC2C()
                .then(response => {
                    this.customPictureIds = response;

                    // Generate report
                    this.generateAndDownloadReportC2C(designImages);
                })
                .finally(() => {
                    this.trackGenerateReportDataC2C();
                });
        }
        else {
            // Generate report without custom images
            this.generateAndDownloadReportC2C(designImages);

            this.trackGenerateReportDataC2C();
        }
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public getArrowClass(isCollapsed: boolean) {
        return isCollapsed ? 'pe-ui-c2c-sprite-lines-expanded' : 'pe-ui-c2c-sprite-lines';
    }

    public handleDevSpliceCheckboxOnChange() {
        if (!SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.devSpliceLengthCalculationsCheckbox)) {
            SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(this.yieldLengthCalculationsCheckbox, true);
        }
    }

    public handleYieldCheckboxOnChange() {
        if (!SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.yieldLengthCalculationsCheckbox)) {
            SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(this.devSpliceLengthCalculationsCheckbox, true);
        }
    }

    public reportExportDisabledTooltip() {
        return this.featuresVisibilityInfoService.tooltip(Feature.Application_Report);
    }

    public close() {
        this.modalInstance.close();
    }

    public formatReportType(reportTypeId: ReportType) {
        const reportTypes = this.commonCodeList.commonCodeLists[CommonCodeList.ReportType] as ReportTypeCodeListC2C[];
        const reportType = reportTypes.find((item) => item.id === reportTypeId);
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);

        return this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.Layout.ReportTypeFormat').replace('{reportType}', reportType?.getTranslatedNameText(codeListDeps) ?? '');
    }

    public formatPaperSize(paperSizeId: number) {
        const reportPaperSizes = this.commonCodeListService.commonCodeLists[CommonCodeList.ReportPaperSize] as ReportPaperSizeCodeList[];
        const paperSize = reportPaperSizes.find((item) => item.id === paperSizeId);
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);

        return this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.Layout.PaperSizeFormat').replace('{paperSize}', paperSize?.getTranslatedNameText(codeListDeps) ?? '');
    }

    ////////////////////////////////////////////////////////////////////////
    // Image creation related functions
    public removeCustomPicture(index: number) {
        if (this.submitted) {
            return;
        }
        if (this.customPictures.has(index)) {
            this.customPicturesUploadSize += this.customPictures.get(index)?.imgSize ?? 0;
            this.customPictures.delete(index);
            this.customPicturesChanged = true;
        }

        if (this.customPictures.size == 0) {
            this.customPictureIds = [];
        }
    }

    public customPictureSelected(index: number) {
        switch (index) {
            case 1: {
                if (this.customPicturesInput1.value != null && this.customPicturesInput1.value != '') {
                    this.uploadCustomPicture((this.customPicturesInput1.files as FileList)[0], index);
                    this.customPicturesInput1.value = '';
                }
                break;
            }
            case 2: {
                if (this.customPicturesInput2.value != null && this.customPicturesInput2.value != '') {
                    this.uploadCustomPicture((this.customPicturesInput2.files as FileList)[0], index);
                    this.customPicturesInput2.value = '';
                }
                break;
            }
            case 3: {
                if (this.customPicturesInput3.value != null && this.customPicturesInput3.value != '') {
                    this.uploadCustomPicture((this.customPicturesInput3.files as FileList)[0], index);
                    this.customPicturesInput3.value = '';
                }
                break;
            }
        }
    }

    public selectCustomPicture(index: number) {
        switch (index) {
            case 1: {
                if (this.customPicturesInput1?.value == null || this.customPicturesInput1?.value == '') {
                    this.customPicturesInput1.click();
                }
                break;
            }
            case 2: {
                if (this.customPicturesInput2?.value == null || this.customPicturesInput2?.value == '') {
                    this.customPicturesInput2.click();
                }
                break;
            }
            case 3: {
                if (this.customPicturesInput3?.value == null || this.customPicturesInput3?.value == '') {
                    this.customPicturesInput3.click();
                }
            }
        }
    }
    ////////////////////////////////////////////////////////////////////////

    private uploadCustomPicture(file: File, index: number) {
        if (file.type != 'image/png' && file.type != 'image/jpeg') {
            this.modalService.openAlertWarning(
                this.localizationService.getString('Agito.Hilti.C2C.ReportTemplates.WrongFileTypeAlert.Title'),
                this.localizationService.getString('Agito.Hilti.C2C.ReportTemplates.WrongFileTypeAlert.Message')
            );
            return;
        }
        if (file.size > this.customPicturesUploadSize) {
            if (this.customPictures.size < 1) {
                this.modalService.openAlertWarning(
                    this.localizationService.getString('Agito.Hilti.C2C.ReportTemplates.FileTooLargeAlert.Title'),
                    this.localizationService.getString('Agito.Hilti.C2C.ReportTemplates.FileTooLargeAlert.Message')
                );
                return;
            }
            else {
                this.modalService.openAlertWarning(
                    this.localizationService.getString('Agito.Hilti.C2C.ReportTemplates.FilesTooLargeAlert.Title'),
                    this.localizationService.getString('Agito.Hilti.C2C.ReportTemplates.FilesTooLargeAlert.Message')
                );
                return;
            }
        }

        const reader = new FileReader();

        // ngZone.run() as replacement for this.$scope.$apply in angularJS
        reader.addEventListener('load', () => {
            const img: HTMLImageElement = new ((window as any).Image)();
            img.src = reader.result as string;
            this.ngZone.run(() => {
                img.addEventListener('load', () => {
                    this.ngZone.run(() => {
                        if (img.naturalWidth > 1200 || img.naturalHeight > 800) {
                            this.modalService.openAlertWarning(
                                this.localizationService.getString('Agito.Hilti.C2C.ReportTemplates.ImageTooLargeAlert.Title'),
                                this.localizationService.getString('Agito.Hilti.C2C.ReportTemplates.ImageTooLargeAlert.Message')
                            );
                            return;
                        }
                        this.customPicturesUploadSize -= file.size;
                        this.customPictures.set(index, { imgUrl: img.src, imgSize: file.size });
                        this.customPicturesChanged = true;
                    });
                }, false);
            });
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    private async createModelImage(extraInfo: IScreenShotSettingsC2C) {
        const screen = await this.modalInstance.input?.createGlModelScreenshot(extraInfo);
        return screen;
    }

    private logError(error: any) {
        if (error instanceof Error) {
            console.error(error);
        }
    }

    private async createModelImage2D(extraInfo: IScreenShotSettingsC2C, view2dMode: View2dModeType) {
        return await this.modalInstance.input?.createGlModelScreenshot2D(extraInfo, view2dMode);
    }

    private async createZoneDiagramImage() {
        if (!this.isGenericOverlay) {
            this.zoneAnalysisDiagramContent = await this.modalInstance.input?.createZoneDiagramScreenshot() as string;
        }
    }

    private setCustomPicturesC2C(): void {
        this.reportService.getCustomImagesC2C(this.design.customPicturesC2C)
            .then(images => {
                for (let i = 0; i < images.length; i++) {
                    const image = images[i];
                    const imageSize = this.getImageSize(image);
                    this.customPictures.set(i + 1, { imgUrl: image, imgSize: imageSize });
                }
            });
    }

    private getImageSize(value: string): number {
        // The size of the decoded data can be approximated with this formula.
        return 4 * Math.ceil(((value.length - 'data:image/png;base64,'.length) / 3)) * 0.5624896334383812;
    }

    private initC2CSpecifiCheckboxes() {
        // Setting the text for the checkbox.
        const calculationSettings = this.design.designData.reportDataC2C?.exportReport.calculationSettings;
        this.devSpliceLengthCalculationsCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            itemId: 'dev-splice-length-calculations-checkbox',
            itemText: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.Layout.Calculations.DevSpliceLength'),
        });
        this.yieldLengthCalculationsCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            itemId: 'yield-length-calculations-checkbox',
            itemText: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.Layout.Calculations.YieldLength'),
        });
        this.anchorageCalculationsCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            itemId: 'anchorage-calculations-checkbox',
            itemText: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.Layout.Calculations.AnchorageLength'),
        });

        // If an element is present in the array, then the checkbox should be checked.
        const devSpliceChecked = calculationSettings?.some(element => element == CalculationSettings.DevSpliceLength) ?? false;
        const yieldChecked = calculationSettings?.some(element => element == CalculationSettings.YieldLength) ?? false;
        const anchorageChecked = calculationSettings?.some(element => element == CalculationSettings.Anchorage) ?? false;

        // Check the checkboxes based on the previously defined variables.
        SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(this.devSpliceLengthCalculationsCheckbox, devSpliceChecked);
        SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(this.yieldLengthCalculationsCheckbox, yieldChecked);
        SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(this.anchorageCalculationsCheckbox, anchorageChecked);

        // The checkboxes are to be disabled in all but one case.
        this.calculationSettingsDisabled = true;

        // Enable the checkbox in the case that anchor theory + yield (dev/splice + yield) is selected (only for dev/splice and yield checkboxes).
        if (devSpliceChecked && yieldChecked) {
            this.calculationSettingsDisabled = false;
        }
    }

    private getEmbedmentDepthValue(): string {
        const effectiveEmbedmentDepth: number = (this.design.designData.reportDataC2C?.exportReport.effectiveEmbedment || 0);
        const embedmentDepthString = this.getValueWithLengthFormat(effectiveEmbedmentDepth, ValueWithLengthFormatMode.Hef);

        return embedmentDepthString;
    }

    private getValueWithLengthFormat(value: number, mode = ValueWithLengthFormatMode.None) {
        const defaultUnit = this.unitService.getDefaultUnit(UnitGroup.Length);
        const internalUnit = this.unitService.getInternalUnit(UnitGroup.Length);
        const defaultUnitValue = this.unitService.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit);
        const precision = this.unitService.getPrecision(defaultUnit);
        const formatedValue = this.unitService.formatUnitValueArgs(defaultUnitValue, defaultUnit, precision);

        switch (mode) {
            case ValueWithLengthFormatMode.Hef:
                return `h_ef = ${formatedValue}`;

            case ValueWithLengthFormatMode.HNom:
                return `h_nom = ${formatedValue}`;

            default:
                break;
        }

        return formatedValue;
    }

    private setProof() {
        if (this.design.isPirEuOrAus) {
            this.proof = `${this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.Summary.Proof.DesignMethod')} ${this.localizationService.getString(this.design.designData.projectDesignC2C?.options.selectedDesignMethodTranslationKey ?? '')}` || '-';
        }
        else {
            const designMethodGroup = this.design.designMethodGroup as DesignMethodGroupC2C;
            const uktaDesignMethod = getUKDesignMethodKey(this.design.region.id ?? 0, designMethodGroup, this.featuresVisibilityService.isFeatureEnabled('C2C_UKTA'));
            this.proof = this.designMethodString(uktaDesignMethod.nameResourceKey);

            if (this.design.projectDesign.loads.shearDesignMethod == DesignMethod.HiltiMethodRebarDesign) {
                this.proof += ` & ${this.localizationService.getString('Agito.Hilti.C2C.CodeList.DesignMethodGroupEntity.HiltiMethod')}`;
            }
        }
    }

    private setIssued() {
        const region = this.userSettingsService.getRegionById(this.userSettingsService.settings.application.general.regionId.value ?? 0);
        let issued = '';
        let valid = '';

        if (this.design.designData.reportDataC2C?.approvalDetails != null) {
            if (this.design.designData.reportDataC2C.approvalDetails.issuedOn != null) {
                issued = moment.utc(this.design.designData.reportDataC2C.approvalDetails.issuedOn).locale(region.culture as string).format(this.dateTimeService.dotNetToMomentFormat(region.dateTimeFormat?.ShortDatePattern as string));
            }

            if (this.design.designData.reportDataC2C.approvalDetails.valid != null) {
                valid = moment.utc(this.design.designData.reportDataC2C.approvalDetails.valid).locale(region.culture as string).format(this.dateTimeService.dotNetToMomentFormat(region.dateTimeFormat?.ShortDatePattern as string));
            }
        }

        const separator = issued != '' && valid != '' ? ' - ' : '';
        const issuedValid = issued + separator + valid;
        this.issued = issuedValid != '' ? issuedValid : '-';
    }

    private setEvaluationService() {
        if (this.design.designData.reportDataC2C?.approvalDetails != null &&
            this.design.designData.reportDataC2C?.approvalDetails != undefined) {
            this.evaluationService = this.design.designData.reportDataC2C?.approvalDetails?.number;
        }
        else {
            this.evaluationService = this.localizationService.getString('Agito.Hilti.C2C.DesignReportData.HiltiTechnicalData');
        }


        const designStandard = this.design.designStandardC2C?.id;

        let evaluationServiceTitleKey = 'Agito.Hilti.Profis3.ExportReport.Summary.EuropeanTechnicalAssessment';
        if (this.featuresVisibilityService.isFeatureEnabled('C2C_UKTA') &&
            this.design.designData.reportDataC2C?.approvalDetails?.type == 'UKTA') {
            evaluationServiceTitleKey = 'Agito.Hilti.Profis3.ExportReport.Summary.UKTA';
        }
        else if (isHnaBasedDesignStandard(designStandard)) {
            // HNA-based designs: display ESR
            evaluationServiceTitleKey = 'Agito.Hilti.Profis3.ExportReport.Summary.EvaluationService';
        }

        this.evaluationServiceTitle = this.localizationService.getString(evaluationServiceTitleKey);
    }

    private setAnchorImage() {
        this.anchorImage = this.design.rebarFamily?.image ?? '';

        includeSprites(this.elementRef.nativeElement.shadowRoot,
            this.design.rebarFamily?.image as Sprite
        );
    }

    private setBaseMaterialExistingStructure() {
        if (this.design.designData.reportDataC2C?.exportReport.crackedConcrete) {
            this.baseMaterialExistingStructure = `${this.localizationService.getString('Agito.Hilti.C2C.Overlay.BaseMaterial.CrackedConcrete')}, ${this.design.designData.reportDataC2C.exportReport.existingStructure}`;
        }
        else {
            this.baseMaterialExistingStructure = this.design.designData.reportDataC2C?.exportReport.existingStructure ?? '';
        }
    }

    public designMethodString(nameResourceKey: string | undefined): string {
        const modifiedString = this.localizationService.getString('Agito.Hilti.C2C.CodeList.DesignMethodEntity.General.ModifiedFrom');
        const designMethodString = this.localizationService.getString(nameResourceKey ?? '-') || '-';

        return `${this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.Summary.Proof.DesignMethod')} ${
          isHnaAnchoringBarYieldMethod(this.design) ? `${modifiedString} ${designMethodString}` : designMethodString
        }`;
    }

    public setBaseMaterialNewStructure() {
        if (this.design.designData.reportDataC2C?.exportReport.crackedConcrete) {
            this.baseMaterialNewStructure = `${this.localizationService.getString('Agito.Hilti.C2C.Overlay.BaseMaterial.CrackedConcrete')}, ${this.design.designData.reportDataC2C.exportReport.newStructure}`;
        }
        else {
            this.baseMaterialNewStructure = this.design.designData.reportDataC2C?.exportReport.newStructure ?? '';
        }
    }

    private setInitialCheckboxStates() {
        this.exportReportProjectDetailsInputs.includeDetailsInReport.selectedValues?.add(ReportDetails.IncludeItemNumbers);

        this.exportTrimbleConnectInput.trimbleConnectChecked = false;

        const anchorTheoryPropId = this.design.isC2COverlay ? PropertyMetaDataC2C.AnchorTheory_C2C_IsAnchorTheoryDisplayed.id : PropertyMetaDataC2C.Loads_C2C_IsAnchorTheoryDisplayed.id;
        if (this.design.model[anchorTheoryPropId]) {
            this.exportReportProjectDetailsInputs.includeDetailsInReport.selectedValues?.add(ReportDetails.IncludeAnchorCalculation);
        }

        if (this.design?.designData?.reportDataC2C?.isSustainabilityAvailable) {
            this.exportReportProjectDetailsInputs.includeSustainabilityInReport?.selectedValues?.add(ReportDetails.IncludeSustainability);
        }
    }

    private setIncludeDetailsAndSustainabilityItems(): void {
        this.exportReportProjectDetailsInputs.includeDetailsInReport.items?.push({
            id: 'export-report-details-section-item-number-checkbox',
            text: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.ProjectDetails.IncludeItemNumber'),
            value: ReportDetails.IncludeItemNumbers,
            tooltip: this.reportExportDisabledTooltip()
        });

        this.exportReportProjectDetailsInputs.includeDetailsInReport.items?.push(
            {
                id: 'include-approvals-checkbox',
                text: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.ProjectDetails.IncludeApprovals'),
                value: ReportDetails.IncludeApprovals,
                disabled: this.includeApprovalDisabled()
            }
        );

        this.exportReportProjectDetailsInputs.includeSustainabilityInReport?.items?.push(
            {
                id: 'include-sustainability-checkbox',
                text: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.Sustainability.IncludeSustainability'),
                value: ReportDetails.IncludeSustainability,
                disabled: !(this.design?.designData?.reportDataC2C?.isSustainabilityAvailable ?? false)
            }
        );
    }

    private includeApprovalDisabled(): boolean {
        const approvalPropertyId = this.design.isC2COverlay
            ? PropertyMetaDataC2C.Product_C2C_ConnectorViewApproval.id
            : PropertyMetaDataC2C.Product_C2C_ViewApproval.id;

        return this.isPropertyDisabled(approvalPropertyId);
    }

    private isPropertyDisabled(propertyId: number) {
        const propertyInfo = this.design.properties.get(propertyId);
        return propertyInfo !== undefined ? propertyInfo.disabled : true;
    }

    private setExportReportCompanyLayoutInputs() {
        const selectedReportTypeId = this.reportTypesC2C.find(reportType => reportType.id === this.design.reportTypeId)?.id;

        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);
        const reportTypesItems = this.reportTypesC2C;

        const selectedLanguage = this.languages.find((language) => language.id === this.design.reportLanguageId);

        const selectedReportPaperSize = this.reportPaperSizes.find((reportPaperSize) => reportPaperSize.id === this.design.reportPaperSizeId);

        const decisiveLoadCombination = this.design.loadCombinationsC2C.find((loadCombination) => loadCombination.id == this.design.decisiveLoadCombinationIdC2C);

        this.exportReportCompanyLayoutInputs = {
            reportTemplate: this.reportTemplate,
            reportTemplateDisabled: this.reportTemplateDisabled,
            isLoadCombinationDropdownVisible: this.isLoadCombinationDropdownVisibleC2C,
            displayLoadCaseDropdown: false,
            isExternalOnlineRussianUser: this.userService.isExternalOnlineRussianUser,
            handrailSafetyDesign: false,
            firstPageNumber: this.design.reportFirstPage != null ? this.design.reportFirstPage : 1,
            reportCompanyName: this.design.reportCompanyName,
            reportAddress: this.design.reportAddress,
            reportContactPerson: this.design.reportContactPerson,
            reportPhoneNumber: this.design.reportPhoneNumber,
            reportEmail: this.design.reportEmail,
            reportTypeDropdown: this.getReportTypeDropdown(reportTypesItems, selectedReportTypeId ?? ReportType.Detailed, 'export-report-layout-section-report-type-dropdown'),
            languageDropdown: {
                id: 'export-report-layout-section-language-dropdown',
                items: this.languages.map((language) => {
                    return {
                        value: language.id,
                        text: language.getTranslatedNameText(codeListDeps)
                    } as DropdownItem<number>;
                }),
                selectedValue: selectedLanguage != null ? selectedLanguage.id : undefined
            },
            paperSizeDropdown: {
                id: 'export-report-layout-section-papersize-dropdown',
                items: this.reportPaperSizes.map((reportPaperSize) => {
                    return {
                        value: reportPaperSize.id,
                        text: reportPaperSize.getTranslatedNameText(codeListDeps)
                    } as DropdownItem<number>;
                }),
                selectedValue: selectedReportPaperSize != null ? selectedReportPaperSize.id : undefined
            },
            loadCombinationDropdown: {
                id: 'export-report-load-combination-c2c-dropdown',
                tooltip: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.Layout.LoadCombination.Tooltip'),
                items: this.design.loadCombinationsC2C
                    .filter(loadCombination => !loadCombination.hasScopeChecksError)
                    .map((loadCombination) => {
                        return {
                            value: loadCombination.id,
                            text: (decisiveLoadCombination === loadCombination ? '* ' : '') + loadCombination.name
                        } as DropdownItem<string>;
                    }),
                selectedValue: this.design.designData.projectDesignC2C?.loads.showLoadInputs
                    ? this.design.loadCombinationsC2C.find((loadCombination) => loadCombination.id == decisiveLoadCombination?.id)?.id
                    : this.design.loadCombinationsC2C.find((loadCombination) => loadCombination.id == this.design.selectedLoadCombinationIdC2C)?.id
            },
            loadCaseDropdown: {},
            loadCaseHandrailDropdown: {},
            templateDropdown: {
                id: 'export-report-layout-section-template-dropdown',
                items: [],
                selectedValue: this.reportTemplate?.Id ?? customTemplateId,
            }
        } as IExportReportCompanyLayoutComponentInput;
    }

    private allowedValues<TType extends CodeList>(codeList: TType[], allowedValues: number[]) {
        if (allowedValues == null) {
            return codeList;
        }

        return codeList.filter(codeListItem => allowedValues.includes(codeListItem.id as number));
    }

    private templateReportType(): ReportType {
        let reportType = ReportType.Detailed;

        // Custom report type
        if (this.exportReportCompanyLayoutInputs.templateDropdown.selectedValue == customTemplateId) {
            const reportTypeDropdownSelection = this.exportReportCompanyLayoutInputs.reportTypeDropdown.selectedValue;
            reportType = reportTypeDropdownSelection as ReportType;
        }
        else {
            // Defined report types
            reportType = this.exportReportCompanyLayoutInputs.reportTemplate?.ReportType as number as unknown as ReportType;
        }

        // C2C currently does not support Long, so map Long to Detailed
        if (reportType == ReportType.Long) {
            return ReportType.Detailed;
        }

        return reportType;
    }

    private getReportTypeDropdown(items: CodeList[], selectedValue: number, id: string): DropdownProps<number> {
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);

        return {
            id,
            items: items.map(item => {
                return {
                    value: item.id as number,
                    text: item.getTranslatedNameText(codeListDeps) as string
                };
            }),
            selectedValue
        };
    }

    private uploadPicturesC2C(): Promise<string[]> {
        const images: IUploadedPicture[] = [];
        const customPictures = this.getDesignCustomPictures();
        for (let i = 0; i < customPictures.length; i++) {
            images.push({
                content: customPictures[i],
                type: i + 1
            });
        }

        return this.reportService.uploadImagesC2C(this.design.id, images);
    }

    private getDesignCustomPictures(): string[] {
        const map: string[] = [];
        for (const [key, value] of this.customPictures) {
            map[key - 1] = value.imgUrl;
        }
        return map;
    }

    private generateAndDownloadReportC2C(designImages: string[]) {
        const changes = CalculationServiceBase.changeModel(this.design.model, this.design.modelChanges, this.design.changes, () => this.updateReportSettings());
        const options = this.getReportOptionsC2C();

        // all report export report data need to be send into calculation service, otherwise will not be saved
        this.calculationServiceC2C.calculateAllC2CSignalR(this.design, Object.values(changes))
            .then(() => this.calculationServiceC2C.generateAndDownloadReport(this.design, true, options, designImages))
            .then(() => this.close())
            .catch(error => {
                this.submitted = false;

                throw error;
            });
    }

    private getReportOptionsC2C(): ReportOptionEntityC2C {
        const paperSizes = [ReportPaperSize.Unknown, ReportPaperSize.A4, ReportPaperSize.Letter];

        return {
            fasteningPoint: this.exportReportProjectDetailsInputs.fasteningPoint,
            notes: this.exportReportProjectDetailsInputs.notes,
            noteLoadResultingAnchorForces: this.noteLoadCaseResultingRebarForces,
            noteTensionLoad: this.design.noteTensionLoad,
            noteShearLoad: this.noteShearLoad,
            noteInstallationData: this.noteInstallationData,
            customPictures: this.customPictureIds,
            companyName: this.reportTemplate?.Company ?? this.exportReportCompanyLayoutInputs.reportCompanyName,
            contactPerson: this.reportTemplate?.ContactPerson ?? this.exportReportCompanyLayoutInputs.reportContactPerson,
            phoneNumber: this.reportTemplate?.PhoneNumber ?? this.exportReportCompanyLayoutInputs.reportPhoneNumber,
            faxNumber: this.reportTemplate?.FaxNumber,
            email: this.reportTemplate?.Email ?? this.exportReportCompanyLayoutInputs.reportEmail,
            type: (this.design.reportTypeId as ReportTypeC2C),
            reportLanguageId: this.exportReportCompanyLayoutInputs.languageDropdown.selectedValue as number,
            paperSize: paperSizes[this.exportReportCompanyLayoutInputs.paperSizeDropdown.selectedValue as number],
            firstPage: this.exportReportCompanyLayoutInputs.firstPageNumber,
            address: this.reportTemplate?.Address ?? this.exportReportCompanyLayoutInputs.reportAddress,
            includeItemNumber: this.exportReportProjectDetailsInputs.includeDetailsInReport.selectedValues?.has(ReportDetails.IncludeItemNumbers) ?? false,
            customPictureTypes: this.picturesToUploadC2C.filter(_ => _.type != UploadedPictureType.Model3dScreenshot).map(_ => _.type),
            reportTemplateId: this.design.reportTemplateId,
            trimbleConnectFolderId: this.exportTrimbleConnectInput.trimbleConnectFolderId,
            trimbleConnectLocation: this.exportTrimbleConnectInput.trimbleConnectLocation,
            trimbleConnectReportName: this.exportTrimbleConnectInput.trimbleConnectReportName,
            trimbleConnectUpload: this.exportTrimbleConnectInput.trimbleConnectChecked,
            includeAnchorCalculations: this.exportReportProjectDetailsInputs.includeDetailsInReport.selectedValues?.has(ReportDetails.IncludeAnchorCalculation) ?? false,
            reportTemplateOptions: this.getReportTemplateOptions(this.reportTemplate?.TemplateOptions as ReportTemplateOptionEntity[]),
            removeProjectHeader: this.reportTemplate?.RemoveProjectHeader ? this.reportTemplate.RemoveProjectHeader : false,
            calculationSettings: this.getCalculationSettings(),
            includeApprovals: this.exportReportProjectDetailsInputs.includeDetailsInReport.selectedValues?.has(ReportDetails.IncludeApprovals) ?? false,
            includeSustainability: this.exportReportProjectDetailsInputs.includeSustainabilityInReport?.selectedValues?.has(ReportDetails.IncludeSustainability) ?? false
        };
    }

    private trackGenerateReportDataC2C(): void {
        this.design.usageCounterC2C.ReportsCreated++;

        if (this.reportTemplate == null) {
            this.design.usageCounterC2C.ReportCreatedWithCustomTemplate++;
        }
        else if (this.reportTemplate.IsTemplate) {
            this.design.usageCounterC2C.ReportCreatedWithDefaultTemplate++;
        }
        else {
            this.design.usageCounterC2C.ReportCreatedWithUserTemplate++;
        }

        if (this.exportReportProjectDetailsInputs.includeDetailsInReport.selectedValues?.has(ReportDetails.IncludeItemNumbers)) {
            this.design.usageCounterC2C.ReportCreatedWithItemNumbers++;
        }

        if (this.exportReportProjectDetailsInputs.includeSustainabilityInReport?.selectedValues?.has(ReportDetails.IncludeSustainability)) {
            this.design.usageCounterC2C.ReportCreatedWithSustainability++;
        }

        if ((this.exportReportCompanyLayoutInputs.reportTypeDropdown.selectedValue == ReportType.Short && this.reportTemplate == null) || (this.reportTemplate != null && this.templateReportType() == ReportType.Short)) {
            this.design.usageCounterC2C.ShortReportsCreated++;
        }
    }

    private updateReportSettings() {
        // trimble connect BL
        this.design.trimbleConnectUpload = this.exportTrimbleConnectInput.trimbleConnectChecked;

        if (this.design.trimbleConnectUpload) {
            this.design.trimbleConnectFolderId = this.exportTrimbleConnectInput.trimbleConnectFolderId;
            this.design.trimbleConnectLocation = this.exportTrimbleConnectInput.trimbleConnectLocation;
            this.design.trimbleConnectReportName = this.exportTrimbleConnectInput.trimbleConnectReportName;
        }

        this.design.reportNotes = this.exportReportProjectDetailsInputs.notes;
        this.design.reportFateningPoint = this.exportReportProjectDetailsInputs.fasteningPoint;

        // template
        this.updateReportSettingsForTemplate();

        this.design.customPicturesC2C = [...this.customPictureIds];
        this.design.noteLoadCaseResultingRebarForcesC2C = this.noteLoadCaseResultingRebarForces;
        this.design.reportLoadCombinationC2C = this.getReportLoadCombinationId();
    }

    private updateReportSettingsForTemplate() {
        if (this.exportReportCompanyLayoutInputs.templateDropdown.selectedValue == customTemplateId) {
            this.design.reportTemplateId = 0;
            this.design.reportTypeId = this.exportReportCompanyLayoutInputs.reportTypeDropdown.selectedValue as number;
            this.design.reportLanguageId = this.exportReportCompanyLayoutInputs.languageDropdown.selectedValue as number;
            this.design.reportPaperSizeId = this.exportReportCompanyLayoutInputs.paperSizeDropdown.selectedValue as number;
            this.design.reportFirstPage = this.exportReportCompanyLayoutInputs.firstPageNumber;
            this.design.noteShearLoad = this.noteShearLoad;
            this.design.noteInstallationData = this.noteInstallationData;
            this.design.reportCompanyName = this.exportReportCompanyLayoutInputs.reportCompanyName;
            this.design.reportAddress = this.exportReportCompanyLayoutInputs.reportAddress;
            this.design.reportContactPerson = this.exportReportCompanyLayoutInputs.reportContactPerson;
            this.design.reportPhoneNumber = this.exportReportCompanyLayoutInputs.reportPhoneNumber;
            this.design.reportEmail = this.exportReportCompanyLayoutInputs.reportEmail;
        }
        else {
            this.design.reportTemplateId = this.exportReportCompanyLayoutInputs.reportTemplate?.Id as number;
            if (this.userService.isExternalOnlineRussianUser) {
                if (this.userSettingsService.ccmsUserSettings != null) {
                    this.design.reportCompanyName = this.userSettingsService.ccmsUserSettings.CompanyName ?? '';
                    this.design.reportAddress = this.userSettingsService.ccmsUserSettings.Address ?? '';
                    this.design.reportContactPerson = this.userSettingsService.ccmsUserSettings.FullName ?? '';
                    this.design.reportPhoneNumber = this.userSettingsService.ccmsUserSettings.Phone ?? '';
                    this.design.reportEmail = this.userSettingsService.ccmsUserSettings.EmailAddress ?? '';
                }
            }
            else {
                this.design.reportCompanyName = this.exportReportCompanyLayoutInputs.reportTemplate?.Company ?? '';
                this.design.reportAddress = this.exportReportCompanyLayoutInputs.reportTemplate?.Address ?? '';
                this.design.reportContactPerson = this.exportReportCompanyLayoutInputs.reportTemplate?.ContactPerson ?? '';
                this.design.reportPhoneNumber = this.exportReportCompanyLayoutInputs.reportTemplate?.PhoneNumber ?? '';
                this.design.reportEmail = this.exportReportCompanyLayoutInputs.reportTemplate?.Email ?? '';
            }
            this.design.reportTypeId = covertCommonReportTypeToC2C(this.templateReportType());
            this.design.reportLanguageId = this.exportReportCompanyLayoutInputs.languageDropdown.selectedValue as number;
            this.design.reportPaperSizeId = (this.exportReportCompanyLayoutInputs.reportTemplate?.PaperSize ?? PaperSize.A4) as unknown as ReportPaperSize;
            this.design.reportFirstPage = this.exportReportCompanyLayoutInputs.firstPageNumber;
            this.design.noteShearLoad = this.noteShearLoad;
            this.design.noteInstallationData = this.noteInstallationData;
        }
    }

    private createTemplate() {
        this.noteShearLoad = this.design.noteShearLoad ?? '';
        this.noteInstallationData = this.design.noteInstallationData ?? '';
        this.noteLoadCaseResultingRebarForces = this.design.noteLoadCaseResultingRebarForcesC2C ?? '';

        this.design.customPicturesC2C?.forEach((value, index) => {
            if (value) {
                const imageSize = this.getImageSize(value);
                this.customPictures.set(index + 1, { imgUrl: value, imgSize: imageSize });
                this.customPicturesUploadSize -= imageSize;
            }
        });
    }

    private getReportLoadCombinationId(): string {
        return this.displayLoadCombinationDropdownC2C ? (this.exportReportCompanyLayoutInputs.loadCombinationDropdown.selectedValue as string) : this.design.selectedLoadCombinationIdC2C;
    }

    // Get an enum array of settings to be shown or not in the report.
    private getCalculationSettings(): CalculationSettings[] {
        const calculationSettings = new Array<CalculationSettings>();

        if (SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.devSpliceLengthCalculationsCheckbox)) {
            calculationSettings.push(CalculationSettings.DevSpliceLength);
        }
        if (SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.yieldLengthCalculationsCheckbox)) {
            calculationSettings.push(CalculationSettings.YieldLength);
        }
        if (SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.anchorageCalculationsCheckbox)) {
            calculationSettings.push(CalculationSettings.Anchorage);
        }

        return calculationSettings;
    }

    private getReportTemplateOptions(templateOptions: ReportTemplateOptionEntity[]): ReportTemplateOptionEntityC2C[] {
        const reportTemplateOptions: ReportTemplateOptionEntityC2C[] = [];

        if (templateOptions == null) {
            return reportTemplateOptions;
        }

        for (const templateOption of templateOptions) {
            reportTemplateOptions.push({
                templateOptionLocationType: this.mapTemplateOptionLocationType(templateOption.TemplateOptionLocationType),
                templateOptionType: this.mapTemplateOptionType(templateOption.TemplateOptionType),
                value: templateOption.Value
            });
        }

        return reportTemplateOptions;
    }

    private mapTemplateOptionType(templateOptionType: TemplateOptionType): ReportTemplateOptionType {
        switch (templateOptionType) {
            case TemplateOptionType.CompanyInfo:
                return ReportTemplateOptionType.CompanyInfo;
            case TemplateOptionType.CustomText:
                return ReportTemplateOptionType.CustomText;
            case TemplateOptionType.DateOfGeneration:
                return ReportTemplateOptionType.DateOfGeneration;
            case TemplateOptionType.Logo:
                return ReportTemplateOptionType.Logo;
            case TemplateOptionType.PageNumber:
                return ReportTemplateOptionType.PageNumber;
            case TemplateOptionType.ProjectName:
                return ReportTemplateOptionType.ProjectName;
            default:
                return ReportTemplateOptionType.Unknown;
        }
    }

    private mapTemplateOptionLocationType(templateOptionType: TemplateOptionLocationType): ReportTemplateOptionLocationType {
        switch (templateOptionType) {
            case TemplateOptionLocationType.Footer:
                return ReportTemplateOptionLocationType.Footer;
            case TemplateOptionLocationType.Header:
                return ReportTemplateOptionLocationType.Header;
            default:
                return ReportTemplateOptionLocationType.Unknown;
        }
    }
}
