<div id="LC-table" class="loads-container">
    <!-- Tooltips -->
    <ng-template #staticLoadTooltip>
        <pe-content-tooltip [tooltip]="helper.staticTooltip"></pe-content-tooltip>
    </ng-template>

    <ng-template #seismicLoadTooltip>
        <pe-content-tooltip [tooltip]="helper.seismicTooltip"></pe-content-tooltip>
    </ng-template>

    <ng-template #fatigueLoadTooltip>
        <pe-content-tooltip [tooltip]="helper.fatigueTooltip"></pe-content-tooltip>
    </ng-template>

    <ng-template #fireLoadTooltip>
        <pe-content-tooltip [tooltip]="helper.fireTooltip"></pe-content-tooltip>
    </ng-template>
    <!-- Tooltips -->

    <pe-resizer
        [disabled]="collapsed"
        [targetHtmlElement]="loadsContainer"
        [parentHtmlElement]="parentElement"
        [direction]="'up'"
        (sizeChange)="onResize()">

        <div id="load-combinations" class="control loads"   *ngIf="design != null">
            <!-- Collapsed with a single decisive load combination. -->
            <div class="loads-header-collapsed" *ngIf="collapsed && selectedLoad != null">
                <div class="loads-top-header">
                    <div class="collapsed-loads-space-c2c"></div>

                    <div class="column-name" style="flex-basis: 150px;">
                        <div class="text" [l10n]="titleTranslation"></div>
                    </div>

                    <div class="column-type" *ngIf="!helper.showDynamic">
                        <div class="text" l10n="Agito.Hilti.Profis3.Loads.Type"></div>
                    </div>

                    <div class="column-loads C2C" *ngIf="helper.showSustainedLoads || helper.showDynamic">
                        <div class="text" l10n="Agito.Hilti.Profis3.Loads.Loads" *ngIf="helper.showSustainedLoads"></div>
                    </div>

                    <div class="column-zones C2C" *ngIf="helper.areZonesVisible" [ngStyle]="zoneHeaderFlex">
                        <div class="text" [innerHTML]="zonesHeader"></div>
                    </div>

                    <div class="column-shear-stress" *ngIf="!helper.areZonesVisible && helper.isOverlayHNAAndIsShearStress"
                        [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth != null ? (helper.resizerColumnWidth.tedX + helper.resizerColumnWidth.tedY) + 'px' : null }">
                        <div class="text" [innerHTML]= "stressHeader"></div>
                    </div>

                    <div *ngIf="columnForcesWithForceN || columnForcesWithForceXY || columnForcesWithForceYZ || columnForcesWithForceXYZ"
                        [class.column-force]="columnForcesWithForceN"
                        [class.column-forces-overlay-hna]="columnForcesWithForceXY"
                        [class.column-forces]="columnForcesWithForceXYZ"
                        [class.column-forces-without-force-x]="columnForcesWithForceYZ"
                        [ngStyle]="{ 'flex-basis': columnForcesDefaultFlex + 'px' }">
                        <div class="text">{{forcesHeader}}</div>
                    </div>

                    <div *ngIf="columnMomentsWithMomentX || columnMomentsWithMomentXY || columnMomentsWithMomentXYZ"
                        [class.column-moment]="columnMomentsWithMomentX"
                        [class.column-moments-without-moment-z]="columnMomentsWithMomentXY"
                        [class.column-moments]="columnMomentsWithMomentXYZ"
                        [class.column-hidden]="helper.isPerBarPir"
                        [ngStyle]="{ 'flex-basis': columnMomentsDefaultFlex + 'px' }">
                        <div class="text">{{momentsHeader}}</div>
                    </div>

                    <div class="column-utilization" *ngIf="!collapsed"
                        [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth != null ? utilizationFlexBasis + 'px' : null }">
                    </div>

                    <div class="column-messages" *ngIf="!collapsed"></div>

                    <div class="column-collapse">
                        <button
                            id="LC-table-showhide-arrow-button-1"
                            class="collapse-button"
                            type="button"
                            (click)="collapsed = !collapsed; onCollapsedChanged()"
                            [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Region.ShowHide')">
                            <span class="pe-ui-c2c-sprite"
                                [class.pe-ui-c2c-sprite-lines]="collapsed"
                                [class.pe-ui-c2c-sprite-lines-expanded]="!collapsed"></span>
                        </button>
                    </div>
                </div>

                <div class="loads-sub-header">
                    <div class="collapsed-loads-space-c2c"></div>

                    <div class="column-name" style="flex-basis: 150px;"
                        [ngbTooltip]="selectedLoad.name">
                        <div class="text"><span class="clip">{{selectedLoad.name}}</span></div>
                    </div>

                    <div class="column-type clip" *ngIf="!helper.showDynamic">
                        <div class="text">{{selectedLoadTypeName}}</div>
                    </div>

                    <div class="column-loads C2C clip" *ngIf="helper.showDynamic">
                        <div class="text" [l10n]="tedVedMinTranslation"></div>
                        <div class="text" [l10n]="tedVedMaxTranslation"></div>
                    </div>

                    <div class="column-zone column-zone-1 clip" *ngIf="helper.areZonesVisible && helper.isZoneVisible(1)" [ngStyle]="helper.zoneOneFlex" >
                        <div class="text">{{translate('Agito.Hilti.C2C.Navigation.TabProduct.Zone1')}}: {{formatZone(selectedLoad.zone1, propertyMetaData.Loads_C2C_Zone1.id)}}</div>
                        <div class="text" *ngIf="helper.showDynamic">{{translate('Agito.Hilti.C2C.Navigation.TabProduct.Zone1')}}: {{formatZone(selectedLoad.dynamicZone1, propertyMetaData.Loads_C2C_DynamicZone1.id)}}</div>
                    </div>

                    <div class="column-zone column-zone-2 clip" *ngIf="helper.areZonesVisible && helper.isZoneVisible(2)" [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.zone2) }" >
                        <div class="text">{{translate('Agito.Hilti.C2C.Navigation.TabProduct.Zone2')}}: {{formatZone(selectedLoad.zone2, propertyMetaData.Loads_C2C_Zone2.id)}}</div>
                        <div class="text" *ngIf="helper.showDynamic">{{translate('Agito.Hilti.C2C.Navigation.TabProduct.Zone2')}}: {{formatZone(selectedLoad.dynamicZone2, propertyMetaData.Loads_C2C_DynamicZone2.id)}}</div>
                    </div>

                    <div class="column-zone column-zone-3 clip" *ngIf="helper.areZonesVisible && helper.isZoneVisible(3)" [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.zone3) }" >
                        <div class="text">{{translate('Agito.Hilti.C2C.Navigation.TabProduct.Zone3')}}: {{formatZone(selectedLoad.zone3, propertyMetaData.Loads_C2C_Zone3.id)}}</div>
                        <div class="text" *ngIf="helper.showDynamic">{{translate('Agito.Hilti.C2C.Navigation.TabProduct.Zone3')}}: {{formatZone(selectedLoad.dynamicZone3, propertyMetaData.Loads_C2C_DynamicZone3.id)}}</div>
                    </div>

                    <div class="column-zone column-zone-4 clip" *ngIf="helper.areZonesVisible && helper.isZoneVisible(4)" [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.zone4) }" >
                        <div class="text">{{translate('Agito.Hilti.C2C.Navigation.TabProduct.Zone4')}}: {{formatZone(selectedLoad.zone4, propertyMetaData.Loads_C2C_Zone4.id)}}</div>
                        <div class="text" *ngIf="helper.showDynamic">{{translate('Agito.Hilti.C2C.Navigation.TabProduct.Zone4')}}: {{formatZone(selectedLoad.dynamicZone4, propertyMetaData.Loads_C2C_DynamicZone4.id)}}</div>
                    </div>

                    <div class="column-zone column-zone-ted clip" *ngIf="helper.areZonesVisible && helper.isZoneVisible(0)" [ngStyle]="helper.zoneOneFlex" >
                        <div class="text"><span [innerHTML]="tedVedTranslation"></span>: {{formatZone(selectedLoad.zoneGeneric, propertyMetaData.Loads_C2C_ZoneGeneric.id)}}</div>
                        <div class="text" *ngIf="helper.showDynamic"><span [innerHTML]="tedVedTranslation"></span>: {{formatZone(selectedLoad.dynamicZoneGeneric, propertyMetaData.Loads_C2C_DynamicZoneGeneric.id)}}</div>
                    </div>

                    <div class="column-stress column-tedx clip" *ngIf="!helper.areZonesVisible && helper.isOverlayHNAAndIsShearStress" [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.tedX) }">
                        <div class="text">𝜏ed x: {{formatZone(selectedLoad.tedX, propertyMetaData.Loads_C2C_TedX.id)}}</div>
                    </div>

                    <div class="column-stress column-tedy clip" *ngIf="!helper.areZonesVisible && helper.isOverlayHNAAndIsShearStress" [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.tedY) }">
                        <div class="text">𝜏ed y: {{formatZone(selectedLoad.tedY, propertyMetaData.Loads_C2C_TedY.id)}}</div>
                    </div>

                    <div class="column-loads C2C clip" *ngIf="helper.showSustainedLoads" [ngStyle]="{ 'flex-basis': 120 + 'px' }">
                        <div class="text">{{helper.firstRowLoadLegendTranslation}}</div>
                        <div class="text">{{helper.secondRowLoadLegendTranslation}}</div>
                    </div>

                    <div class="column-force column-vx clip" *ngIf="showForceX" [ngStyle]="{ 'flex-basis': 50 + 'px' }">
                        <div class="text">Vx: {{formatForce(selectedLoad.forceX)}}</div>
                    </div>

                    <div class="column-force column-vy clip" *ngIf="!helper.areZonesVisible && isNotOverlayHNAOrIsShearForce && !helper.isPerBarPir" [ngStyle]="{ 'flex-basis': 50 + 'px' }">
                        <div class="text">Vy: {{formatForce(selectedLoad.forceY)}}</div>
                    </div>

                    <div class="column-force column-n clip" *ngIf="!helper.areZonesVisible && !helper.isOverlayHNA" [ngStyle]="{ 'flex-basis': 50 + 'px' }">
                        <div class="text">N: {{formatForce(selectedLoad.forceZ)}}</div>
                        <div class="text" *ngIf="helper.showSustainedLoads">N: {{formatForce(selectedLoad.sustainedForceZ)}}</div>
                    </div>

                    <div class="column-moment column-mx clip" *ngIf="!helper.areZonesVisible && !helper.isOverlayHNA && !helper.isPerBarPir" [ngStyle]="{ 'flex-basis': 50 + 'px' }" >
                        <div class="text">Mx: {{formatMoment(selectedLoad.momentX)}}</div>
                        <div class="text" *ngIf="helper.showSustainedLoads">Mx: {{formatMoment(selectedLoad.sustainedMomentX)}}</div>
                    </div>

                    <div class="column-moment column-my clip" *ngIf="!helper.areZonesVisible && !helper.isOverlayHNA && !helper.isPropertyHidden(propertyMetaData.Loads_C2C_MomentY.id)" [ngStyle]="{ 'flex-basis': 50 + 'px' }">
                        <div class="text">My: {{formatMoment(selectedLoad.momentY)}}</div>
                    </div>

                    <div class="column-moment column-mz clip" *ngIf="!helper.areZonesVisible && !helper.isOverlayHNA && !helper.isPropertyHidden(propertyMetaData.Loads_C2C_MomentZ.id)" [ngStyle]="{ 'flex-basis': 50 + 'px' }" >
                        <div class="text">Mz: {{formatMoment(selectedLoad.momentZ)}}</div>
                    </div>

                    <div class="column-utilization" *ngIf="!collapsed"
                        [ngStyle]="{ 'flex-basis': 50 + 'px' }"></div>

                    <div class="column-messages" *ngIf="!collapsed"></div>

                    <div class="column-delete"></div>
                </div>
            </div>

            <!-- Collapsed with multiple decisive load combinations. -->
            <div class="loads-header-collapsed" *ngIf="collapsed && selectedLoad == null">
                <div class="loads-top-header">
                    <div class="column-selected"></div>

                    <div class="collapsed-loads-space-c2c"></div>

                    <div class="column-name" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth != null ? (helper.resizerColumnWidth.name - 1) + 'px' : null }">
                        <div class="text" [l10n]="titleTranslation"></div>
                    </div>

                    <div class="column-type" *ngIf="helper.showDynamic">
                        <div class="text" l10n="Agito.Hilti.Profis3.Loads.Type"></div>
                    </div>

                    <div class="column-loads C2C clip" *ngIf="helper.showDynamic">
                        <div class="text">{{helper.firstRowLoadLegendTranslation}}</div>
                        <div class="text">{{helper.secondRowLoadLegendTranslation}}</div>
                    </div>

                    <div class="column-zones" *ngIf="helper.areZonesVisible"></div>

                    <div class="column-forces" *ngIf="!helper.areZonesVisible"
                        [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth != null ? (helper.resizerColumnWidth.forceVx + helper.resizerColumnWidth.forceVy + helper.resizerColumnWidth.forceN) + 'px' : null }">
                        <div class="text">{{forcesHeader}}</div>
                    </div>

                    <div class="column-moments" *ngIf="!helper.areZonesVisible"
                        [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth != null ? (helper.resizerColumnWidth.momentMx + helper.resizerColumnWidth.momentMy + helper.resizerColumnWidth.momentMz) + 'px' : null }">
                        <div class="text">{{momentsHeader}}</div>
                    </div>

                    <div class="column-utilization" [class.column-collapsed-hidden]="collapsed"
                        [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth != null ? utilizationFlexBasis + 'px' : null }">
                    </div>

                    <div class="column-messages" [class.column-collapsed-hidden]="collapsed"></div>

                    <div class="column-collapse">
                        <button
                            id="LC-table-showhide-arrow-button-2"
                            class="collapse-button"
                            type="button"
                            (click)="collapsed = !collapsed; onCollapsedChanged()"
                            [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Region.ShowHide')">
                            <span class="pe-ui-c2c-sprite"
                                [class.pe-ui-c2c-sprite-lines]="collapsed"
                                [class.pe-ui-c2c-sprite-lines-expanded]="!collapsed"></span>
                        </button>
                    </div>
                </div>
            </div>

            <!-- Expanded. -->
            <div class="resizer-wrapper" *ngIf="!collapsed && rowsLoaded">
                <div class="loads-header">
                    <div class="header-sizer">
                        <div class="column-selected" *ngIf="!helper.isPerBarPir"></div>

                        <div class="column-rank" *ngIf="!helper.isPerBarPir">
                            <div class="text">N°</div>
                        </div>

                        <div class="column-name" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth != null ? (helper.resizerColumnWidth.name - 1) + 'px' : null }">
                            <div class="text" l10n="Agito.Hilti.Profis3.Loads.Name"></div>
                        </div>

                        <div class="column-type" *ngIf="!helper.showDynamic" [ngStyle]="{ 'flex-basis': helper.columnTypeWidth + 'px' }">
                            <div class="text" l10n="Agito.Hilti.Profis3.Loads.Type"></div>
                        </div>

                        <div class="column-loads C2C" *ngIf="helper.showDynamic"></div>

                        <div *ngIf="helper.areZonesVisible" class="column-zones" [ngStyle]="zoneHeaderFlex">
                            <div class="text" [innerHTML]= "zonesHeader"></div>
                        </div>

                        <div id="column-reinforcement-headers" class="column-reinforcement" *ngIf="helper.isPerBar">
                            <div class="text">{{loadsHeader}}</div>
                        </div>

                        <div id="column-loads-headers" class="column-loads" *ngIf="helper.isExtensionsOrJointsHNA">
                            <div class="text">{{loadsHeader}}</div>
                        </div>

                        <div id="LC-table-shear-stress-section" class="column-shear-stress" *ngIf="!helper.areZonesVisible && helper.isOverlayHNAAndIsShearStress"
                            [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth != null ? (helper.resizerColumnWidth.tedX + helper.resizerColumnWidth.tedY) + 'px' : null }">
                            <div [ngStyle]="{ 'padding-left': '5px' }" [innerHTML]= "stressHeader"></div>
                        </div>

                        <div id="LC-table-forces-moments-section" *ngIf="!helper.areZonesVisible && isNotOverlayHNAOrIsShearForce"
                            [class.column-forces]="!helper.isOverlayHNA && !helper.isPerBarPir"
                            [class.column-forces-overlay-hna]="helper.isOverlayHNA"
                            [class.column-force]="helper.isPerBarPir"
                            [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth != null ? forcesFlexBasis + 'px' : null }">
                            <div class="text">{{forcesHeader}}</div>
                        </div>

                        <div *ngIf="!helper.areZonesVisible && !helper.isOverlayHNA"
                            [ngClass]="{'column-moments-without-moment-z': (helper.isPirEuOrAus|| helper.isExtensionsOrJointsHNA) && !helper.isPerBarPir, 'column-hidden': helper.isPerBarPir}"
                            [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth != null ? momentsFlexBasis + 'px' : null }">
                            <div class="text">{{momentsHeader}}</div>
                        </div>

                        <div *ngIf="helper.showUtilizations || helper.isEuropeanDesignStandardOverlay" class="column-utilization" [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.utilization) }">
                            <div class="text" l10n="Agito.Hilti.Profis3.Loads.Utilization"></div>
                        </div>

                        <div class="column-messages"></div>

                        <div class="column-collapse">
                            <button
                                id="LC-table-showhide-arrow-button-3"
                                class="collapse-button"
                                type="button"
                                (click)="collapsed = !collapsed; onCollapsedChanged()"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Region.ShowHide')">
                                <span class="pe-ui-c2c-sprite"
                                    [class.pe-ui-c2c-sprite-lines]="collapsed"
                                    [class.pe-ui-c2c-sprite-lines-expanded]="!collapsed"></span>
                            </button>
                        </div>
                    </div>
                </div>

                <div id="loads-content" class="loads-content concrete">
                    <div class="loads-sub-header">
                        <div class="header-sizer">
                            <div id="LC-table-combination-number-section" class="column-selected" *ngIf="!helper.isPerBarPir"></div>

                            <div class="column-rank" *ngIf="!helper.isPerBarPir"></div>

                            <div id="LC-table-combination-name-section" class="column-name"
                                [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth != null ? (helper.resizerColumnWidth.name - 1) + 'px' : null }">
                            </div>

                            <div id="LC-table-load-type-section" *ngIf="!helper.showDynamic"
                                class="column-type" [ngStyle]="{ 'flex-basis': helper.columnTypeWidth + 'px' }">
                                <div class="type-buttons btn-group" role="group" *ngIf="!helper.isLoadTypeZoneFatigue">
                                    <button
                                        *ngIf="helper.isLoadTypeAllowed(helper.loadTypeEnum.Static)"
                                        id="LC-table-static-loadtype-button"
                                        type="button"
                                        class="type-button btn btn-default"
                                        (click)="setAllLoads(1)"
                                        [disabled]="submitted"
                                        [ngbTooltip]="staticLoadTooltip">
                                        <span class="pe-ui-c2c-sprite pe-ui-c2c-sprite-anchor-shock"></span>
                                    </button>

                                    <button
                                        *ngIf="helper.isLoadTypeAllowed(helper.loadTypeEnum.Seismic)"
                                        id="LC-table-seismic-loadtype-button"
                                        type="button"
                                        class="type-button btn btn-default"
                                        (click)="setAllLoads(2)"
                                        [disabled]="submitted"
                                        [ngbTooltip]="seismicLoadTooltip">
                                        <span class="pe-ui-c2c-sprite pe-ui-c2c-sprite-anchor-seismic"></span>
                                    </button>

                                    <button
                                        *ngIf="helper.isLoadTypeAllowed(helper.loadTypeEnum.Fatigue)"
                                        id="LC-table-fatigue-loadtype-button"
                                        type="button"
                                        class="type-button btn btn-default"
                                        (click)="setAllLoads(3)"
                                        [disabled]="submitted"
                                        [ngbTooltip]="fatigueLoadTooltip">
                                        <span class="pe-ui-c2c-sprite pe-ui-c2c-sprite-anchor-fatigue"></span>
                                    </button>

                                    <button
                                        *ngIf="helper.isLoadTypeAllowed(helper.loadTypeEnum.Fire)"
                                        id="LC-table-fire-loadtype-button"
                                        type="button"
                                        class="type-button btn btn-default"
                                        (click)="setAllLoads(4)"
                                        [disabled]="submitted"
                                        [ngbTooltip]="fireLoadTooltip">
                                        <span class="pe-ui-c2c-sprite pe-ui-c2c-sprite-anchor-fire-resistant"></span>
                                    </button>
                                </div>
                            </div>

                            <div id="LC-table-lower-shear-section" class="column-loads C2C" *ngIf="helper.showDynamic">
                                <div class="text" l10n="Agito.Hilti.C2C.Loads.LowerShear"></div>
                            </div>

                            <div class="column-reinforcement" *ngIf="helper.isPerBar"></div>

                            <div class="column-loads" *ngIf="helper.isExtensionsOrJointsHNA"></div>

                            <div id="LC-table-tau-x-column" class="column-stress column-tedx"
                                *ngIf="!helper.areZonesVisible && helper.isOverlayHNAAndIsShearStress"
                                [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.tedX) }">
                                <div class="text" l10n="Agito.Hilti.C2C.Loads.TedX"></div>
                            </div>

                            <div id="LC-table-tau-y-column" class="column-stress column-tedy"
                                *ngIf="!helper.areZonesVisible && helper.isOverlayHNAAndIsShearStress"
                                [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.tedY) }">
                                <div class="text" l10n="Agito.Hilti.C2C.Loads.TedY"></div>
                            </div>

                            <div id="LC-table-Vx-force-column" class="column-force column-vx"
                                *ngIf="!helper.areZonesVisible && isNotOverlayHNAOrIsShearForce && !helper.isPerBarPir"
                                [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.forceVx) }">
                                <div class="text" l10n="Agito.Hilti.Profis3.Loads.Vx"></div>
                            </div>

                            <div id="LC-table-Vy-force-column" class="column-force column-vy"
                                *ngIf="!helper.areZonesVisible && isNotOverlayHNAOrIsShearForce && !helper.isPerBarPir"
                                [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.forceVy) }">
                                <div class="text" l10n="Agito.Hilti.Profis3.Loads.Vy"></div>
                            </div>

                            <div id="LC-table-N-force-column" class="column-force column-n"
                                *ngIf="!helper.areZonesVisible && !helper.isOverlayHNA"
                                [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.forceN) }">
                                <div class="text" l10n="Agito.Hilti.Profis3.Loads.N"></div>
                            </div>

                            <div id="LC-table-Mx-moment-column" class="column-moment column-mx"
                                *ngIf="!helper.areZonesVisible && !helper.isOverlayHNA && !helper.isPerBarPir"
                                [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.momentMx) }">
                                <div class="text" l10n="Agito.Hilti.Profis3.Loads.Mx"></div>
                            </div>

                            <div id="LC-table-My-moment-column" class="column-moment column-my"
                                *ngIf="!helper.areZonesVisible && !helper.isOverlayHNA && !helper.isPerBarPir"
                                [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.momentMy) }">
                                <div class="text" l10n="Agito.Hilti.Profis3.Loads.My"></div>
                            </div>

                            <div id="LC-table-Mz-moment-column" class="column-moment column-mz"
                                *ngIf="!helper.areZonesVisible && !helper.isOverlayHNA && !helper.isPropertyHidden(propertyMetaData.Loads_C2C_MomentZ.id)"
                                [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.momentMz) }">
                                <div class="text" l10n="Agito.Hilti.Profis3.Loads.Mz"></div>
                            </div>

                            <div id="LC-table-zone-ted-column" class="column-zone column-zone-ted"
                                *ngIf="helper.areZonesVisible && helper.isZoneVisible(0)"
                                [ngStyle]="helper.zoneOneFlex">
                                <div class="text" [innerHTML]="tedVedTranslation"></div>
                            </div>

                            <div id="LC-table-zone-1-column" class="column-zone column-zone-1"
                                *ngIf="helper.areZonesVisible && helper.isZoneVisible(1)"
                                [ngStyle]="helper.zoneOneFlex">
                                <div class="text" l10n="Agito.Hilti.C2C.Navigation.TabProduct.Zone1"></div>
                            </div>

                            <div id="LC-table-zone-2-column" class="column-zone column-zone-2"
                                *ngIf="helper.areZonesVisible && helper.isZoneVisible(2)"
                                [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.zone2) }">
                                <div class="text" l10n="Agito.Hilti.C2C.Navigation.TabProduct.Zone2"></div>
                            </div>

                            <div id="LC-table-zone-3-column" class="column-zone column-zone-3"
                                *ngIf="helper.areZonesVisible && helper.isZoneVisible(3)"
                                [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.zone3) }">
                                <div class="text" l10n="Agito.Hilti.C2C.Navigation.TabProduct.Zone3"></div>
                            </div>

                            <div id="LC-table-zone-4-column" class="column-zone column-zone-4"
                                *ngIf="helper.areZonesVisible && helper.isZoneVisible(4)"
                                [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.zone4) }">
                                <div class="text" l10n="Agito.Hilti.C2C.Navigation.TabProduct.Zone4"></div>
                            </div>

                            <div *ngIf="helper.isEuropeanDesignStandardOverlay"
                                class="column-utilization" [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.utilization) }">
                                <div class="utilization-text">
                                    <div class="text" l10n="Agito.Hilti.C2C.Utilizations.OverlayUtilization.Max"></div>
                                </div>
                            </div>

                            <!-- column utilizations C2C -->
                            <div *ngIf="helper.showUtilizations" class="column-utilization" #utilizationColumnRef
                                [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.utilization) }">
                                <div class="utilization-text" *ngIf="!helper.isUtilizationCompact && design.isHnaJointsAnchorTheory">
                                    <div class="centered-text" l10n="Agito.Hilti.Profis3.Loads.Tension.Short"></div>
                                </div>
                                <div class="utilization-text" *ngIf="(!helper.isUtilizationCompact && design.isHnaJointsAnchorTheory) || !design.isHnaJointsAnchorTheory">
                                    <div class="centered-text" l10n="Agito.Hilti.C2C.Loads.Shear.Short"></div>
                                </div>
                                <div class="utilization-text" *ngIf="design.isHnaJointsAnchorTheory">
                                    <div class="centered-text" l10n="Agito.Hilti.Profis3.Loads.Total"></div>
                                </div>
                            </div>

                            <div class="column-messages"></div>

                            <div class="column-delete">
                                <div [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DeleteInactive')"
                                    [disableTooltip]="submitted">
                                    <button
                                        id="LC-table-bin-button"
                                        type="button"
                                        class="delete-button"
                                        (click)="deleteLoads()"
                                        [disabled]="submitted || helper.haveSingleLoad || areLoadsWizardGenerated || helper.isPerBarPir"
                                        [disableTooltip]="submitted"
                                        [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DeleteInactive')">
                                        <span class="pe-ui-c2c-sprite pe-ui-c2c-sprite-trash"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="loads-rows">
                        <virtual-scroller #scrollLoadsC2C
                            [items]="loads" [scrollAnimationTime]="0"
                            [ngStyle]="{ 'height': '100%', 'overflow-x': 'hidden' }" (vsUpdate)="virtualScrollChange($any($event))">

                            <c2c-loads-row
                                class="loads-row-component"
                                *ngFor="let load of scrollLoadsC2C.viewPortItems; index as viewportIndex; trackBy:identifyLoad;"
                                [resizerWrapper]="resizerWrapper"
                                [helper]="helper"
                                [selectedLoadId]="selectedLoadId"
                                [decisiveLoadId]="decisiveLoadId"
                                [isAdd]="false"
                                [parentId]="'load-combinations'"
                                [load]="load"
                                [loadIndex]="scrollLoadsC2C.viewPortInfo.startIndex + viewportIndex"
                                [disabled]="disabled"
                                [submitted]="submitted"
                                (onLoadSelected)="selectLoad($any($event).detail)"
                                (onChangeLoad)="changeLoad()"
                                (onDeleteLoad)="deleteLoad($any($event).detail)"
                                (columnsResize)="columnsResized()"
                                [isUtilizationCompact]="helper.isUtilizationCompact">
                            </c2c-loads-row>

                            <c2c-loads-row
                                class="loads-row-component"
                                [resizerWrapper]="resizerWrapper"
                                *ngIf="helper.addingNewLoad"
                                [helper]="helper"
                                [selectedLoadId]="selectedLoadId"
                                [isAdd]="true"
                                [parentId]="'load-combinations'"
                                [load]="helper.newLoad"
                                [disabled]="disabled"
                                [submitted]="submitted"
                                (onAddLoad)="addNewLoad($any($event).detail)">
                            </c2c-loads-row>
                        </virtual-scroller>
                    </div>
                </div>
            </div>

            <div class="loads-sub-footer" *ngIf="rowsLoaded" [class.visible]="!collapsed">
                <div class="calculation-mode" [hidden]="loads.length <= 1 || helper.isPirEuOrAus">
                    <pe-radio-button [look]="loadsCalculationModeRadio.look"
                                     [items]="loadsCalculationModeRadio.items"
                                     [selectedValue]="loadsCalculationMode"
                                     (selectedValueChange)="loadsCalculationModeRadioSelectedValueChange($any($event).detail)">
                    </pe-radio-button>
                </div>

                <div class="new-label" *ngIf="showNewLabel">
                    <span l10n="Agito.Hilti.C2C.Loads.NewStatus"></span>
                </div>
                <pe-button id="LC-table-decisive-button"
                           [ngbTooltip]="loadTooltip"
                           [look]="'Default'"
                           class="sub-footer-button button"
                           *ngIf="showDecisiveButton"
                           (buttonClick)="updateToDecisiveButton()">
                    {{translate("Agito.Hilti.Profis3.Notifications.UpdateToDecisiveLoadCase")}}
                </pe-button>
                <pe-button id="LC-table-inputcustomfactoredloads-button"
                           [ngbTooltip]="loadTooltip"
                           [look]="'Default'"
                           class="sub-footer-button button"
                           *ngIf="showAddButton"
                           (buttonClick)="openShowNewLoadWarning()"
                           [disabled]="newLoadDisabled">
                    {{translate("Agito.Hilti.Profis3.Loads.AddLoad")}}
                </pe-button>
                <pe-button id="LC-table-importfactoredloadsfromspreadsheet-button"
                           [ngbTooltip]="loadTooltip"
                           [look]="'Default'"
                           class="import-loads-button sub-footer-button button"
                           *ngIf="showAddButton"
                           (buttonClick)="importLoads()"
                           [disabled]="importLoadDisabled">
                    {{translate(importLoadsTranslation)}}
                </pe-button>
            </div>
        </div>
    </pe-resizer>
</div>
