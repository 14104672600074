import { IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import {
    DesignExternalMetaData,
    DocumentAccessMode,
    DocumentServiceBase
} from '@profis-engineering/pe-ui-common/services/document.common';
import { ProjectDesignEntityC2C } from '../generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { IDetailedDisplayDesign } from '../entities/display-design';

/**
 * DocumentServiceBaseC2C base
 */
export abstract class DocumentServiceBaseC2C extends DocumentServiceBase {

    public abstract openDesignExclusiveC2C(design: IBaseDesign): Promise<ProjectDesignEntityC2C>;
    public abstract updateDesignWithNewContentC2C(_designId: string, _projectId: string, _designName: string, _contentOverride: ProjectDesignEntityC2C, _metadataOverride: DesignExternalMetaData, _displayDesign?: IDetailedDisplayDesign, _unlock?: boolean, _exclusiveLock?: boolean, _documentAccessMode?: DocumentAccessMode): Promise<void>;
    public abstract openDesignC2C(design: IBaseDesign): Promise<ProjectDesignEntityC2C>;
    public abstract createUniqueName(oldName: string, usedNames: string[]): string;
}
