export enum DesignCodeList {
    LoadType,
    FireDuration,
    EmbedmentDepth,
    RebarDiameterMode,
    EmbedmentDepthMode,
    SpacingMode,
    ProductFilter,
    ProductFilterGroup,
    OptimizationType,
    ConcreteReinforcement,
    EdgeReinforcement,
    BaseMaterialStructure,
    TemperatureInstallation,
    OverlayPosition,
    DrillingType,
    HoleType,
    JointRoughness,
    RowsNumberMode,
    EdgeDistanceMode,
    ZonesNumber,
    LoadDefinitionTypes,
    LoadingDefinitionTypes,
    Roughness,
    DrillingAid,
    LoadTypeCategory,
    BondCondition,
    ReinforcmentAlphaCoefficient,
    SurfaceTreatment,
    ConcreteDensity,
    ConcreteShearInterfaceMode,
    ExistingReinforcementDiameter,
    SpliceClass,
    ReinforcedInterface,
    ConnectorLength,
    EmbedmentDepthExisting,
    EmbedmentDepthOverlay,
    FastenerFamily,
    FastenerSize,
    FastenerType,
    BarType,
    LocationFactor,
    Overstrength,
    HoleCondition,
    ContactSurfaceCondition,
    ReinforcementTensionCondition,
    RebarMaterial,
    GenericRebarDiameter,
    KBNumberControlRegion,
    CrossSection,
    LongitudinalReinforcementOption,
    ShapeType,
    SpacingBarsOption,
    TransverseReinforcementOption,
    CalculationMode,
    DesignWorkingLife,
    ShearInterfaceType,
    LoadDefinitionSections,
    ReinforcementType,
    NumberOfLayers,
    FireInputTypes,
    CrackWidth,
    ApplicationTypesC2C,
    DesignMethodGroupsC2C,
    DesignMethods,
    DesignMethodConnectionTypeMethodGroup,
    DesignForYield
}
