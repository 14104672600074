import beamReinforcement from './beam-reinforcement.png';
import floorReinforcement from './floor-reinforcement.png';
import genericReinforcement from './generic-reinforcement.png';
import jacketting from './jacketting.png';
import square from './Square.png';
import wallReinforcement from './wall-reinforcement.png';

const images = {
    'beam-reinforcement.png': beamReinforcement,
    'floor-reinforcement.png': floorReinforcement,
    'generic-reinforcement.png': genericReinforcement,
    'jacketting.png': jacketting,
    'Square.png': square,
    'wall-reinforcement.png': wallReinforcement
} satisfies Record<string, string>;

export default images as typeof images & { [imageName: string]: string };
