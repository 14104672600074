import {
    Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnChanges, OnInit, Output,
    SimpleChanges, ViewEncapsulation
} from '@angular/core';
import {
    RadioButtonItem
} from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import {
    TextBoxBackground, TextBoxDisplay
} from '@profis-engineering/pe-ui-common/components/text-box/text-box.common';
import {
    ToggleButtonGroupItem
} from '@profis-engineering/pe-ui-common/components/toggle-button-group/toggle-button-group.common';
import { IIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';

import {
    LoadCombinationC2C
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    ConnectionType, DesignStandard, LoadingDefinitionType, LoadType
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import {
    FeatureFlagTypes
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.FeatureFlags';
import { PropertyMetaDataC2C } from '../../../shared/properties/properties';
import { InternalLoadType, LoadsComponentHelper } from '../../helpers/loads-component-helper';
import { LocalizationService } from '../../services/localization.service';
import { UserService } from '../../services/user.service';
import { getSpriteAsIconStyle, includeSprites, Sprite } from '../../sprites';

@Component({
    templateUrl: './loads-row.component.html',
    styleUrls: ['./loads-base.scss', './loads-rows-base.scss', './loads-row.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
/* eslint @angular-eslint/component-class-suffix: ["off"] */
// tslint:disable-next-line: component-class-suffix
export class LoadsRowComponent implements OnInit, OnChanges {
    @Input()
    public resizerWrapper!: HTMLElement;

    @Input()
    public parentId!: string;

    @Input()
    public load!: LoadCombinationC2C;

    @Input()
    public loadIndex!: number;

    @Input()
    public helper!: LoadsComponentHelper;

    @Input()
    public isAdd = false;

    @Input()
    public selectedLoadId!: string;

    @Input()
    public decisiveLoadId!: string;

    @Input()
    public disabled!: boolean;

    @Input()
    public submitted!: boolean;

    @Input()
    public isUtilizationCompact = false;

    @Output()
    // LEGACY - do not exclude for new code
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onLoadSelected = new EventEmitter<string>();

    @Output()
    // LEGACY - do not exclude for new code
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onAddLoad = new EventEmitter<LoadCombinationC2C>();

    @Output()
    // LEGACY - do not exclude for new code
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onChangeLoad = new EventEmitter<LoadCombinationC2C>();

    @Output()
    // LEGACY - do not exclude for new code
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onDeleteLoad = new EventEmitter<LoadCombinationC2C>();

    @Output()
    public columnsResize = new EventEmitter<void>();

    public dataLoaded = false;

    public propertyMetaData = PropertyMetaDataC2C;

    public selectedLoadItems: RadioButtonItem<string>[] = [];
    public loadTypeToggleItems: ToggleButtonGroupItem<InternalLoadType>[] = [];

    private continueColumnResizingFn!: (event: MouseEvent) => void;
    private endColumnResizingFn!: (event: MouseEvent) => void;

    private _onAddLoadType: LoadType = LoadType.Static;

    private oldInnerWidth: number;

    private readonly loadsRowInputNameLargeHeight: number = 65;

    constructor(
        private localization: LocalizationService,
        private user: UserService,
        private elementRef: ElementRef<HTMLElement>,
        private ngZone: NgZone
    ) {
        this.oldInnerWidth = innerWidth;
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-x',
            'sprite-ok',
            'sprite-warning',
            'sprite-trash');

        this.oldInnerWidth = innerWidth;
        this.design.onStateChanged(() => this.init());
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.dataLoaded && changes['load'].currentValue != null && changes['helper'].currentValue != null) {
            this.init();

            this.dataLoaded = true;
            return;
        }

        if (this.dataLoaded && changes['load'] != null) {
            if (this.selectedLoadItems?.length > 0) {
                this.setSelectedLoadItemId(this.selectedLoadItems[0]);
                this.selectedLoadItems[0].value = this.load.id;
            }

            if (this.loadTypeToggleItems?.length > 0) {
                this.loadTypeToggleItems.forEach(item => this.helper.setLoadTypeToggleItemId(item, this.parentId, this.loadIndex));
            }
        }
    }

    private init() {
        this.selectedLoadItems = [
            {
                id: '',
                text: '',
                value: this.load.id
            }
        ];
        this.setSelectedLoadItemId(this.selectedLoadItems[0]);

        const loadSprites: [InternalLoadType, Sprite][] = [
            [InternalLoadType.Static, 'sprite-anchor-shock'],
            [InternalLoadType.Seismic, 'sprite-anchor-seismic'],
            [InternalLoadType.Fatigue, 'sprite-anchor-fatigue'],
            [InternalLoadType.Fire, 'sprite-anchor-fire-resistant']
        ];
        const loadToIconMap = new Map<InternalLoadType, IIconStyle>(loadSprites.map(m => [m[0], getSpriteAsIconStyle(m[1])]));
        this.loadTypeToggleItems = this.helper.loadLoadTypeToggleItems(this.parentId, this.loadIndex, loadToIconMap);

        this.continueColumnResizingFn = this.continueColumnResizing.bind(this);
        this.endColumnResizingFn = this.endColumnResizing.bind(this);
    }

    public get design() {
        return this.user.design;
    }

    public get isDataOk() {
        return this.user?.design != null;
    }

    public get isPIR() {
        return this.design.connectionType == ConnectionType.StructuralJoints || this.design.connectionType == ConnectionType.Splices;
    }

    public get isJointsHNA() {
        const isHNA = this.design.designStandardC2C?.id == DesignStandard.ACI || this.design.designStandardC2C?.id == DesignStandard.CSA;
        return this.design.connectionType == ConnectionType.StructuralJoints && isHNA;
    }

    public get isDecisive() {
        return this.load.id == this.decisiveLoadId && !this.helper.isPerBarPir;
    }

    public get isSelected() {
        return this.load.id == this.selectedLoadId && !this.helper.isPerBarPir;
    }

    public get showColoredRow() {
        return this.isPIR && this.isDecisive && this.design.isFeatureEnabled(FeatureFlagTypes.Decisive);
    }

    public get showBold() {
        return this.design.isFeatureEnabled(FeatureFlagTypes.Decisive) && this.isPIR && this.isSelected || !this.isPIR && this.isDecisive;
    }

    public get textBoxDisplay() {
        if (this.isPIR && this.design.isFeatureEnabled(FeatureFlagTypes.Decisive)) {
            return this.isSelected ? TextBoxDisplay.Bold : TextBoxDisplay.Normal;
        }
        return this.isDecisive ? TextBoxDisplay.Bold : TextBoxDisplay.Normal;
    }

    public get textBoxBackground() {
        return this.isPIR && this.isDecisive && this.design.isFeatureEnabled(FeatureFlagTypes.Decisive) ? TextBoxBackground.Decisive : TextBoxBackground.Normal;
    }

    public get nameInputHeight() {
        return this.helper.showDynamic || this.helper.isPerBar || this.isJointsHNA || this.helper.isExtensionsOrJointsHNA
            ? this.loadsRowInputNameLargeHeight
            : undefined;
    }

    public get noValueString() {
        return this.helper.isPirEuOrAus ? '' : this.translate('Nolasoft.Hilti.Profis3.Report.NA');
    }

    public get perBarTopTranslation() {
        return this.translate('Agito.Hilti.Profis3.Loads.Top');
    }

    public get perBarBottomTranslation() {
        return this.translate('Agito.Hilti.Profis3.Loads.Bottom');
    }

    public get anchorTheoryDesignTranslation() {
        return this.translate('Agito.Hilti.C2C.Loads.Design');
    }

    public get anchorTheorySustainedTranslation() {
        return this.translate('Agito.Hilti.C2C.Loads.Sustained');
    }

    public get isNotOverlayHNAOrIsNotShearStress() {
        return !this.helper.isOverlayHNA || !this.helper.isShearStress;
    }

    public get tedVedMinTranslation() {
        const loadingDesignType = this.design.model[PropertyMetaDataC2C.Loads_C2C_LoadingDefinitionType.id] as LoadingDefinitionType;
        return (loadingDesignType == LoadingDefinitionType.ShearLoad)
            ? `${this.translate('Agito.Hilti.C2C.Loads.VedMin')}`
            : `${this.translate('Agito.Hilti.C2C.Loads.TedMin').replace('τ', '<span class="tauFontSmall">τ</span>')}`;
    }

    public get tedVedMaxTranslation() {
        const loadingDesignType = this.design.model[PropertyMetaDataC2C.Loads_C2C_LoadingDefinitionType.id] as LoadingDefinitionType;
        return (loadingDesignType == LoadingDefinitionType.ShearLoad)
            ? `${this.translate('Agito.Hilti.C2C.Loads.VedMax')}`
            : `${this.translate('Agito.Hilti.C2C.Loads.TedMax').replace('τ', '<span class="tauFontSmall">τ</span>')}`;
    }

    public selectLoad() {
        this.onLoadSelected.emit(this.load.id);
    }

    public loadNameChanged(name: string) {
        if (name !== undefined) {
            if (this.load.name != name) {
                this.load.name = name;

                if (this.isAdd) {
                    // Handled when actually adding a load
                    return;
                }

                this.onChangeLoad.emit();
            }
        }
    }

    public loadActiveLoadTypeChange(type: InternalLoadType) {
        if (type !== undefined) {
            const loadType = (type as number) as LoadType;
            if (this.load.activeLoadType != loadType) {
                this.load.activeLoadType = loadType;

                if (this.isAdd) {
                    this._onAddLoadType = loadType;
                    return;
                }

                this.onChangeLoad.emit();
            }
        }
    }

    public loadValueChange(property: keyof LoadCombinationC2C, value: number) {
        if (property != null && value != null) {
            if (this.load[property] != value) {
                (this.load[property] as any) = value;

                if (this.isAdd) {
                    // Handled when actually adding a load
                    return;
                }

                this.onChangeLoad.emit();
            }
        }
    }

    public addNewLoad() {
        this.onAddLoad.emit(this.helper.newLoad);
    }

    public deleteLoad(load: LoadCombinationC2C) {
        this.onDeleteLoad.emit(load);
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    private setSelectedLoadItemId(item: RadioButtonItem<string>) {
        item.id = `${this.parentId}}-${this.loadIndex != null ? this.loadIndex : 'new-load'}-selected-button`;
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize() {
        // reset column widths on window width resize
        if (this.oldInnerWidth != innerWidth) {
            this.helper.resizerColumnWidth = undefined;
            this.oldInnerWidth = innerWidth;
        }
    }

    // Resizing
    public beginColumnResizing(event: MouseEvent) {
        event.preventDefault();
        const mouse = this.helper.calculateMouseInsideElement(event, this.resizerWrapper);

        this.helper.beginColumnResizing(mouse, event.currentTarget as HTMLElement);

        // Append resized and attach events
        this.resizerWrapper.append(this.helper.resizerOverlayElement);
        document.addEventListener('mousemove', this.continueColumnResizingFn, false);
        document.addEventListener('mouseup', this.endColumnResizingFn, false);
    }

    private continueColumnResizing(event: MouseEvent) {
        event.preventDefault();
        const mouse = this.helper.calculateMouseInsideElement(event, this.resizerWrapper);

        this.helper.continueColumnResizing(mouse);
    }

    private endColumnResizing(event: MouseEvent) {
        NgZone.assertInAngularZone();

        event.preventDefault();
        const mouse = this.helper.calculateMouseInsideElement(event, this.resizerWrapper);

        this.helper.endColumnResizing(mouse);

        // Cleanup
        this.helper.resizerOverlayElement.remove();
        document.removeEventListener('mousemove', this.continueColumnResizingFn, false);
        document.removeEventListener('mouseup', this.endColumnResizingFn, false);

        this.columnsResize.emit();
    }

    /**
     * Returns value or null if UIProperty is hidden or disabled
     * @param propertyId UIProperty id
     * @param value value
     * @returns null or numberic value
     */
    public valueOrNullIfPropHiddenOrDisabled(propertyId: number, value: number) {
        if (this.helper.isPirEuOrAus) {
            return this.helper.isPropertyDisabled(propertyId) || this.helper.isPropertyHidden(propertyId) ? undefined : value;
        }

        return value;
    }

    public isLoadPropertyDisabled(propertyId: number): boolean {
        const propertyName = this.getPropertyNameById(propertyId);

        if (this.design.isPirHna) {
            if (this.load.activeLoadType == LoadType.Fire
                || (this.isAdd && this._onAddLoadType == LoadType.Fire)) {
                if (propertyName != null) {
                    this.loadValueChange(propertyName, 0);
                    return true;
                }
            }
        }

        return this.submitted || this.load.isWizardGenerated || this.helper.isPropertyDisabled(propertyId);
    }

    private getPropertyNameById(id: number): keyof LoadCombinationC2C | undefined {
        switch (id) {
            case this.propertyMetaData.Loads_C2C_ForceX.id:
                return 'forceX';
            case this.propertyMetaData.Loads_C2C_ForceY.id:
                return 'forceY';
            default:
                return undefined;
        }
    }

}
