import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';

import {
    AfterViewInit, Component, ElementRef, HostListener, Input, NgZone, OnChanges, OnDestroy, OnInit,
    SimpleChanges, TrackByFunction, ViewChild, ViewEncapsulation
} from '@angular/core';
import { VirtualScrollerComponent } from '@iharbeck/ngx-virtual-scroller';
import {
    RadioButtonProps, RadioLook
} from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import { DesignEvent, Properties } from '@profis-engineering/pe-ui-common/entities/design';
import {
    Feature
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { environment } from '../../../environments/environmentC2C';
import { DesignC2C as Design } from '../../../shared/entities/design-c2c';
import {
    LoadCombinationC2C, UIProperty as UIPropertyC2C
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    ApplicationType, CalculationMode, ConnectionType, DesignStandard, LoadCombinationsCalculationType, LoadingDefinitionType,
    LoadType
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { FeatureFlagTypes } from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.FeatureFlags';
import { PropertyMetaDataC2C } from '../../../shared/properties/properties';
import { InternalLoadType, LoadsComponentHelper } from '../../helpers/loads-component-helper';
import { CalculationServiceC2C } from '../../services/calculation-c2c.service';
import { CodeListService } from '../../services/code-list.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { GuidService } from '../../services/guid.service';
import { LicenseService } from '../../services/license.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { TranslationFormatService } from '../../services/translation-format.service';
import { UnitService } from '../../services/unit.service';
import { UserService } from '../../services/user.service';
import { includeSprites } from '../../sprites';

export const DEFAULT_MAX_LOADS_HEIGHT = 334;

interface IDesignChangeDetectionModel {
    model: { [property: number]: any };
    properties: Properties;
}

@Component({
    templateUrl: './loads.component.html',
    styleUrls: ['../loads-row/loads-base.scss', './loads.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class LoadsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    private static readonly loadsRowHeight: number = 32; // sync with css variable @loads-row-height
    private static readonly resizedAndBorderPadding: number = 6; // 4 resizer + 2 borders

    @Input()
    public selectedLoadId!: string;

    @Input()
    public decisiveLoadId!: string;

    @Input()
    public disabled!: boolean;

    @Input()
    public resize3d!: (resetHighlightedComponents?: boolean) => void;

    @Input()
    public parentElement?: HTMLElement;

    public scrollLoadsFirstItemId?: string;

    @ViewChild('scrollLoadsC2C')
    public scrollLoadsElement!: VirtualScrollerComponent;

    @ViewChild('utilizationColumnRef')
    public utilizationColumnRef!: ElementRef<HTMLElement>;

    public propertyMetaData = PropertyMetaDataC2C;

    public rowsLoaded!: boolean;

    public collapsed = false;
    public submitted!: boolean;

    public loadsCalculationMode: LoadCombinationsCalculationType | null = null;
    public loadsCalculationModeRadio!: RadioButtonProps<LoadCombinationsCalculationType>;

    public helper: LoadsComponentHelper;

    private tableHeight!: number;
    private destroyed!: boolean;

    // Change detection
    private oldDesign!: IDesignChangeDetectionModel;
    private oldShowDynamic!: boolean;

    constructor(
        private localization: LocalizationService,
        private unit: UnitService,
        private guid: GuidService,
        private modal: ModalService,
        private user: UserService,
        private featuresVisibilityInfo: FeaturesVisibilityInfoService,
        private ngZone: NgZone,
        private calculationService: CalculationServiceC2C,
        translationFormatService: TranslationFormatService,
        codeList: CodeListService,
        private elementRef: ElementRef<HTMLElement>,
        private licenseService: LicenseService
    ) {
        this.helper = new LoadsComponentHelper(
            localization,
            user.design as unknown as Design,
            unit,
            modal,
            translationFormatService,
            codeList
        );
    }

    public get design() {
        return this.user.design;
    }

    public get loads() {
        return this.design.loadCombinationsC2C;
    }

    public get utilizationFlexBasis() {
        return this.collapsed ? 0 : this.helper.resizerColumnWidth?.utilization ?? 0;
    }

    public get zoneHeaderFlex() {
        const zoneHeaderFlex = this.helper.numberZones * this.helper.headerFlexValue;

        if (this.helper.resizerColumnWidth == null) {
            if (this.helper.numberZones == 1) {
                return { flex: 2 * zoneHeaderFlex };
            }
            else {
                return { flex: zoneHeaderFlex };
            }
        }
        else if (this.collapsed) {
            return { flex: zoneHeaderFlex, 'flex-basis': this.helper.zonesHeaderWidth + 'px' };
        }
        else {
            return { 'flex-basis': this.helper.zonesHeaderWidth + 'px' };
        }
    }

    public get importLoadsTranslation() {
        return 'Agito.Hilti.Profis3.Loads.ImportLoadsCombinations';
    }

    public get forcesHeader() {
        return `${this.translate('Agito.Hilti.Profis3.Loads.Forces')} [${this.unit.formatUnit(this.unit.getDefaultUnit(UnitGroup.Force))}]`;
    }

    public get momentsHeader() {
        return `${this.translate('Agito.Hilti.Profis3.Loads.Moments')} [${this.unit.formatUnit(this.unit.getDefaultUnit(UnitGroup.Moment))}]`;
    }

    public get zonesHeader() {
        const loadingDesignType = this.design.model[PropertyMetaDataC2C.Loads_C2C_LoadingDefinitionType.id] as LoadingDefinitionType;
        return (loadingDesignType == LoadingDefinitionType.ShearLoad)
            ? `${this.translate('Agito.Hilti.C2C.Loads.Load')} [${this.unit.formatUnit(this.unit.getDefaultUnit(UnitGroup.Force))}]`
            : `${this.translate('Agito.Hilti.C2C.Loads.Stress').replace('τ', '<span class="tauFontSmall">τ</span>')} [${this.unit.formatUnit(this.unit.getDefaultUnit(UnitGroup.Stress))}]`;
    }

    public get stressHeader() {
        return `${this.translate('Agito.Hilti.C2C.Loads.Stress').replace('τ', '<span class="tauFontSmall">τ</span>')} [${this.unit.formatUnit(this.unit.getDefaultUnit(UnitGroup.Stress))}]`;
    }

    public get loadsHeader() {
        return this.translate('Agito.Hilti.Profis3.Loads.Loads');
    }

    public get selectedLoad(): LoadCombinationC2C | null {
        if (this.loads != null && this.selectedLoadId != null) {
            const selectedLoad = this.loads.find((load) => load.id == this.selectedLoadId) ?? null;

            if (!selectedLoad && this.helper.isPerBarPir) {
                return this.loads[0];
            }

            return selectedLoad;
        }

        return null;
    }

    public get selectedLoadTypeName() {
        return this.selectedLoad != null
            ? this.translate(`Agito.Hilti.Profis3.InfoSection.ActiveLoadSection.${InternalLoadType[this.selectedLoad.activeLoadType]}`)
            : null;
    }

    public get tedVedTranslation() {
        const loadingDesignType = this.design.model[PropertyMetaDataC2C.Loads_C2C_LoadingDefinitionType.id] as LoadingDefinitionType;
        return (loadingDesignType == LoadingDefinitionType.ShearLoad)
            ? `${this.translate('Agito.Hilti.C2C.Loads.Ved')}`
            : `${this.translate('Agito.Hilti.C2C.Loads.Ted').replace('τ', '<span class="tauFontSmall">τ</span>')}`;
    }

    public get tedVedMinTranslation() {
        const loadingDesignType = this.design.model[PropertyMetaDataC2C.Loads_C2C_LoadingDefinitionType.id] as LoadingDefinitionType;
        return (loadingDesignType == LoadingDefinitionType.ShearLoad)
            ? 'Agito.Hilti.C2C.Loads.VedMin'
            : 'Agito.Hilti.C2C.Loads.TedMin';
    }

    public get tedVedMaxTranslation() {
        const loadingDesignType = this.design.model[PropertyMetaDataC2C.Loads_C2C_LoadingDefinitionType.id] as LoadingDefinitionType;
        return (loadingDesignType == LoadingDefinitionType.ShearLoad)
            ? 'Agito.Hilti.C2C.Loads.VedMax'
            : 'Agito.Hilti.C2C.Loads.TedMax';
    }

    public get titleTranslation() {
        return 'Agito.Hilti.Profis3.Navigation.TabLoads.RegionLoads.ControlLoadCombinations.Title';
    }

    public get maxLoads() {
        return this.helper.maxLoads(environment.maxLoadCombinationsPirEuC2C, environment.maxLoadCombinationsC2C);
    }

    public get newLoadDisabled() {
        return this.submitted || this.helper.addingNewLoad || this.loads == null || this.loads.length >= this.maxLoads
            || this.isFreeLicenseAndLimitedAnchorcode || this.featuresVisibilityInfo.isDisabled(Feature.Design_LoadCombination, this.design.region.id)
            || this.helper.isFullyOptimizedHnaDesignStandardOverlay || !this.helper.isMultiLoadEnabled;
    }

    public get importLoadDisabled() {
        return this.submitted || this.helper.addingNewLoad || this.loads == null || (this.loads.length >= this.maxLoads)
            || this.isFreeLicenseAndLimitedAnchorcode || this.featuresVisibilityInfo.isDisabled(Feature.Design_LoadCombination, this.design.region.id)
            || this.helper.isFullyOptimizedHnaDesignStandardOverlay || !this.helper.isMultiLoadEnabled;
    }

    public get loadTooltip() {
        const hasFreeOrLimitedLicense = this.isFreeLicenseAndLimitedAnchorcode;
        const isUserDefined = this.design.designData.projectDesignC2C.product.calculationMode == CalculationMode.Verification;

        if (this.helper.isFullyOptimizedHnaDesignStandardOverlay && !hasFreeOrLimitedLicense) {
            return this.translate('Agito.Hilti.C2C.Features.Design.LoadCombination.Tooltip');
        } else if (this.helper.isOptimizedEuDesignOverlay && !isUserDefined) {
            return this.translate('Agito.Hilti.C2C.Loads.MultipleLCNotAllowed.Overlay.Tooltip');
        } else if (this.helper.isOptimizedEuDesignPirEU && !isUserDefined) {
            return this.translate('Agito.Hilti.C2C.Loads.MultipleLCNotAllowed.Pir.Tooltip');
        }

        return hasFreeOrLimitedLicense || this.featuresVisibilityInfo.isDisabled(Feature.Design_LoadCombination, this.design.region.id)
            ? this.translate('Agito.Hilti.Profis3.Features.Design.ShowInputLoads.Tooltip') : null;
    }

    public get areLoadsWizardGenerated() {
        return this.loads.every((l) => l.isWizardGenerated);
    }

    public get showAddButton() {
        return this.determineFeatureVisibility(true);
    }

    public get showNewLabel() {
        return this.determineFeatureVisibility(false);
    }

    public get isNotOverlayHNAOrIsShearForce() {
        return !this.helper.isOverlayHNA || this.isShearForce;
    }

    public get forcesFlexBasis(): number {
        if (this.helper.isPerBarPir) {
            return this.helper.resizerColumnWidth?.forceN ?? 0;
        }

        const forceVx = this.helper.resizerColumnWidth?.forceVx ?? 0;
        const forceVy = this.helper.resizerColumnWidth?.forceVy ?? 0;
        const forceN = !this.helper.isOverlayHNA ? this.helper.resizerColumnWidth?.forceN ?? 0 : 0;

        return forceVx + forceVy + forceN;
    }

    public get momentsFlexBasis(): number {
        if (this.helper.isPerBarPir) {
            return 0;
        }

        const momentMx = this.helper.resizerColumnWidth?.momentMx ?? 0;
        const momentMy = this.helper.resizerColumnWidth?.momentMy ?? 0;
        const momentMz = this.helper.resizerColumnWidth?.momentMz ?? 0;

        return momentMx + momentMy + momentMz;
    }

    public get columnForcesWithForceN() {
        return !this.showForceX && !this.helper.columnVisibilities.forceVy && this.helper.columnVisibilities.forceN;
    }

    public get columnForcesWithForceXY() {
        return this.showForceX && this.helper.columnVisibilities.forceVy && !this.helper.columnVisibilities.forceN;
    }

    public get columnForcesWithForceXYZ() {
        return this.showForceX && this.helper.columnVisibilities.forceVy && this.helper.columnVisibilities.forceN;
    }

    public get columnForcesWithForceYZ() {
        return !this.showForceX && this.helper.columnVisibilities.forceVy && this.helper.columnVisibilities.forceN;
    }

    public get showForceX() {
        return !this.helper.areZonesVisible && this.isNotOverlayHNAOrIsShearForce && !this.helper.isPerBarPir && !this.helper.isPropertyHidden(PropertyMetaDataC2C.Loads_C2C_ForceX.id);
    }

    public get columnForcesDefaultFlex() {
        if (this.columnForcesWithForceXYZ) {
            return 150;
        }

        if (this.columnForcesWithForceXY || this.columnForcesWithForceYZ) {
            return 100;
        }

        if (this.columnForcesWithForceN) {
            return 50;
        }

        return 0;
    }

    public get columnMomentsWithMomentX() {
        return this.showMomentMx && !this.showMomentMy && !this.helper.columnVisibilities.momentMz;
    }

    public get columnMomentsWithMomentXY() {
        return this.showMomentMx && this.showMomentMy && !this.helper.columnVisibilities.momentMz;
    }

    public get columnMomentsWithMomentXYZ() {
        return this.showMomentMx && this.showMomentMy && this.helper.columnVisibilities.momentMz;
    }

    public get columnMomentsDefaultFlex() {
        if (this.columnMomentsWithMomentXYZ) {
            return 150;
        }

        if (this.columnMomentsWithMomentXY) {
            return 100;
        }

        if (this.columnMomentsWithMomentX) {
            return 50;
        }

        return 0;
    }

    public get showDecisiveButton() {
        return this.design.connectionType == ConnectionType.ConcreteOverlay && !this.helper.isSplicesAndPerBar && this.design.decisiveLoadCombinationIdC2C != null
            && this.design.selectedLoadCombinationIdC2C != this.design.decisiveLoadCombinationIdC2C;
    }

    private get loadCombinationUsedInReport() {
        return this.design.reportLoadCombination;
    }

    private get isShearForce() {
        return this.design.designData.projectDesignC2C?.loads.loadingDefinitionType == LoadingDefinitionType.ShearLoad;
    }

    private get isFreeLicenseAndLimitedAnchorcode() {
        const limitedLicenses = this.licenseService.floatingLimitReached || this.licenseService.isFree() || this.licenseService.isOnlyBasic();
        const limitedStandards = [DesignStandard.ACI, DesignStandard.CSA];
        const designStandard = this.design.designStandardC2C?.id ?? DesignStandard.Unknown;
        return limitedLicenses && limitedStandards.includes(designStandard);
    }

    private get showMomentMx() {
        return !this.helper.areZonesVisible && !this.helper.isOverlayHNA && !this.helper.design.isC2COverlay && !this.helper.isPropertyHidden(PropertyMetaDataC2C.Loads_C2C_MomentX.id);
    }

    private get showMomentMy() {
        return !this.helper.areZonesVisible && !this.helper.isOverlayHNA && !this.helper.isPropertyHidden(PropertyMetaDataC2C.Loads_C2C_MomentY.id);
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-trash',
            'sprite-anchor-shock',
            'sprite-anchor-seismic',
            'sprite-anchor-fire-resistant',
            'sprite-lines-expanded',
            'sprite-lines'
        );

        this.onStateChanged = this.onStateChanged.bind(this);
        this.design.onStateChanged(this.onStateChanged);

        this.helper.initialize();

        this.collapsed = this.loads == null;

        // Load calculation mode
        this.loadsCalculationModeRadio = {
            look: RadioLook.Horizontal,
            items: [
                {
                    id: 'calculation-mode-all',
                    value: LoadCombinationsCalculationType.All,
                    text: this.translate('Agito.Hilti.Profis3.Loads.CalculationMode.All')
                },
                {
                    id: 'calculation-mode-single',
                    value: LoadCombinationsCalculationType.Single,
                    text: this.translate('Agito.Hilti.Profis3.Loads.CalculationMode.Single')
                }
            ],
            selectedValue: this.loadsCalculationMode ?? undefined
        };
        this.loadsCalculationMode = this.design.loadsCalculationModeC2C;

        this.onNumberOfZonesChange();

        this.oldDesign = this.cloneDesignForChangeDetection(this.design);
        this.oldShowDynamic = this.helper.showDynamic;

        this.sizeHeader = this.sizeHeader.bind(this);
        requestAnimationFrame(this.sizeHeader);

        this.setMinimumHeight();

        this.onCollapsedChanged();
    }

    ngAfterViewInit() {
        this.scrollLoadsFirstItemId = this.scrollLoadsElement?.items[0]?.Id;
        setTimeout(() => this.setCompact(), 400);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['selectedLoadId'] != null) {
            this.selectedLoadChanged(this.selectedLoad);
        }
    }

    ngOnDestroy(): void {
        if (this.design != null)
            this.design.off(DesignEvent.stateChanged, this.onStateChanged);
        this.destroyed = true;
    }

    public setAllLoads(index: number) {
        switch (index) {
            case 1:
            case 2:
            case 3:
            case 4:
                this.loads.forEach(load => { load.activeLoadType = index; });

                // Run calculation
                this.calculationService.calculateAsync(this.design);
                break;
            default:
                throw new Error('Wrong load type id.');
        }
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public openShowNewLoadWarning() {
        if (this.loads.some(l => l.isWizardGenerated)) {
            this.modal.openConfirmChange({
                id: 'switch-to-custom-load-combinations-warning',
                title: this.translate('Agito.Hilti.Profis3.Warning'),
                message: this.translate('Agito.Hilti.Profis3.Main.SwitchToCustomLoadComabinations.Message'),
                confirmButtonText: this.translate('Agito.Hilti.Profis3.Yes'),
                cancelButtonText: this.translate('Agito.Hilti.Profis3.No'),
                onConfirm: (modal) => {
                    modal.close();
                    this.showNewLoad();
                },
                onCancel: (modal) => {
                    modal.close();
                }
            });
        }
        else {
            this.showNewLoad();
        }
    }

    public importLoads() {
        if (this.loads.some(l => l.isWizardGenerated)) {
            this.modal.openConfirmChange({
                id: 'switch-to-custom-load-combinations-warning',
                title: this.translate('Agito.Hilti.Profis3.Warning'),
                message: this.translate('Agito.Hilti.Profis3.Main.SwitchToCustomLoadComabinations.Message'),
                confirmButtonText: this.translate('Agito.Hilti.Profis3.Yes'),
                cancelButtonText: this.translate('Agito.Hilti.Profis3.No'),
                onConfirm: (modal) => {
                    modal.close();
                    this.modal.openImportLoads();
                },
                onCancel: (modal) => {
                    modal.close();
                }
            });
        }
        else {
            this.modal.openImportLoads();
        }
    }

    public deleteLoads() {
        if (this.helper.haveSingleLoad) {
            return;
        }

        // one load combination is minimum
        const lastLoadCombinationId = this.selectedLoadId != null ? this.selectedLoadId :
            this.loadCombinationUsedInReport != null ? this.loadCombinationUsedInReport : this.loads[0].id;

        this.design.loadCombinationsC2C = this.loads.filter((l) => l.isWizardGenerated || l.id == lastLoadCombinationId);

        // Run calculation
        this.calculationService.calculateAsync(this.design);
    }

    public formatForce(value: number) {
        if (value == null) {
            return null;
        }

        const defaultUnit = this.unit.getDefaultUnit(UnitGroup.Force);
        const internalUnit = this.unit.getInternalUnit(UnitGroup.Force);
        const precision = this.unit.getPrecision(defaultUnit);

        const defaultValue = this.unit.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit, true);

        return this.unit.formatNumber(defaultValue, precision);
    }

    public formatMoment(value: number) {
        if (value == null) {
            return null;
        }

        const defaultUnit = this.unit.getDefaultUnit(UnitGroup.Moment);
        const internalUnit = this.unit.getInternalUnit(UnitGroup.Moment);
        const precision = this.unit.getPrecision(defaultUnit);

        const defaultValue = this.unit.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit, true);

        return this.unit.formatNumber(defaultValue, precision);
    }

    public formatZone(value: number, uiProperty: UIPropertyC2C) {
        if (value == null) {
            return null;
        }

        const defaultUnit = this.isShearForce
            ? this.unit.getDefaultUnit(UnitGroup.Force)
            : this.unit.getDefaultUnit(UnitGroup.Stress);

        const internalUnit = this.isShearForce
            ? this.unit.getInternalUnit(UnitGroup.Force)
            : this.unit.getInternalUnit(UnitGroup.Stress);

        const precision = this.unit.getPrecision(defaultUnit, uiProperty);

        const defaultValue = this.unit.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit, true);

        return this.unit.formatNumber(defaultValue, precision);
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize() {
        // reset column widths on window resize
        this.helper.resizerColumnWidth = null;
        this.scrollLoadsElement?.refresh();
        this.setCompact();
    }

    public get loadsContainer() {
        return this.elementRef.nativeElement.shadowRoot?.querySelector('.loads-container') as HTMLElement;
    }

    public get resizerWrapper() {
        return this.elementRef.nativeElement.shadowRoot?.querySelector<HTMLElement>('.resizer-wrapper') as HTMLElement;
    }

    public onResize() {
        this.tableHeight = this.loadsContainer.offsetHeight;
        this.scrollLoadsElement.refresh();
        this.resize3d(true);
    }

    public onCollapsedChanged() {
        if (!this.collapsed) {
            // trigger rows load
            this.rowsLoaded = true;
        }

        this.adjustGridHeight();
        this.setMinimumHeight();
        if (this.resize3d != null) {
            this.resize3d();
        }
        this.setCompact();
    }

    private adjustGridHeight() {
        if (this.collapsed) {
            this.removeGridHeight();
        }
        else {
            this.setGridHeight();
        }
    }

    public selectLoad(loadId: string) {
        if (this.selectedLoadId != loadId) {
            this.selectedLoadId = loadId;

            this.selectedLoadChanged(this.selectedLoad);
        }
    }

    public changeLoad() {
        // Run calculation
        this.calculationService.calculateAsync(this.design);
    }

    public loadsCalculationModeRadioSelectedValueChange(selectedValue: LoadCombinationsCalculationType) {
        if (this.loadsCalculationMode != selectedValue) {
            this.loadsCalculationMode = selectedValue;

            this.submitted = true;

            this.calculationService.calculateAsync(this.design,
                (design) => {
                    design.loadsCalculationModeC2C = this.loadsCalculationMode;
                }
            )
                .finally(() => {
                    this.submitted = false;
                });

            this.loadsCalculationModeChanged();
        }
    }

    public addNewLoad(newLoad: LoadCombinationC2C) {
        const loads = this.loads || [];

        const load = cloneDeep(newLoad);
        load.id = this.guid.new();

        if (this.helper.isLoadTypeZoneFatigue) {
            load.activeLoadType = LoadType.Fatigue;
        }

        loads.push(load);
        this.design.loadCombinationsC2C = loads;

        this.helper.addingNewLoad = false;

        if (this.loads.length > 0) {
            this.selectedLoadId = load.id;
        }

        this.loadsContentScrollBottom();

        // Run calculation
        this.calculationService.calculateAsync(this.design);

        this.loadsContentScrollBottom();
    }

    public deleteLoad(load: LoadCombinationC2C) {
        if (this.helper.haveSingleLoad) {
            return;
        }

        this.design.loadCombinationsC2C = this.loads.filter((l) => l !== load || l.id == this.selectedLoadId);

        // Run calculation
        this.calculationService.calculateAsync(this.design);
    }

    public columnsResized() {
        this.setCompact();
    }

    public virtualScrollChange(event: Event) {
        let eventItemId: string | undefined = undefined;
        if (event != null) {
            const event2 = event as any;
            if (Array.isArray(event2) && event2.length > 0) {
                eventItemId = event2[0]?.Id;
            }
        }

        if (event == null || eventItemId != this.scrollLoadsFirstItemId) {
            // If input element had focus we need to blur it on virtual scroll change
            // (viewport end/start index changed), but not if no actual scrolling happened
            const activeElement = document.activeElement as HTMLElement;
            if (activeElement != null && activeElement.tagName.toLowerCase() === 'input') {
                const loadRowsElement = this.elementRef.nativeElement.shadowRoot?.querySelector<HTMLElement>('.loads-rows') as HTMLElement;
                if (loadRowsElement != null && loadRowsElement.contains(activeElement)) {
                    activeElement.blur();
                }
            }
        }

        this.scrollLoadsFirstItemId = eventItemId;
    }

    public identifyLoad: TrackByFunction<LoadCombinationC2C> = (index: number) => index;

    public updateToDecisiveButton() {
        this.selectLoad(this.design.decisiveLoadCombinationIdC2C);
    }

    private onShowDynamicChanged() {
        this.setMinimumHeight();
        this.resize3d();
    }

    private onAnchorTheoryDisplayed() {
        this.setMinimumHeight();
        this.resize3d();
    }

    private loadsCalculationModeChanged() {
        if (!this.selectedLoadId) {
            this.selectedLoadId = this.helper.isEuropeanDesignStandardOverlay
                ? this.design.decisiveLoadCombinationIdC2C
                : this.design.selectedLoadCombinationIdC2C;
            return;
        }

        if (this.loadsCalculationMode == LoadCombinationsCalculationType.All) {
            // Update model
            this.selectedLoadId = this.design.selectedLoadCombinationIdC2C;
        }
        else if (this.loadsCalculationMode == LoadCombinationsCalculationType.Single) {
            if (this.selectedLoadId != this.design.selectedLoadCombinationIdC2C) {
                // Update model
                this.selectedLoadId = this.design.selectedLoadCombinationIdC2C;
            }
        }
        else {
            throw new Error('Unknown calculation mode');
        }
    }

    private selectedLoadChanged(load: LoadCombinationC2C | null) {
        if (this.selectedLoadId != this.design.selectedLoadCombinationIdC2C && load != null) {
            // Update model and run calculation
            this.calculationService
                .calculateAsync(this.design,
                    (design) => {
                        design.selectedLoadCombinationIdC2C = load.id;
                    }
                );
        }
    }

    private onStateChanged() {
        // FIX MODULARIZATION: remove NgZone wrapper when design will be removed from pe-ui
        const onStateChanged = () => {
            let designChanged = false;
            const newDesign = this.design;

            let propId = PropertyMetaDataC2C.Overlay_C2C_Zones_NumberOfZones.id;
            if (this.oldDesign.model[propId] != newDesign.model[propId]) {
                this.onNumberOfZonesChange();
                designChanged = true;
            }

            propId = PropertyMetaDataC2C.Loads_C2C_ShowLoadInputs.id;
            if (this.oldDesign.model[propId] != newDesign.model[propId]) {
                this.onShowLoadInput();
                designChanged = true;
            }

            propId = this.design.isC2COverlay ? PropertyMetaDataC2C.AnchorTheory_C2C_IsAnchorTheoryDisplayed.id : PropertyMetaDataC2C.Loads_C2C_IsAnchorTheoryDisplayed.id;
            if (this.oldDesign.model[propId] != newDesign.model[propId]) {
                this.onAnchorTheoryDisplayed();
                designChanged = true;
            }

            const selectedLoadCombinationId = this.design.selectedLoadCombinationIdC2C;
            if (this.selectedLoadId != selectedLoadCombinationId) {
                this.selectedLoadId = selectedLoadCombinationId;
                designChanged = true;
            }

            const loadsCalculationMode = this.design.loadsCalculationModeC2C;
            if (this.loadsCalculationMode != loadsCalculationMode) {
                this.loadsCalculationMode = loadsCalculationMode;
                designChanged = true;
            }

            propId = PropertyMetaDataC2C.Loads_C2C_LoadDefinitionSection.id;
            if (this.oldDesign.model[propId] != newDesign.model[propId]) {
                designChanged = true;
            }

            propId = PropertyMetaDataC2C.Loads_C2C_InterfaceShear.id;
            if (this.oldDesign.model[propId] != newDesign.model[propId]) {
                designChanged = true;
            }

            propId = PropertyMetaDataC2C.Loads_C2C_LoadCombinationsCalculationType.id;
            const oldLoadCombinationsCalculationType = this.oldDesign.properties.get(propId)?.hidden;
            const newLoadCombinationsCalculationType = newDesign.properties.get(propId)?.hidden;
            if (!isEqual(oldLoadCombinationsCalculationType, newLoadCombinationsCalculationType) && newLoadCombinationsCalculationType != null) {
                if (newDesign.loadsCalculationModeC2C === undefined) {
                    this.loadsCalculationMode = null;
                }
                else {
                    this.loadsCalculationMode = newDesign.loadsCalculationModeC2C;
                }
            }

            propId = PropertyMetaDataC2C.Loads_C2C_LoadCombinations.id;
            if (this.oldDesign.model[propId] != newDesign.model[propId]) {
                this.adjustGridHeight();
                designChanged = true;

                if (this.helper.isPirEuOrAus) {
                    this.helper.resetAllColumnsWidth();
                }

                this.setCompact();
            }

            if (designChanged) {
                this.oldDesign = this.cloneDesignForChangeDetection(this.design);
            }

            const showDynamic = this.helper.showDynamic;
            if (this.oldShowDynamic != showDynamic) {
                this.oldShowDynamic = showDynamic;
                this.onShowDynamicChanged();
            }
        };
        return NgZone.isInAngularZone() ? onStateChanged() : this.ngZone.run(onStateChanged);
    }

    private loadsContentScrollBottom() {
        this.scrollLoadsElement.scrollToIndex(this.loads.length);
    }

    private sizeHeader() {
        if (!this.destroyed) {
            const header = this.elementRef.nativeElement.shadowRoot?.querySelector('.resizer-wrapper .loads-header .header-sizer') as HTMLElement;
            const subHeader = this.elementRef.nativeElement.shadowRoot?.querySelector('.resizer-wrapper .loads-sub-header .header-sizer') as HTMLElement;
            const rows = this.elementRef.nativeElement.shadowRoot?.querySelectorAll('.resizer-wrapper .loads-row-component') as NodeListOf<Element>;

            if (header != null) {
                if (rows.length > 0) {
                    const rowWidth = (rows[0] as HTMLElement).offsetWidth;

                    if (header.style.width != rowWidth + 'px') {
                        header.style.width = rowWidth + 'px';
                    }
                }
                else if (header.style.width != '') {
                    header.style.width = '';
                }
            }

            if (subHeader != null) {
                if (rows.length > 0) {
                    const rowWidth = (rows[0] as HTMLElement).offsetWidth;

                    if (subHeader.style.width != rowWidth + 'px') {
                        subHeader.style.width = rowWidth + 'px';
                    }
                }
                else if (subHeader.style.width != '') {
                    subHeader.style.width = '';
                }
            }

            requestAnimationFrame(this.sizeHeader);
        }
    }

    private setGridHeight(addSpace = 0) {
        let fullHeight = 0;

        if (this.tableHeight == null) { // if grid was not manually expanded, calculate new height
            let rowsHeight = this.loads.length;

            if (this.helper.addingNewLoad) { // Always account for additional row and space when adding new load
                rowsHeight += 1;
                addSpace += 17;
            }

            rowsHeight = rowsHeight * this.calculateRowHeight();

            rowsHeight++;   // First row has 2 borders, only 1 respected in calculateRowHeight()!

            // remove empty space in footer
            if (!this.showAddButton) {
                rowsHeight -= LoadsComponent.loadsRowHeight + LoadsComponent.resizedAndBorderPadding;

                // Add 1px for every load combination (row border)
                if (this.loads.length > 0) {
                    rowsHeight += (this.loads.length - 1);
                }
            }

            const headersHeight = 4 + 32 + 36 + 48; // 4 resizer  32 header  36 subheader  48 footer

            fullHeight = Math.min(rowsHeight + headersHeight + addSpace, DEFAULT_MAX_LOADS_HEIGHT);
        }
        else {
            fullHeight = this.tableHeight;
        }
        this.loadsContainer.style.height = fullHeight + 'px';
    }

    private removeGridHeight() {
        this.loadsContainer.style.height = '';
    }

    private onNumberOfZonesChange() {
        if (this.design.applicationType == ApplicationType.GenericReinforcement) {
            this.helper.numberZones = 1;
        }
        else {
            this.helper.numberZones = this.design.model[PropertyMetaDataC2C.Overlay_C2C_Zones_NumberOfZones.id] as number;
        }
    }

    private onShowLoadInput() {
        this.setMinimumHeight();
        this.resize3d();
    }

    private calculateRowHeight(load?: LoadCombinationC2C) {
        const rowFactor = this.helper.showDynamic || this.helper.isPerBar || this.helper.isExtensionsOrJointsHNA
            ? 2
            : 1;
        let height = rowFactor * (LoadsComponent.loadsRowHeight + 2); // +1 for bottom border that each row has

        // total height is actually loads.length * height + 1; so we add + 1 to the first load
        if (load != null && this.loads.length > 0 && this.loads[0] == load) {
            height++;
        }

        return height;
    }

    private setMinimumHeight() {
        let rowHeight = this.calculateRowHeight();

        if (!this.showAddButton) {
            rowHeight -= LoadsComponent.loadsRowHeight + LoadsComponent.resizedAndBorderPadding;
        }
        const minimumHeight = !this.collapsed ? 122 + rowHeight : 0;

        this.loadsContainer.style.minHeight = minimumHeight + 'px';
    }

    private showNewLoad() {
        this.helper.initializeNewLoad();

        this.helper.addingNewLoad = true;

        this.setGridHeight();
        this.loadsContentScrollBottom();
        this.setMinimumHeight();
        this.resize3d();
    }

    private cloneDesignForChangeDetection(design: Design): IDesignChangeDetectionModel {
        return {
            model: cloneDeep(design.model),
            properties: cloneDeep(design.properties)
        };
    }

    private setCompact() {
        NgZone.assertInAngularZone();

        if (!this.helper.setCompactCollapsed(this.collapsed)) {
            setTimeout(() => {
                this.helper.calculateCompact(this.utilizationColumnRef?.nativeElement);
            }, 100);
        }
    }

    private determineFeatureVisibility(defaultVisibility: boolean): boolean {
        if (this.helper.isPirEuOrAus) {
            return this.design.isFeatureEnabled(FeatureFlagTypes.MultiLoadPirEu);
        } else if (this.helper.isEuropeanDesignStandardOverlay) {
            return this.design.isFeatureEnabled(FeatureFlagTypes.OverlayEUMLC);
        }

        return defaultVisibility;
    }
}
