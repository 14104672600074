import {
    Component, DoCheck, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output,
    ViewEncapsulation
} from '@angular/core';
import { DesignEvent } from '@profis-engineering/pe-ui-common/entities/design';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { DesignC2C as Design } from '../../../shared/entities/design-c2c';
import { ZoneAnalysisInput } from '../../../shared/entities/zone-analysis-input';
import {
    ApplicationType, DesignStandard, LoadingDefinitionType, LoadType, ZoneName
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import { PropertyMetaDataC2C } from '../../../shared/properties/properties';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { UserService } from '../../services/user.service';

interface IAnalysisData {
    height: number;
    heightDynamic: number;
    width: number;
    isNegative: boolean;
    isNegativeDynamic: boolean;
    zoneName: string;
    zone: ZoneName;
}

interface IZoneData {
    width: number;
    stress: number;
    stressDynamic: number;
    zone: ZoneName;
}

interface IDataDisplay extends IAnalysisData {
    x: number;
    y: number;
    yDynamic: number;
    shading: number;
    elementId: string;
    elementIdDynamic: string;
}

interface IDataLabelDisplay {
    x: number;
    y: number;
    label: string;
}

interface IDataValueDisplay {
    x: number;
    y: number;
    yDiagramHeightSum: number;
    value: string;
    valueDynamic: string;
    yValueSum: number;
}

interface IAnalysisDataDisplay {
    display: IDataDisplay[];
    labels: IDataLabelDisplay[];
    stress: IDataValueDisplay[];
}

enum ZoneSymmetry {
    Symmetric,
    Asymmetric
}

const X_AXIS_ASYMMETRIC_WIDTH = 163;
const X_AXIS_SYMMETRIC_WIDTH = 85;
const Y_AXIS_OFFSET = 110;
const X_AXIS_OFFSET = 5;
const Y_NEGATIVE_ALIGNMENT = 10;
const Y_POSITIVE_ALIGNMENT = 2;
const MAX_HEIGHT = 80;
const MIN_ZONE_FACTOR = 0.075;
const SVG_WIDTH = 230;
const SVG_HEIGHT = 200;
const ZONE_LABEL_POSITIVE_Y = 120;
const ZONE_LABEL_NEGATIVE_Y = 106;
const SCREENSHOT_SCALE = 10;
const MIN_DISTANCE_BETWEEN_TEXT = 10;

@Component({
    templateUrl: './zone-analysis.component.html',
    styleUrls: ['./zone-analysis.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ZoneAnalysisComponent implements OnDestroy, DoCheck {

    @Input()
    public collapsed = true;

    @Input()
    public zoneAnalysisInput!: ZoneAnalysisInput;

    @Input()
    public design!: Design;

    @Output()
    public collapsedChange = new EventEmitter<boolean>();

    public analysisData: IAnalysisData[] = [];
    public zoneData: IZoneData[] = [];
    public analysisDataDisplay: IAnalysisDataDisplay;

    public stressUnitName?= '';
    public lengthUnitName?= '';

    public unitGroup!: UnitGroup;
    public loadingDefinitionType!: LoadingDefinitionType;

    public maxGraphValue: IDataValueDisplay | null = null;

    private z1width!: number;
    private z2width!: number;
    private z3width!: number;
    private z4width!: number;

    private isDesignSymmetric!: boolean;

    private numberZones = 0;

    public dataLoaded = false;

    constructor(
        private userService: UserService,
        private localizationService: LocalizationService,
        private unitService: UnitService,
        private elementRef: ElementRef<HTMLElement>,
        private ngZone: NgZone
    ) {
        this.analysisDataDisplay = {
            display: [],
            labels: [],
            stress: [],
        };

        this.onStateChanged = this.onStateChanged.bind(this);
    }

    ngDoCheck(): void {
        if (!this.design)
            this.design = this.userService.design;

        if (this.zoneAnalysisInput == null || this.dataLoaded)
            return;

        this.dataLoaded = true;
        this.zoneAnalysisInput.createScreenshot = () => this.createScreenshot();
        this.design.onStateChanged(this.onStateChanged);
        this.onStateChanged();
    }

    public ngOnDestroy(): void {
        this.design.off(DesignEvent.stateChanged, this.onStateChanged);
    }

    public get isSymmetric(): boolean {
        return this.isDesignSymmetric;
    }

    public get isShearStress(): boolean {
        return this.loadingDefinitionType == LoadingDefinitionType.ShearStress;
    }

    public get isFatigue(): boolean {
        return this.design.model[PropertyMetaDataC2C.Loads_C2C_LoadType.id] == LoadType.Fatigue ? true : false;
    }

    public async createScreenshot(): Promise<string> {
        const zoneDiagram = this.elementRef.nativeElement.shadowRoot?.getElementById('zoneanalysis-diagram') as HTMLElement;

        this.scaleUp(zoneDiagram);
        const svgString = new XMLSerializer().serializeToString(zoneDiagram);
        this.scaleDown(zoneDiagram);

        const canvas = document.createElement('canvas') as HTMLCanvasElement;
        const ctx = canvas.getContext('2d');

        canvas.height = SVG_HEIGHT * SCREENSHOT_SCALE;
        canvas.width = SVG_WIDTH * SCREENSHOT_SCALE;

        const img = new Image();
        const svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
        const url = URL.createObjectURL(svg);

        img.src = url;
        return new Promise<string>((resolve) => {
            img.onload = function () {
                ctx?.drawImage(img, 0, 0);
                const base64 = canvas.toDataURL('image/png', 1.0);
                resolve(base64);
            };
        });
    }

    public toggleZoneTooltip(tooltip: any, zoneValue?: string) {
        if (tooltip.isOpen()) {
            tooltip.close();
        } else {
            tooltip.open({ zoneValue });
        }
    }

    public tooltipPlacement(isNegative: boolean) {
        return isNegative ? 'bottom' : 'top';
    }

    public getZoneAnalysisTooltip(isNegative: boolean, zone: ZoneName) {
        const stressValue = this.parseNumber(this.getStressValue(isNegative, zone));
        const minText = this.isFatigue ? ',min' : '';

        if (this.isShearStress) {
            return `<span class="tauFont">&tau;</span><sub>Ed${minText}</sub> = ${stressValue}`;
        }
        else {
            return `V<sub>Ed${minText}</sub> = ${stressValue}`;
        }
    }

    public getZoneAnalysisDynamicTooltip(zone: ZoneName) {
        const stressValueDynamic = this.getStressValueDynamic(false, zone);

        if (this.isShearStress) {
            return `<span class="tauFont">&tau;</span><sub>Ed,max</sub> = ${stressValueDynamic}`;
        }
        else {
            return `V<sub>Ed,max</sub> = ${stressValueDynamic}`;
        }
    }

    public getTooltip(fromStatic: boolean, data: IDataDisplay) {
        const needSwap = this.isFatigue && data.isNegative && data.isNegativeDynamic;
        if (this.isFatigue && needSwap) {
            return fromStatic ? this.getZoneAnalysisDynamicTooltip(data.zone) :
                this.getZoneAnalysisTooltip(data.isNegative, data.zone);
        }

        return fromStatic ? this.getZoneAnalysisTooltip(data.isNegative, data.zone) :
            this.getZoneAnalysisDynamicTooltip(data.zone);
    }

    public isETAG() {
        return this.design.designStandardC2C?.id == DesignStandard.ETAG;
    }

    public onCollapsedChanged(collapsed: boolean) {
        this.collapsed = collapsed;
        this.collapsedChange.emit(this.collapsed);
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public parseNumber(value: number): string {
        const defaultUnit = this.unitService.getDefaultUnit(this.unitGroup);
        const internalUnit = this.unitService.getInternalUnit(this.unitGroup);
        const defaultPrecision = this.unitService.getPrecision(defaultUnit, PropertyMetaDataC2C.Loads_C2C_ZoneGeneric.id);
        const unitValue = this.unitService.parseUnitValue(value.toString(), this.unitGroup);

        if (unitValue != null && !Number.isNaN(unitValue.value)) {
            value = this.unitService.convertUnitValueToUnit(unitValue, internalUnit).value;
        }

        return this.unitService.formatNumber(this.unitService.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit, true), defaultPrecision);
    }

    public getStressValue(isNegative: boolean, zone: ZoneName): number {
        const data = this.zoneData.find(x => x.zone == zone);
        if (data == null)
            return 0;

        const stressValue = data.stress;
        return (isNegative && stressValue > 0) || (!isNegative && stressValue < 0) ? -stressValue : stressValue;
    }

    public getStressValueDynamic(reduceFromStress: boolean, zone: ZoneName): number {
        const data = this.zoneData.find(x => x.zone == zone);
        if (data == null)
            return 0;

        return reduceFromStress ? data.stressDynamic - data.stress : data.stressDynamic;
    }

    public fillColor(data: IDataDisplay) {
        if (this.isFatigue) {
            return data.isNegative != data.isNegativeDynamic ? { 'fill': '#671C3E' } : { 'fill': '#19af37' };
        }

        return { 'fill': '#19af37' };
    }

    private get propertyLength() {
        switch (this.design.applicationType) {
            case ApplicationType.FloorReinforcement:
                return PropertyMetaDataC2C.Overlay_C2C_Geometry_SlabLength;
            case ApplicationType.WallReinforcement:
                return PropertyMetaDataC2C.Overlay_C2C_Geometry_WallHeight;
            case ApplicationType.BeamReinforcement:
                return PropertyMetaDataC2C.Overlay_C2C_Geometry_BeamLength;
            default:
                return null;
        }
    }

    private get minZoneWidth(): number {
        if (this.propertyLength != null) {
            const slabLength = this.design.model[this.propertyLength.id] as number;
            return this.unitService.convertInternalValueToDefaultValue(MIN_ZONE_FACTOR * slabLength, UnitGroup.Length);
        }

        return 0;
    }

    private calculateHeight(zoneData: IZoneData) {
        if (this.isFatigue && zoneData.stress < 0 && zoneData.stressDynamic < 0) {
            return zoneData.stressDynamic;
        }

        return zoneData.stress;
    }

    private calculateDynamicHeight(zoneData: IZoneData) {
        if (this.isFatigue && zoneData.stress < 0 && zoneData.stressDynamic < 0) {
            return Math.min(zoneData.stress - zoneData.stressDynamic, 0);
        }

        if (this.isFatigue && zoneData.stress < 0 == zoneData.stressDynamic < 0) {
            return Math.max(zoneData.stressDynamic - zoneData.stress, 0);
        }

        return zoneData.stressDynamic;
    }

    private onStateChanged() {
        // FIX MODULARIZATION: remove NgZone wrapper when design will be removed from pe-ui
        const onStateChanged = () => {
            this.loadingDefinitionType = this.design.model[PropertyMetaDataC2C.Loads_C2C_LoadingDefinitionType.id] as LoadingDefinitionType;
            this.unitGroup = (this.loadingDefinitionType == LoadingDefinitionType.ShearLoad)
                ? UnitGroup.Force
                : UnitGroup.Stress;

            this.setParameters();
            this.setZonesData();
            this.setAnalysisData();

            if (this.isDesignSymmetric) {
                this.drawZonesData(ZoneSymmetry.Symmetric);
                this.drawMirrorredZonesData();
                this.drawZonesProperties(ZoneSymmetry.Symmetric);
            }
            else {
                this.drawZonesData(ZoneSymmetry.Asymmetric);
                this.drawZonesProperties(ZoneSymmetry.Asymmetric);
            }

            this.setUnitNames();
        };
        return NgZone.isInAngularZone() ? onStateChanged() : this.ngZone.run(onStateChanged);
    }

    private setParameters() {
        this.isDesignSymmetric = this.design.model[PropertyMetaDataC2C.Overlay_C2C_Zones_Symmetric.id] as boolean;
        this.numberZones = this.design.model[PropertyMetaDataC2C.Overlay_C2C_Zones_NumberOfZones.id] as number;

        if (this.design.model[PropertyMetaDataC2C.Overlay_C2C_Zones_Z1Width.id] != null) {
            this.z1width = this.design.model[PropertyMetaDataC2C.Overlay_C2C_Zones_Z1Width.id] as number;
        }

        if (this.design.model[PropertyMetaDataC2C.Overlay_C2C_Zones_Z2Width.id] != null) {
            this.z2width = this.design.model[PropertyMetaDataC2C.Overlay_C2C_Zones_Z2Width.id] as number;
        }

        if (this.design.model[PropertyMetaDataC2C.Overlay_C2C_Zones_Z3Width.id] != null) {
            this.z3width = this.design.model[PropertyMetaDataC2C.Overlay_C2C_Zones_Z3Width.id] as number;
        }

        if (this.design.model[PropertyMetaDataC2C.Overlay_C2C_Zones_Z4Width.id] != null) {
            this.z4width = this.design.model[PropertyMetaDataC2C.Overlay_C2C_Zones_Z4Width.id] as number;
        }

        this.analysisData = [];
        this.analysisDataDisplay = {
            display: [],
            labels: [],
            stress: [],
        };
    }

    private setUnitNames() {
        const unitForcesGroup = this.unitService.getUnitGroupCodeList(UnitGroup.Force);
        const unitStressGroup = this.unitService.getUnitGroupCodeList(UnitGroup.Stress);
        const unitLengthGroup = this.unitService.getUnitGroupCodeList(UnitGroup.Length);

        this.stressUnitName = (this.loadingDefinitionType == LoadingDefinitionType.ShearLoad)
            ? unitForcesGroup.find((unit) => unit.id == this.design.unitForce)?.name
            : unitStressGroup.find((unit) => unit.id == this.design.unitStress)?.name;

        this.lengthUnitName = unitLengthGroup.find((unit) => unit.id == this.design.unitLength)?.name;
    }

    private setZonesData() {
        const allZones: IZoneData[] = [
            {
                stress: this.unitService.convertInternalValueToDefaultValue(this.design.loadsZone1C2C, this.unitGroup),
                stressDynamic: this.unitService.convertInternalValueToDefaultValue(this.design.loadsDynamicZone1C2C, this.unitGroup),
                width: this.unitService.convertInternalValueToDefaultValue(this.z1width, UnitGroup.Length),
                zone: ZoneName.Z1
            },
            {
                stress: this.unitService.convertInternalValueToDefaultValue(this.design.loadsZone2C2C, this.unitGroup),
                stressDynamic: this.unitService.convertInternalValueToDefaultValue(this.design.loadsDynamicZone2C2C, this.unitGroup),
                width: this.unitService.convertInternalValueToDefaultValue(this.z2width, UnitGroup.Length),
                zone: ZoneName.Z2
            },
            {
                stress: this.unitService.convertInternalValueToDefaultValue(this.design.loadsZone3C2C, this.unitGroup),
                stressDynamic: this.unitService.convertInternalValueToDefaultValue(this.design.loadsDynamicZone3C2C, this.unitGroup),
                width: this.unitService.convertInternalValueToDefaultValue(this.z3width, UnitGroup.Length),
                zone: ZoneName.Z3
            },
            {
                stress: this.unitService.convertInternalValueToDefaultValue(this.design.loadsZone4C2C, this.unitGroup),
                stressDynamic: this.unitService.convertInternalValueToDefaultValue(this.design.loadsDynamicZone4C2C, this.unitGroup),
                width: this.unitService.convertInternalValueToDefaultValue(this.z4width, UnitGroup.Length),
                zone: ZoneName.Z4
            }
        ];

        this.zoneData = allZones.slice(0, this.numberZones);
    }

    private setAnalysisData() {
        this.zoneData.forEach(zoneData => {
            this.analysisData.push({
                height: this.calculateHeight(zoneData),
                heightDynamic: this.calculateDynamicHeight(zoneData),
                width: zoneData.width < this.minZoneWidth ? this.minZoneWidth : zoneData.width,
                isNegative: zoneData.stress < 0,
                isNegativeDynamic: zoneData.stressDynamic < 0,
                zoneName: this.zoneAnalysisInput.getZoneName(zoneData.zone),
                zone: zoneData.zone
            });
        });
    }

    private drawZonesProperties(symmetry: ZoneSymmetry) {
        this.maxGraphValue = null;
        for (let i = 1; i <= this.analysisDataDisplay.display.length; i++) {
            const isNegative = this.analysisDataDisplay.display[i - 1].isNegative;
            const isNegativeDynamic = this.analysisDataDisplay.display[i - 1].isNegativeDynamic;
            const heightDynamic = this.analysisDataDisplay.display[i - 1].heightDynamic;
            const height = this.analysisDataDisplay.display[i - 1].height;
            const lastZone = i == this.analysisDataDisplay.display.length;
            const axisWidth = symmetry == ZoneSymmetry.Asymmetric ? X_AXIS_ASYMMETRIC_WIDTH : X_AXIS_SYMMETRIC_WIDTH * 2;

            let yDiagramHeightSum = 0;
            let yValueSum = 0;

            // stress values
            let calculatedY;

            if (lastZone) {
                calculatedY = isNegative ? (Y_AXIS_OFFSET + height + Y_NEGATIVE_ALIGNMENT) : (Y_AXIS_OFFSET - height - Y_POSITIVE_ALIGNMENT);
            } else {
                calculatedY = isNegative ? (Y_AXIS_OFFSET + height + Y_NEGATIVE_ALIGNMENT) : (Y_AXIS_OFFSET - height - 2);
            }

            const valueDynamic = this.getStressValueDynamic(isNegative == isNegativeDynamic, this.analysisDataDisplay.display[i - 1].zone);
            let value = this.getStressValue(isNegative, this.analysisDataDisplay.display[i - 1].zone);
            let yDiagramMiddleLabelSum = calculatedY;
            let zoneLabelY = isNegative ? ZONE_LABEL_NEGATIVE_Y : ZONE_LABEL_POSITIVE_Y;

            if (this.isFatigue) {
                if (isNegative) {
                    if (isNegativeDynamic) {
                        yDiagramHeightSum = Y_AXIS_OFFSET + heightDynamic + height;
                        yValueSum = value;
                        value = valueDynamic + value;

                        if (yDiagramHeightSum - yDiagramMiddleLabelSum < 0) {
                            yDiagramMiddleLabelSum += yDiagramHeightSum - yDiagramMiddleLabelSum;
                        }

                        if (yDiagramMiddleLabelSum - zoneLabelY < MIN_DISTANCE_BETWEEN_TEXT) {
                            zoneLabelY -= MIN_DISTANCE_BETWEEN_TEXT - yDiagramMiddleLabelSum + zoneLabelY;
                        }
                    }
                    else {
                        yDiagramHeightSum = (Y_AXIS_OFFSET - heightDynamic);
                        yValueSum = valueDynamic;

                        if (Math.abs(ZONE_LABEL_NEGATIVE_Y - yDiagramHeightSum) < MIN_DISTANCE_BETWEEN_TEXT) {
                            yDiagramHeightSum = ZONE_LABEL_NEGATIVE_Y - MIN_DISTANCE_BETWEEN_TEXT;
                        }
                    }
                }
                else {
                    if (isNegativeDynamic) {
                        yDiagramHeightSum = Y_AXIS_OFFSET - height;
                        yValueSum = value;
                        value = valueDynamic;
                        yDiagramMiddleLabelSum = (Y_AXIS_OFFSET + heightDynamic + Y_NEGATIVE_ALIGNMENT);

                        if (Math.abs(ZONE_LABEL_POSITIVE_Y - yDiagramMiddleLabelSum) < MIN_DISTANCE_BETWEEN_TEXT) {
                            yDiagramMiddleLabelSum = ZONE_LABEL_POSITIVE_Y + MIN_DISTANCE_BETWEEN_TEXT;
                        }
                    }
                    else {
                        yDiagramHeightSum = Y_AXIS_OFFSET - heightDynamic - height;
                        yValueSum = value + valueDynamic;

                        if (yDiagramMiddleLabelSum - yDiagramHeightSum < MIN_DISTANCE_BETWEEN_TEXT) {
                            yDiagramMiddleLabelSum -= yDiagramMiddleLabelSum - yDiagramHeightSum - MIN_DISTANCE_BETWEEN_TEXT;
                        }

                        if (zoneLabelY - yDiagramMiddleLabelSum < MIN_DISTANCE_BETWEEN_TEXT) {
                            zoneLabelY -= zoneLabelY - yDiagramMiddleLabelSum - MIN_DISTANCE_BETWEEN_TEXT;
                        }
                    }
                }
            }
            else {
                if (isNegative) {
                    yDiagramHeightSum = Y_AXIS_OFFSET + height;
                }
                else {
                    yDiagramHeightSum = Y_AXIS_OFFSET - height;
                }

                yValueSum = value;
            }

            // Adding alignment to place max value text just above/below the graph column
            if (yDiagramHeightSum > Y_AXIS_OFFSET) {
                yDiagramHeightSum += Y_NEGATIVE_ALIGNMENT;
            }
            else {
                yDiagramHeightSum -= Y_POSITIVE_ALIGNMENT;
            }

            let stressX;
            if (lastZone) {
                stressX = ((axisWidth + this.analysisDataDisplay.display[this.analysisDataDisplay.display.length - 1].x) / 2) - 3;
            } else {
                const midPoint = (this.analysisDataDisplay.display[i].x + this.analysisDataDisplay.display[i - 1].x) / 2;
                stressX = isNegative ? midPoint - 10 : midPoint - 5;
            }

            this.analysisDataDisplay.stress.push({
                value: this.parseNumber(value),
                x: stressX,
                y: this.isNanCheck(yDiagramMiddleLabelSum),
                yDiagramHeightSum: !this.isFatigue ? this.isNanCheck(calculatedY) : this.isNanCheck(yDiagramHeightSum),
                valueDynamic: this.parseNumber(valueDynamic),
                yValueSum: yValueSum
            });

            if (this.maxGraphValue == null || this.maxGraphValue == undefined) {
                this.maxGraphValue = this.analysisDataDisplay.stress[0];
            }
            else {
                if (yValueSum > this.maxGraphValue.yValueSum) {
                    this.maxGraphValue = this.analysisDataDisplay.stress[i - 1];
                }
            }

            // labels
            const labelXpos = lastZone ? ((axisWidth + this.analysisDataDisplay.display[this.analysisDataDisplay.display.length - 1].x) / 2) - 3 :
                ((this.analysisDataDisplay.display[i].x + this.analysisDataDisplay.display[i - 1].x) / 2) - 5;

            this.analysisDataDisplay.labels.push({
                label: this.analysisDataDisplay.display[i - 1].zoneName,
                x: labelXpos,
                y: zoneLabelY
            });
        }
    }

    private drawMirrorredZonesData() {
        let offsetX = X_AXIS_SYMMETRIC_WIDTH + 5;

        const graphData = this.calculateRelativeGraphData(this.analysisData, ZoneSymmetry.Symmetric).reverse();
        graphData.forEach(data => {
            const calculatedY = data.isNegative ? Y_AXIS_OFFSET - data.height : Y_AXIS_OFFSET;
            let calculatedYdynamic = Y_AXIS_OFFSET;

            if (data.isNegative && data.isNegativeDynamic) {
                calculatedYdynamic = Y_AXIS_OFFSET - data.heightDynamic - data.height;
            }
            else if (data.isNegative && !data.isNegativeDynamic) {
                calculatedYdynamic = Y_AXIS_OFFSET;
            }
            else if (!data.isNegative && !data.isNegativeDynamic) {
                calculatedYdynamic = Y_AXIS_OFFSET + data.height;
            }
            else if (!data.isNegative && data.isNegativeDynamic) {
                calculatedYdynamic = Y_AXIS_OFFSET - data.heightDynamic;
            }

            this.analysisDataDisplay.display.push({
                width: data.width,
                height: this.isNanCheck(data.height),
                heightDynamic: this.isNanCheck(data.heightDynamic),
                x: offsetX,
                y: this.isNanCheck(calculatedY),
                yDynamic: this.isNanCheck(calculatedYdynamic),
                shading: this.zoneAnalysisInput.calculateZoneColorFactors(this.numberZones, this.zoneAnalysisInput.getZoneNameByName(data.zoneName)),
                isNegative: !data.isNegative,
                isNegativeDynamic: (data.heightDynamic == 0) ? data.isNegativeDynamic : !data.isNegativeDynamic,
                zoneName: data.zoneName,
                zone: data.zone,
                elementId: this.getZoneElementId(ZoneSymmetry.Symmetric, data.zone),
                elementIdDynamic: this.getZoneElementIdDynamic(ZoneSymmetry.Symmetric, data.zone)
            });

            offsetX += data.width;
        });
    }

    private drawZonesData(symmetry: ZoneSymmetry) {
        let offsetX = X_AXIS_OFFSET;

        const graphData = this.calculateRelativeGraphData(this.analysisData, symmetry);
        graphData.forEach(data => {
            const calculatedY = data.isNegative ? Y_AXIS_OFFSET : Y_AXIS_OFFSET - data.height;
            let calculatedYdynamic = Y_AXIS_OFFSET;

            if (data.isNegative && data.isNegativeDynamic) {
                calculatedYdynamic = Y_AXIS_OFFSET + data.height;
            }
            else if (data.isNegative && !data.isNegativeDynamic) {
                calculatedYdynamic = Y_AXIS_OFFSET - data.heightDynamic;
            }
            else if (!data.isNegative && !data.isNegativeDynamic) {
                calculatedYdynamic = Y_AXIS_OFFSET - (data.heightDynamic + data.height);
            }
            else if (!data.isNegative && data.isNegativeDynamic) {
                calculatedYdynamic = Y_AXIS_OFFSET;
            }

            this.analysisDataDisplay.display.push({
                width: data.width,
                height: this.isNanCheck(data.height),
                heightDynamic: this.isNanCheck(data.heightDynamic),
                x: offsetX,
                y: this.isNanCheck(calculatedY),
                yDynamic: this.isNanCheck(calculatedYdynamic),
                shading: this.zoneAnalysisInput.calculateZoneColorFactors(this.numberZones, this.zoneAnalysisInput.getZoneNameByName(data.zoneName)),
                isNegative: data.isNegative,
                isNegativeDynamic: data.isNegativeDynamic,
                zoneName: data.zoneName,
                zone: data.zone,
                elementId: this.getZoneElementId(ZoneSymmetry.Asymmetric, data.zone),
                elementIdDynamic: this.getZoneElementIdDynamic(ZoneSymmetry.Asymmetric, data.zone)
            });

            offsetX += data.width;
        });
    }

    private isNanCheck(value: number): number {
        return isNaN(value) ? 0 : value;
    }

    private calculateRelativeGraphData(zones: IAnalysisData[], symmetry: ZoneSymmetry) {
        const axisWidth = symmetry == ZoneSymmetry.Asymmetric ? X_AXIS_ASYMMETRIC_WIDTH : X_AXIS_SYMMETRIC_WIDTH;
        const graphData: IAnalysisData[] = [];

        const widthsSum = zones.reduce((sum, zone) => sum + zone.width, 0);
        const maxValue = zones.reduce((sum, zone) => Math.max(sum, zone.isNegative == zone.isNegativeDynamic ?
            Math.abs(zone.height + zone.heightDynamic) : Math.max(Math.abs(zone.height), Math.abs(zone.heightDynamic))), 0);

        zones.forEach(zone => {
            const widthRatio = zone.width / widthsSum;
            const heightRatioDynamic = zone.heightDynamic != 0 ? zone.heightDynamic / maxValue : 0;
            const heightRatio = zone.height != 0 ? zone.height / maxValue : 0;

            graphData.push({
                height: Math.abs(heightRatio * MAX_HEIGHT),
                heightDynamic: Math.abs(heightRatioDynamic * MAX_HEIGHT),
                width: widthRatio * axisWidth,
                isNegative: zone.isNegative,
                isNegativeDynamic: zone.isNegativeDynamic,
                zoneName: zone.zoneName,
                zone: zone.zone
            });
        });

        return graphData;
    }

    private getZoneElementId(symmetry: ZoneSymmetry, zone: ZoneName): string {
        const symmetricIds: Record<ZoneName, string> = {
            [ZoneName.Unknown]: 'unknown',
            [ZoneName.Z1]: 'zones-analysis-z1s',
            [ZoneName.Z1Symmetric]: 'zones-analysis-z1s',
            [ZoneName.Z2]: 'zones-analysis-z2s',
            [ZoneName.Z2Symmetric]: 'zones-analysis-z2s',
            [ZoneName.Z3]: 'zones-analysis-z3s',
            [ZoneName.Z3Symmetric]: 'zones-analysis-z3s',
            [ZoneName.Z4]: 'zones-analysis-z4s',
            [ZoneName.Z4Symmetric]: 'zones-analysis-z4s'
        };

        const asymmetricIds: Record<ZoneName, string> = {
            [ZoneName.Unknown]: 'unknown',
            [ZoneName.Z1]: 'zones-analysis-z1',
            [ZoneName.Z1Symmetric]: 'zones-analysis-z1',
            [ZoneName.Z2]: 'zones-analysis-z2',
            [ZoneName.Z2Symmetric]: 'zones-analysis-z2',
            [ZoneName.Z3]: 'zones-analysis-z3',
            [ZoneName.Z3Symmetric]: 'zones-analysis-z3',
            [ZoneName.Z4]: 'zones-analysis-z4',
            [ZoneName.Z4Symmetric]: 'zones-analysis-z4'
        };

        if (symmetry == ZoneSymmetry.Asymmetric) {
            return asymmetricIds[zone];
        }

        return symmetricIds[zone];
    }

    private getZoneElementIdDynamic(symmetry: ZoneSymmetry, zone: ZoneName): string {
        const symmetricIds: Record<ZoneName, string> = {
            [ZoneName.Unknown]: 'unknown',
            [ZoneName.Z1]: 'zones-analysis-dynamic-z1s',
            [ZoneName.Z1Symmetric]: 'zones-analysis-dynamic-z1s',
            [ZoneName.Z2]: 'zones-analysis-dynamic-z2s',
            [ZoneName.Z2Symmetric]: 'zones-analysis-dynamic-z2s',
            [ZoneName.Z3]: 'zones-analysis-dynamic-z3s',
            [ZoneName.Z3Symmetric]: 'zones-analysis-dynamic-z3s',
            [ZoneName.Z4]: 'zones-analysis-dynamic-z4s',
            [ZoneName.Z4Symmetric]: 'zones-analysis-dynamic-z4s'
        };

        const asymmetricIds: Record<ZoneName, string> = {
            [ZoneName.Unknown]: 'unknown',
            [ZoneName.Z1]: 'zones-analysis-dynamic-z1',
            [ZoneName.Z1Symmetric]: 'zones-analysis-dynamic-z1',
            [ZoneName.Z2]: 'zones-analysis-dynamic-z2',
            [ZoneName.Z2Symmetric]: 'zones-analysis-dynamic-z2',
            [ZoneName.Z3]: 'zones-analysis-dynamic-z3',
            [ZoneName.Z3Symmetric]: 'zones-analysis-dynamic-z3',
            [ZoneName.Z4]: 'zones-analysis-dynamic-z4',
            [ZoneName.Z4Symmetric]: 'zones-analysis-dynamic-z4'
        };

        if (symmetry == ZoneSymmetry.Asymmetric) {
            return asymmetricIds[zone];
        }

        return symmetricIds[zone];
    }

    private scaleUp(diagram: HTMLElement) {
        diagram.setAttribute('width', (SVG_WIDTH * SCREENSHOT_SCALE).toString());
        diagram.setAttribute('height', (SVG_HEIGHT * SCREENSHOT_SCALE).toString());
        diagram.style.transform = `scale(${SCREENSHOT_SCALE})`;
    }

    private scaleDown(diagram: HTMLElement) {
        diagram.setAttribute('width', SVG_WIDTH.toString());
        diagram.setAttribute('height', SVG_HEIGHT.toString());
        diagram.style.transform = 'scale(1)';
    }
}
