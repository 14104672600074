import additionalOffsetDistanceDevelopmentImage from '../../images/InfoDialog/additional-offset-distance-development.png';
import additionalOffsetDistanceSplicesImage from '../../images/InfoDialog/additional-offset-distance-splices.png';
import castInSplicedReinforcementACI1419Image from '../../images/InfoDialog/cast-in-spliced-reinforcement-1.png';
import castInSplicedReinforcementACI11Image from '../../images/InfoDialog/cast-in-spliced-reinforcement-2.png';
import castInSplicedReinforcementCSAImage from '../../images/InfoDialog/cast-in-spliced-reinforcement-3.png';
import contactSurfaceConditionACI1419Image from '../../images/InfoDialog/coefficients-of-friction-1.png';
import contactSurfaceConditionACI11Image from '../../images/InfoDialog/coefficients-of-friction-2.png';
import contactSurfaceConditionCSAImage from '../../images/InfoDialog/coefficients-of-friction-3.png';
import concreteMaterialSettingsEquation from '../../images/InfoDialog/concrete-material-settings-equation.png';
import concreteMaterialSettingsGraph from '../../images/InfoDialog/concrete-material-settings-graph.png';
import continuousInXImage from '../../images/InfoDialog/continuous-in-x.png';
import continousJointsScInfoPopupImage from '../../images/InfoDialog/continuous-joints-sc-popup.png';
import deltaFtdImageAus from '../../images/InfoDialog/delta-ftd-calculations-image-aus.png';
import deltaFtdImage from '../../images/InfoDialog/delta-ftd-calculations-image.png';
import desigForYieldImage from '../../images/InfoDialog/desigforyield-1.png';
import designMethodSplicesAsImage from '../../images/InfoDialog/design-method-splices-as.png';
import designMethodSplicesImage from '../../images/InfoDialog/design-method-splices.png';
import detailedDefinitionACIImage from '../../images/InfoDialog/detailed-definition-1.png';
import detailedDefinitionCSAImage from '../../images/InfoDialog/detailed-definition-2.png';
import drillingAidImage from '../../images/InfoDialog/drilling-aid.png';
import epoxyCoatedReinforcementACI1419Image from '../../images/InfoDialog/epoxy-coated-reinforcement-1.png';
import epoxyCoatedReinforcementACI11Image from '../../images/InfoDialog/epoxy-coated-reinforcement-2.png';
import epoxyCoatedReinforcementCSAImage from '../../images/InfoDialog/epoxy-coated-reinforcement-3.png';
import fireResistanceDurationBeamsAndColumnsImage from '../../images/InfoDialog/fireResistanceDurationBeamsAndColumns.png';
import fireResistanceDurationSlabsAndWallsImage from '../../images/InfoDialog/fireResistanceDurationSlabsAndWalls.png';
import reinforcementInCompressionImage from '../../images/InfoDialog/include-reinforcement-in-compression.png';
import locationFactorACI1419Image from '../../images/InfoDialog/location-factor-1.png';
import locationFactorACI11Image from '../../images/InfoDialog/location-factor-2.png';
import locationFactorCSAImage from '../../images/InfoDialog/location-factor-3.png';
import unfavorableToleranceCirImage from '../../images/InfoDialog/most-unfavorable-tolerance-cir.png';
import unfavorableToleranceContactSplicesImage from '../../images/InfoDialog/most-unfavorable-tolerance-contact-splices.png';
import unfavorableToleranceNonContactSplicesImage from '../../images/InfoDialog/most-unfavorable-tolerance-non-contact-splices.png';
import unfavorableTolerancePirImage from '../../images/InfoDialog/most-unfavorable-tolerance-pir.png';
import optimizedValuesImage from '../../images/InfoDialog/optimized-values-1.png';
import ratioBInfoBox from '../../images/InfoDialog/ratioBInfoBox.png';
import rebarEpoxyCoatedACI1419Image from '../../images/InfoDialog/rebar-epoxycoated-1.png';
import rebarEpoxyCoatedACI11Image from '../../images/InfoDialog/rebar-epoxycoated-2.png';
import rebarEpoxyCoatedCSAImage from '../../images/InfoDialog/rebar-epoxycoated-3.png';
import rebarSpacingImage from '../../images/InfoDialog/rebar-spacing.png';
import spliceToExistingReinforcementImage from '../../images/InfoDialog/rebarsplicetoexistingreinforcement-1.png';
import trussFourTiesLeftImage from '../../images/InfoDialog/truss-four-ties-left.png';
import trussFourTiesRightImage from '../../images/InfoDialog/truss-four-ties-right.png';
import trussSixTiesLeftImage from '../../images/InfoDialog/truss-six-ties-left.png';
import trussSixTiesRightImage from '../../images/InfoDialog/truss-six-ties-right.png';
import permanentLoadCSAImage from '../../images/InfoDialog/permanent-load-csa.png';
import { InfoLink } from '../../shared/entities/code-lists/info-link';
import { Command, commandFromService, MainMenuCommandC2C } from '../../shared/entities/command';
import { DesignC2C as Design, ReportDataC2C } from '../../shared/entities/design-c2c';
import { ProjectCodeList } from '../../shared/enums/project-code-list';
import {
    ProjectDesignEntityC2C
} from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import {
    ApplicationType, ConnectionType, DesignMethodGroup, DesignStandard, StrutAndTieModeLayout
} from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';
import {
    DetailedScopeCheckInfoPopup
} from '../../shared/generated-modules/Hilti.PE.ScopeChecks.Common.Enums';
import { isHnaBasedDesignStandard } from '../../shared/services/calculation.common';
import {
    IInfoDialogTable, InfoDialogTable, TableCellContentType
} from '../components/info-dialog-table/info-dialog-table.component';
import {
    IInfoDialogUnorderedList, InfoDialogUnorderedList
} from '../components/info-dialog-unordered-list/info-dialog-unordered-list.component';
import { CodeListService } from '../services/code-list.service';

const infoDialogImages: { [id: string]: string } = {
    ['rebar-epoxycoated-ACI31814']: rebarEpoxyCoatedACI1419Image,
    ['rebar-epoxycoated-ACI31819']: rebarEpoxyCoatedACI1419Image,
    ['rebar-epoxycoated-ACI31811']: rebarEpoxyCoatedACI11Image,
    ['rebar-epoxycoated-CSAA23314']: rebarEpoxyCoatedCSAImage,
    ['rebar-epoxycoated-CSAA23319']: rebarEpoxyCoatedCSAImage,

    ['optimized-values']: optimizedValuesImage,

    ['additional-offset-distance-development']: additionalOffsetDistanceDevelopmentImage,
    ['additional-offset-distance-splices']: additionalOffsetDistanceSplicesImage,

    ['coefficients-of-friction-ACI31814']: contactSurfaceConditionACI1419Image,
    ['coefficients-of-friction-ACI31819']: contactSurfaceConditionACI1419Image,
    ['coefficients-of-friction-ACI31811']: contactSurfaceConditionACI11Image,
    ['coefficients-of-friction-CSAA23314']: contactSurfaceConditionCSAImage,
    ['coefficients-of-friction-CSAA23319']: contactSurfaceConditionCSAImage,

    ['epoxy-coated-reinforcement-ACI31814']: epoxyCoatedReinforcementACI1419Image,
    ['epoxy-coated-reinforcement-ACI31819']: epoxyCoatedReinforcementACI1419Image,
    ['epoxy-coated-reinforcement-ACI31811']: epoxyCoatedReinforcementACI11Image,
    ['epoxy-coated-reinforcement-CSAA23314']: epoxyCoatedReinforcementCSAImage,
    ['epoxy-coated-reinforcement-CSAA23319']: epoxyCoatedReinforcementCSAImage,

    ['location-factor-ACI31814']: locationFactorACI1419Image,
    ['location-factor-ACI31819']: locationFactorACI1419Image,
    ['location-factor-ACI31811']: locationFactorACI11Image,
    ['location-factor-CSAA23314']: locationFactorCSAImage,
    ['location-factor-CSAA23319']: locationFactorCSAImage,

    ['cast-in-spliced-reinforcement-ACI31814']: castInSplicedReinforcementACI1419Image,
    ['cast-in-spliced-reinforcement-ACI31819']: castInSplicedReinforcementACI1419Image,
    ['cast-in-spliced-reinforcement-ACI31811']: castInSplicedReinforcementACI11Image,
    ['cast-in-spliced-reinforcement-CSAA23314']: castInSplicedReinforcementCSAImage,
    ['cast-in-spliced-reinforcement-CSAA23319']: castInSplicedReinforcementCSAImage,

    ['detailed-definition-ACI31814']: detailedDefinitionACIImage,
    ['detailed-definition-ACI31819']: detailedDefinitionACIImage,
    ['detailed-definition-ACI31811']: detailedDefinitionACIImage,
    ['detailed-definition-CSAA23314']: detailedDefinitionCSAImage,
    ['detailed-definition-CSAA23319']: detailedDefinitionCSAImage,

    ['rebarsplicetoexistingreinforcement']: spliceToExistingReinforcementImage,
    ['continuousInX']: continuousInXImage,

    ['desigforyield']: desigForYieldImage,

    ['ratioBInfoBox']: ratioBInfoBox,

    ['continousJointsScInfoPopup']: continousJointsScInfoPopupImage,

    ['fireResistanceDurationSlabsAndWalls']: fireResistanceDurationSlabsAndWallsImage,
    ['fireResistanceDurationBeamsAndColumns']: fireResistanceDurationBeamsAndColumnsImage,

    ['deltaFtdImg']: deltaFtdImage,

    ['drillingAid']: drillingAidImage,

    ['unfavorableToleranceContactSplices']: unfavorableToleranceContactSplicesImage,
    ['unfavorableToleranceNonContactSplices']: unfavorableToleranceNonContactSplicesImage,
    ['unfavorableTolerancePir']: unfavorableTolerancePirImage,
    ['unfavorableToleranceCir']: unfavorableToleranceCirImage,

    ['includeReinforcementInCompression']: reinforcementInCompressionImage,

    ['designMethodSplices']: designMethodSplicesImage,
    ['designMethodSplicesAs']: designMethodSplicesAsImage,

    ['rebarSpacing']: rebarSpacingImage,

    ['truss-four-ties-left']: trussFourTiesLeftImage,
    ['truss-four-ties-right']: trussFourTiesRightImage,
    ['truss-six-ties-left']: trussSixTiesLeftImage,
    ['truss-six-ties-right']: trussSixTiesRightImage,

    ['deltaFtdImgAus']: deltaFtdImageAus,

    ['concrete-material-settings-equation']: concreteMaterialSettingsEquation,
    ['concrete-material-settings-graph']: concreteMaterialSettingsGraph,

    ['permanent-load-csa']: permanentLoadCSAImage
};

export const PREVENT_UPPERCASE_CONSTANTS_CLASS = 'prevent-uppercase-constants';

export type CommandAdditionalData = {
    designMethodGroup: DesignMethodGroup;
    designStandard: DesignStandard;
    projectDesign?: ProjectDesignEntityC2C;
    isSurfaceTreatmentDisabled: boolean;
    reportData?: ReportDataC2C;
    isPirASAS: boolean;
};

export const getItemsForCommand = (design: Design, projectCodeList: CodeListService, forceLinkToOpen: boolean, command?: MainMenuCommandC2C) => {
    let items: IInfoDialogItem[] = [];
    if (design != null) {
        const infoLink = getInfoLink(projectCodeList, design.region.id, design.connectionTypeId, command);
        items = commandMethodDictionary[command ?? '']?.({
            designMethodGroup: design.designMethodGroup?.id ?? DesignMethodGroup.Unknown,
            designStandard: design.designStandardC2C?.id ?? DesignStandard.Unknown,
            projectDesign: design.designData?.projectDesignC2C,
            isSurfaceTreatmentDisabled: design.isSurfaceTreatmentDisabled,
            reportData: design.designData?.reportDataC2C,
            isPirASAS: design.isPirASAS
        }) ?? [];

        if (infoLink != null) {
            addInfoLinkItems(infoLink, items, forceLinkToOpen);
        }
    }
    else {
        items = commandMethodDictionary[command ?? '']?.({} as CommandAdditionalData) ?? [];
    }

    return items;
};

export const getItemsForScopecheckInfoPopup = (command: DetailedScopeCheckInfoPopup) => {
    return getScInfoPopupItems(command);
};

export const enum InfoDialogItemType {
    Text = 1,
    Image = 2,
    Title = 3,
    InfoLinkValue = 4,
    InfoLinkTitle = 5,
    Table = 6,
    SubHeading = 7,
    ListItem = 8,
    LineBreak = 9,
    InfoDirectLink = 10,
    UnorderedList = 11,
    ThematicBreak = 12
}

export type InfoDialogItemValue = string | InfoDialogTable | IcidLinks[] | InfoDialogUnorderedList | IComplexTranslationString;
export interface IInfoDialogItem {
    type: InfoDialogItemType;
    value?: InfoDialogItemValue;
    appendText?: string;
    style?: string;
}

export interface IcidLinks {
    link: string;
    icid: number;
}

export interface IComplexTranslationString {
    translationString: string;
    constants: Record<string, string>;
}

export interface IInfoLinkValue {
    icidLinks: IcidLinks[];
    displayKey: string;
}

const addInfoLinkItems = (infoLink: IInfoLinkValue, items: IInfoDialogItem[], forceLinkToOpen: boolean): void => {
    items.push({
        type: InfoDialogItemType.InfoLinkValue,
        value: infoLink.icidLinks
    }, {
        type: forceLinkToOpen ? InfoDialogItemType.InfoDirectLink : InfoDialogItemType.InfoLinkTitle,
        value: infoLink.displayKey
    });

};

const getScInfoPopupItems = (command: DetailedScopeCheckInfoPopup): IInfoDialogItem[] => {
    if (command == DetailedScopeCheckInfoPopup.TR069ContinuousJoints) {
        return getTR069ContinuousJointsScInfoItems();
    }

    return [];
};

const getTR069ContinuousJointsScInfoItems = () => {
    const image = `continousJointsScInfoPopup`;
    const translationKeyTitle = `Agito.Hilti.C2C.ScInfoPopup.TR069ContinuousJoints.Title`;
    const translationKeyMessage = `Agito.Hilti.C2C.ScInfoPopup.TR069ContinuousJoints.Message`;

    return [
        { type: InfoDialogItemType.Title, value: translationKeyTitle },
        { type: InfoDialogItemType.Text, value: translationKeyMessage },
        { type: InfoDialogItemType.Image, value: infoDialogImages[image] }
    ];
};

const getRatioBInfoItems = () => {
    const translationKey = 'Agito.Hilti.C2C.InfoPopup.LoadingDefinition.Beta.Title';
    const translationKeyBody = 'Agito.Hilti.C2C.Command.RatioBInfo';

    const imageName = `ratioBInfoBox`;

    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}` },
        { type: InfoDialogItemType.Text, value: `${translationKeyBody}` },
        { type: InfoDialogItemType.Image, value: infoDialogImages[imageName] }
    ];
};

export const getInfoLink = (codeList: CodeListService, regionId: number | undefined, connectionTypeId: number | undefined, command?: MainMenuCommandC2C): IInfoLinkValue | null => {
    const infoLinks = codeList.projectCodeListsC2C[ProjectCodeList.InfoLinkC2C] as InfoLink[];
    const infoLinksIcid = infoLinks.find(x => commandFromService(x.commandId as Command) == command && x.regionLinks?.find(y => y.regionId == regionId) != null);

    const filteredInfoLinksByRegion = infoLinksIcid?.regionLinks?.filter(x => x.regionId == regionId);
    let filteredInfoLinksByConnectionType = filteredInfoLinksByRegion?.filter(x => x.connectionTypeId === connectionTypeId);

    if (filteredInfoLinksByConnectionType?.length == 0) {
        filteredInfoLinksByConnectionType = filteredInfoLinksByRegion?.filter(x => x.connectionTypeId === 0);
    }

    return infoLinksIcid ? {
        displayKey: infoLinksIcid?.nameResourceKey ?? '',
        icidLinks: filteredInfoLinksByConnectionType?.map<IcidLinks>(r => ({ link: r.link, icid: r.icid })) ?? []
    } : null;
};

const getPermanentNetCompressionItems = (designMethodGroup: DesignMethodGroup): IInfoDialogItem[] => {
    const getHiltiMethodKey = () => {
        const isCSA = designMethodGroup == DesignMethodGroup.CSAA23314 || designMethodGroup == DesignMethodGroup.CSAA23319;
        return isCSA ? 'Agito.Hilti.C2C.Navigation.TabLoads.RegionNormalStress.CSA.PermanentNetCompression.Hilti.Tooltip.Text' :
            'Agito.Hilti.C2C.Navigation.TabLoads.RegionNormalStress.ACI.PermanentNetCompression.Hilti.Tooltip.Text';
    };

    const translationKey = designMethodGroup == DesignMethodGroup.HiltiRebarDesign ?
        getHiltiMethodKey() : 'Agito.Hilti.C2C.Navigation.TabLoads.RegionNormalStress.ACICSA.PermanentNetCompression.Tooltip.Text';
    return [
        { type: InfoDialogItemType.Title, value: `Agito.Hilti.C2C.Navigation.TabLoads.RegionNormalStress.ACICSA.PermanentNetCompression.Tooltip.Text.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}` }
    ];
};

const getAnchorTheoryDataItems = (designMethodGroup: DesignMethodGroup, designStandard: DesignStandard): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.Tooltip.Text';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.${designMethodGroupName(designMethodGroup, designStandard)}` },
    ];
};

const getIsYieldDesignItems = (designMethodGroup: DesignMethodGroup, designStandard: DesignStandard, isOverlay: boolean): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsYieldDesign.Tooltip.Text';

    if (isHnaBasedDesignStandard(designStandard)) {
        const isACI = designStandard == DesignStandard.ACI;
        const annex = isACI ? 'ACI' : 'CSA';
        const type = isOverlay ? 'ConcreteOverlay' : 'StructuralJoints';

        const translationKeyHna = 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.IsYieldDesign.PopUp';

        const firstUnorderedUnlockedMethodList = new InfoDialogUnorderedList({
            id: 'unlocked-methods',
            translationKeys: [`${translationKeyHna}.PartOne.DevelopmentLenght`]
        } as IInfoDialogUnorderedList);

        let secondUnorderedUnlockedMethodList;
        if (!isOverlay) {
            firstUnorderedUnlockedMethodList.addItem(`${translationKeyHna}.PartOne.LapSpliceLength.StructuralJoints`);

            secondUnorderedUnlockedMethodList = new InfoDialogUnorderedList({
                id: 'unlocked-methods',
                translationKeys: [`${translationKeyHna}.PartTwo.StructuralJoints.AnchoringToConcreteToDesignForExternalLoads`]
            } as IInfoDialogUnorderedList);
        }

        firstUnorderedUnlockedMethodList.addItem(`${translationKeyHna}.PartOne.AnchoringToConcreteToDesignForBarYield`);

        const table: IInfoDialogTable = {
            id: 'is-yield-design-hna-table',
            rows: [
                {
                    isHeader: false,
                    cells: [
                        {
                            contents: [
                                {
                                    value: `${translationKeyHna}.PartOne`,
                                    contentType: TableCellContentType.TranslatableString
                                },
                                {
                                    value: '',
                                    objectValue: firstUnorderedUnlockedMethodList,
                                    contentType: TableCellContentType.UnorderedList
                                },
                                {
                                    value: '',
                                    contentType: (!isOverlay) ? TableCellContentType.LineBreak : TableCellContentType.Constant
                                },
                                {
                                    value: '',
                                    contentType: (!isOverlay) ? TableCellContentType.LineBreak : TableCellContentType.Constant
                                },
                                {
                                    value: `${translationKeyHna}.PartTwo.${type}`,
                                    contentType: TableCellContentType.TranslatableString
                                },
                                {
                                    value: '',
                                    objectValue: secondUnorderedUnlockedMethodList,
                                    contentType: TableCellContentType.UnorderedList
                                }
                            ]
                        },
                        {
                            contents: [
                                {
                                    value: `${translationKeyHna}.Text.PartTwoCell.${annex}`,
                                    contentType: TableCellContentType.TranslatableString
                                }
                            ]
                        }
                    ]
                },
            ]
        };

        return [
            { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
            { type: InfoDialogItemType.Table, value: new InfoDialogTable(table) }
        ];
    }

    const table: IInfoDialogTable = {
        id: `is-yield-design-hna`,
        rows: [
            {
                isHeader: true,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Message.PartOneCell1`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: `${translationKey}.Message.PartOneCell2`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                ]
            },
            {
                isHeader: false,
                cells: [
                    {
                        colspan: 2,
                        contents: [
                            {
                                value: `${translationKey}.Message.PartTwo`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                ]
            }

        ]
    };

    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.${designMethodGroupName(designMethodGroup, designStandard)}` },
        { type: InfoDialogItemType.Table, value: new InfoDialogTable(table) },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartThree` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartFour` },
    ];
};

const getAnchorTheoryItems = (designStandard: DesignStandard): IInfoDialogItem[] => {
    const annex = designStandard == DesignStandard.ACI ? 'ACI' : 'CSA';
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.PopUp.Text';

    const table: IInfoDialogTable = {
        id: 'is-anchor-theory-hna-table',
        rows: [
            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.PartOneCell.${annex}`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: `${translationKey}.PartTwoCell.${annex}`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    }
                ]
            },
        ]
    };

    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Table, value: new InfoDialogTable(table) },
    ];
};

const getRebarSpacing = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Product.PirRebarSpacing.Popup';
    const imageName = 'rebarSpacing';

    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: translationKey },
        { type: InfoDialogItemType.Image, value: infoDialogImages[imageName] }
    ];
};

const getPhiTensionItems = (designMethodGroup: DesignMethodGroup, designStandard: DesignStandard): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.PhiBond.Tooltip.Text';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.${designMethodGroupName(designMethodGroup, designStandard)}` }
    ];
};

const getBondBreakoutItems = (designMethodGroup: DesignMethodGroup, designStandard: DesignStandard): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabAnchorTheory.AnchorTheoryData.PhiBond.Tooltip.Text';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.BondBreakout.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.${designMethodGroupName(designMethodGroup, designStandard)}` }
    ];
};

const getCompressionConfinementItems = (designMethodGroup: DesignMethodGroup, designStandard: DesignStandard): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.CompressionConfinement.Tooltip.Text';
    const isCSA = designMethodGroup == DesignMethodGroup.CSAA23314 || designMethodGroup == DesignMethodGroup.CSAA23319;
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: isCSA ? `${translationKey}.CSA` : `${translationKey}.${designMethodGroupName(designMethodGroup, designStandard)}` }
    ];
};

const getDetailedDefinitionItems = (designMethodGroup: DesignMethodGroup, designStandard: DesignStandard) => {
    const translationKey = 'Agito.Hilti.C2C.TransverseReinforcement.DetailedDefinition.Tooltip.Text';
    const imageName = `detailed-definition-${designMethodGroupName(designMethodGroup, designStandard)}`;
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.${designMethodGroupName(designMethodGroup, designStandard)}` },
        { type: InfoDialogItemType.Image, value: infoDialogImages[imageName] }
    ];
};

const getCastInSplicedReinforcementItems = (designMethodGroup: DesignMethodGroup, designStandard: DesignStandard): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.CastInSplicedReinforcement.Tooltip.Text';
    const table: IInfoDialogTable = {
        id: `cast-in-spliced-reinforcement-table-text`,
        rows: [
            {
                isHeader: false,
                cells: [
                    {
                        style: 'vertical-align:top; text-align:left; border:0; padding-left: 0;',
                        contents: [
                            {
                                value: `Agito.Hilti.Profis3.CastinSplicedReinforcementPopUp.InfoText1`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        style: 'vertical-align:top; text-align:left; border:0; padding-right: 0;',
                        contents: [
                            {
                                value: `Agito.Hilti.Profis3.CastinSplicedReinforcementPopUp.InfoText2`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    }
                ]
            }
        ]
    };
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.${designMethodGroupName(designMethodGroup, designStandard)}` },
        { type: InfoDialogItemType.Table, value: new InfoDialogTable(table) }
    ];
};

const getLocationFactorItems = (designMethodGroup: DesignMethodGroup, designStandard: DesignStandard): IInfoDialogItem[] => {
    const baseTranslationKey = 'Agito.Hilti.C2C.Reinforcement.LocationFactor.Tooltip.Text';
    let translationKeyCoatingFactor = 'Agito.Hilti.C2C.Reinforcement.LocationFactor.Tooltip.LocationFactor.Text';

    const table: IInfoDialogTable = {
        id: 'location-factor',
        rows: []
    };

    switch (designMethodGroup) {
        case DesignMethodGroup.ACI31811:
            {
                translationKeyCoatingFactor += '.ACI31811';
                break;
            }
        case DesignMethodGroup.ACI31814:
        case DesignMethodGroup.ACI31819:
        case DesignMethodGroup.HiltiRebarDesign:
            {
                table.rows = [
                    {
                        isHeader: false,
                        cells: [
                            {
                                rowspan: 2,
                                contents: [
                                    {
                                        contentType: TableCellContentType.TranslatableString,
                                        value: `${baseTranslationKey}.Table.Row2Cell1`,
                                    }
                                ]
                            },
                            {
                                contents: [
                                    {
                                        contentType: TableCellContentType.TranslatableString,
                                        value: `${baseTranslationKey}.Table.Row1Cell2`
                                    }
                                ]
                            },
                            {
                                contents: [
                                    {
                                        contentType: TableCellContentType.Constant,
                                        value: '1.3',
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        isHeader: false,
                        cells: [
                            {
                                contents: [
                                    {
                                        contentType: TableCellContentType.TranslatableString,
                                        value: `${baseTranslationKey}.Table.Row2Cell2`
                                    }
                                ]
                            },
                            {
                                contents: [
                                    {
                                        contentType: TableCellContentType.Constant,
                                        value: '1.0'
                                    }
                                ]
                            },
                        ]
                    },
                ];
                break;
            }
        case DesignMethodGroup.CSAA23314:
        case DesignMethodGroup.CSAA23319:
            {
                translationKeyCoatingFactor += '.CSA';
                break;
            }
        default:
            { break; }
    }

    const result = [{ type: InfoDialogItemType.Title, value: `${baseTranslationKey}.Title` },
    { type: InfoDialogItemType.Text, value: `${baseTranslationKey}.${designMethodGroupName(designMethodGroup, designStandard)}` },
    { type: InfoDialogItemType.Table, value: new InfoDialogTable(table) }];

    if (translationKeyCoatingFactor != 'Agito.Hilti.C2C.Reinforcement.LocationFactor.Tooltip.LocationFactor.Text') {
        result.push({ type: InfoDialogItemType.Text, value: translationKeyCoatingFactor });
    }

    return result;
};

const getContactSurfaceConditionItems = (designMethodGroup: DesignMethodGroup, designStandard: DesignStandard): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.TabOverlayInstallation.ContactSurfaceCondition.Tooltip.Text';

    const table: IInfoDialogTable = {
        id: 'min-area-reinforcement-table',
        rows: []
    };

    if (designMethodGroup == DesignMethodGroup.ACI31814 || designMethodGroup == DesignMethodGroup.ACI31819) {
        table.rows = [
            {
                isHeader: true,
                style: 'background-color: white !important;',
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Row0Cell1`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Row0Cell2`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '',
                                contentType: TableCellContentType.Constant
                            }
                        ]
                    },
                ]
            },

            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Row1Cell1`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '<b>1.4</b>λ',
                                contentType: TableCellContentType.Constant
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '(a)',
                                contentType: TableCellContentType.Constant
                            }
                        ]
                    },
                ]
            },

            {
                isHeader: false,
                style: 'background-color: white !important;',
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Row2Cell1`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '<b>1.0</b>λ',
                                contentType: TableCellContentType.Constant
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '(b)',
                                contentType: TableCellContentType.Constant
                            }
                        ]
                    },
                ]
            },

            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Row3Cell1`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '<b>0.6</b>λ',
                                contentType: TableCellContentType.Constant
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '(c)',
                                contentType: TableCellContentType.Constant
                            }
                        ]
                    },
                ]
            },

            {
                isHeader: false,
                style: 'background-color: white !important;',
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Row4Cell1`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '<b>0.7</b>λ',
                                contentType: TableCellContentType.Constant
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '(d)',
                                contentType: TableCellContentType.Constant
                            }
                        ]
                    },
                ]
            }
        ];
    }

    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.${designMethodGroupName(designMethodGroup, designStandard)}` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.${designMethodGroupName(designMethodGroup, designStandard)}.Table.Title` },
        { type: InfoDialogItemType.Table, value: new InfoDialogTable(table) },
        { type: InfoDialogItemType.Text, value: `${translationKey}.${designMethodGroupName(designMethodGroup, designStandard)}.PartOne` }
    ];
};

const getOptimizedValuesItems = (designMethodGroup: DesignMethodGroup, designStandard: DesignStandard): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabNewConcrete.CalculationData.Optimized.Tooltip.Text';
    return [
        { type: InfoDialogItemType.Title, value: 'Agito.Hilti.C2C.Navigation.TabNewConcrete.CalculationData.Optimized' },
        { type: InfoDialogItemType.Text, value: `${translationKey}.PartOne` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.${designMethodGroupName(designMethodGroup, designStandard)}` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.PartTwo` },
        { type: InfoDialogItemType.Image, value: infoDialogImages['optimized-values'] }
    ];
};

const getUseFullCrossSectiontems = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabNewConcrete.CalculationData.UseFullCrossSection.Tooltip.Text';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Description` },
    ];
};

const getAdditionalOffsetDistanceItems = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabNewConcrete.OffsetDistance.AdditionalOffset.Tooltip.Text';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Description.First` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Description.Second` },
        { type: InfoDialogItemType.SubHeading, value: `${translationKey}.Development` },
        { type: InfoDialogItemType.Image, value: infoDialogImages['additional-offset-distance-development'] },
        { type: InfoDialogItemType.SubHeading, value: `${translationKey}.Splices` },
        { type: InfoDialogItemType.Image, value: infoDialogImages['additional-offset-distance-splices'] },
        { type: InfoDialogItemType.SubHeading, value: `${translationKey}.Legend` },
        { type: InfoDialogItemType.ListItem, value: `${translationKey}.Legend.First` },
        { type: InfoDialogItemType.ListItem, value: `${translationKey}.Legend.Second` },
        { type: InfoDialogItemType.ListItem, value: `${translationKey}.Legend.Third` },
        { type: InfoDialogItemType.ListItem, value: `${translationKey}.Legend.Fourth` }
    ];
};

const getFireResistanceDuration = (designStandard: DesignStandard, applicationType: ApplicationType, fireApplicationType: ApplicationType, isCustomDesign: boolean): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.Profis3.Navigation.TabLoads.RegionFire.ControlFireResistanceDuration.Tooltip';
    let image = 'fireResistanceDurationBeamsAndColumns';

    const appType = designStandard == DesignStandard.ETAG ? applicationType : fireApplicationType;
    if (!isCustomDesign && showVerticalFireResistanceDurationImage(appType))
        image = 'fireResistanceDurationSlabsAndWalls';

    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.PartOne` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.PartTwo` },
        { type: InfoDialogItemType.Image, value: infoDialogImages[image] }
    ];
};

const showVerticalFireResistanceDurationImage = (applicationType: ApplicationType) => {
    const varticalApplications = [
        ApplicationType.SlabToSlab,
        ApplicationType.WallExtension,
        ApplicationType.SlabToWall,
        ApplicationType.WallOnSlab,
        ApplicationType.FloorReinforcement,
        ApplicationType.WallReinforcement,
        ApplicationType.GenericReinforcement,
        ApplicationType.Single
    ];

    return varticalApplications.includes(applicationType);
};

const getDrillingAidItems = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.TabExistingStructure.InstallationConditions.DrillingAid.Tooltip';
    const image = 'drillingAid';

    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.PartOne` },
        { type: InfoDialogItemType.Image, value: infoDialogImages[image] },
        { type: InfoDialogItemType.Text, value: `${translationKey}.PartTwo` },
        { type: InfoDialogItemType.ListItem, value: `${translationKey}.NoDrillAid` },
        { type: InfoDialogItemType.ListItem, value: `${translationKey}.DrillAid` },
        { type: InfoDialogItemType.ListItem, value: `${translationKey}.DrillAidGuaranteed` },
    ];
};

const getSpliceToExistingReinforcementItems = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Reinforcement.SpliceToExistingReinforcement.Tooltip.Text';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Image, value: infoDialogImages['rebarsplicetoexistingreinforcement'] }
    ];
};

const getOverstrengthItems = (connectionType: ConnectionType, designStandard: DesignStandard, isSpecialMomentsFrame: boolean): IInfoDialogItem[] => {
    const translationTitle = 'Agito.Hilti.C2C.Overstrength.Title.Tooltip.Text.Title';
    let translationText = 'Agito.Hilti.C2C.Overstrength.Title.Tooltip.Text';
    if (connectionType == ConnectionType.StructuralJoints && isSpecialMomentsFrame) {
        translationText += designStandard == DesignStandard.ACI ? '.ACI' : '.CSA';
    }

    return [
        { type: InfoDialogItemType.Title, value: `${translationTitle}` },
        { type: InfoDialogItemType.Text, value: `${translationText}` }
    ];
};

const getRebarEpoxyCoatedItems = (designMethodGroup: DesignMethodGroup, designStandard: DesignStandard, reinforcement = false): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Reinforcement.EpoxyCoated.Tooltip.Text';
    let translationKeyCoatingFactor = 'Agito.Hilti.C2C.Reinforcement.EpoxyCoated.Tooltip.CoatingFactor.Text';

    const table: IInfoDialogTable = {
        id: 'rebar-epoxycoated',
        rows: []
    };

    switch (designMethodGroup) {
        case DesignMethodGroup.ACI31811:
            {
                translationKeyCoatingFactor += '.ACI31811';
                break;
            }
        case DesignMethodGroup.ACI31814:
        case DesignMethodGroup.ACI31819:
        case DesignMethodGroup.HiltiRebarDesign:
            {
                table.rows = [
                    {
                        isHeader: false,
                        cells: [
                            {
                                rowspan: 3,
                                contents: [
                                    {
                                        value: `${translationKey}.Table.Row2Cell1`,
                                        contentType: TableCellContentType.TranslatableString
                                    }
                                ]
                            },
                            {
                                contents: [
                                    {
                                        value: `${translationKey}.Table.Row1Cell2`,
                                        contentType: TableCellContentType.TranslatableString
                                    }
                                ]
                            },
                            {
                                contents: [
                                    {
                                        value: '1.5',
                                        contentType: TableCellContentType.Constant
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        isHeader: false,
                        cells: [
                            {
                                contents: [
                                    {
                                        value: `${translationKey}.Table.Row2Cell2`,
                                        contentType: TableCellContentType.TranslatableString
                                    }
                                ]
                            },
                            {
                                contents: [
                                    {
                                        value: '1.2',
                                        contentType: TableCellContentType.Constant
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        isHeader: false,
                        cells: [
                            {
                                contents: [
                                    {
                                        value: `${translationKey}.Table.Row3Cell2`,
                                        contentType: TableCellContentType.TranslatableString
                                    }
                                ]
                            },
                            {
                                contents: [
                                    {
                                        value: '1.0',
                                        contentType: TableCellContentType.Constant
                                    }
                                ]
                            },
                        ]
                    },
                ];
                break;
            }
        case DesignMethodGroup.CSAA23314:
        case DesignMethodGroup.CSAA23319:
            {
                translationKeyCoatingFactor += '.CSA';
                break;
            }
        default:
            { break; }
    }

    const titleKey = reinforcement
        ? 'Agito.Hilti.C2C.Reinforcement.EpoxyCoatedReinforcement.Tooltip.Text'
        : 'Agito.Hilti.C2C.Reinforcement.EpoxyCoated.Tooltip.Text';
    const result = [
        { type: InfoDialogItemType.Title, value: `${titleKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.${designMethodGroupName(designMethodGroup, designStandard)}` },
        { type: InfoDialogItemType.Table, value: new InfoDialogTable(table) }
    ];

    if (translationKeyCoatingFactor != 'Agito.Hilti.C2C.Reinforcement.EpoxyCoated.Tooltip.CoatingFactor.Text') {
        result.push({ type: InfoDialogItemType.Text, value: translationKeyCoatingFactor });
    }

    return result;
};

const designMethodGroupName = (designMethodGroup: DesignMethodGroup, designStandard: DesignStandard): string => {
    switch (designMethodGroup) {
        case DesignMethodGroup.ACI31811:
            return 'ACI31811';
        case DesignMethodGroup.ACI31814:
            return 'ACI31814';
        case DesignMethodGroup.ACI31819:
            return 'ACI31819';
        case DesignMethodGroup.CSAA23314:
            return 'CSAA23314';
        case DesignMethodGroup.CSAA23319:
            return 'CSAA23319';
        case DesignMethodGroup.HiltiRebarDesign:
            return designStandard == DesignStandard.ACI ? 'ACI31819' : 'CSAA23319';
        default:
            return '';
    }
};

const getConcreteDensityItems = (designMethodGroup: DesignMethodGroup, designStandard: DesignStandard): IInfoDialogItem[] => {

    // Dictionaries which map the received design method values (key) with the desired popup texts (value).
    const designMethodDict = { CSAA23319: 'CSA A23.3-19', CSAA23314: 'CSA A23.3-14' };
    const codeReferenceDict = { CSAA23319: 'Section 8.6.5', CSAA23314: 'Section 8.6.5' };

    // If we're using the Hilti method, then we set the information to CSA-233-19.
    const designMethodString = designMethodGroup == DesignMethodGroup.HiltiRebarDesign ? 'CSAA23319' : designMethodGroupName(designMethodGroup, designStandard);
    const translationKey = 'Agito.Hilti.C2C.BaseMaterial.ConcreteDensity.Popup';

    return [
        // Create text based on the translation, design method and its code reference. The translation key goes into 'value', the raw text goes into 'appendText'.
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Description`, appendText: `\u00A0${designMethodDict[designMethodString as keyof object]} ${codeReferenceDict[designMethodString as keyof object]}.` }
    ];
};

const getTensileStrengthItems = (designMethodGroup: DesignMethodGroup, designStandard: DesignStandard): IInfoDialogItem[] => {

    // Dictionaries which map the received design method values (key) with the desired popup texts (value).
    const designMethodDict = { ACI31819: 'ACI 318-19', ACI31814: 'ACI 318-14', ACI31811: 'ACI 318-11' };
    const codeReferenceDict = { ACI31819: 'Table 19.2.4.1(b)', ACI31814: 'Table 19.2.4.2', ACI31811: 'Section 8.6.1' };

    // If we're using the Hilti method, then we set the information to ACI-318-19.
    const designMethodString = designMethodGroup == DesignMethodGroup.HiltiRebarDesign ? 'ACI31819' : designMethodGroupName(designMethodGroup, designStandard);
    const translationKey = 'Agito.Hilti.C2C.BaseMaterial.LightweightConcreteFactor.Popup';

    return [
        // Create text based on the translation, design method and its code reference. The translation key goes into 'value', the raw text goes into 'appendText'.
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Description`, appendText: `\u00A0${designMethodDict[designMethodString as keyof object]} ${codeReferenceDict[designMethodString as keyof object]}.` }
    ];
};

const getExtensionAtSupportItems = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabLoads.ExtensionAtSupportPopup';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartOne` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartTwo` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartThree` }
    ];
};

const getContinuousInXDirectionItems = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabApplication.ContinuousInXPopup';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartOne` },
        { type: InfoDialogItemType.Image, value: infoDialogImages['continuousInX'] },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartTwo` }
    ];
};

const getCrackedConcreteItems = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabConcrete.CrackedConcretePopup';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartOne` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartTwo` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartThree` },
    ];
};

const getDesignForYieldItems = (isPirASAS: boolean): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabLoads.DesignForYieldPopup';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartOne` },
        { type: InfoDialogItemType.Text, value: `${translationKey}${isPirASAS ? '.AS' : ''}.Message.PartTwo` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartThree` }
    ];
};

const getShearDesignOptionDeltaFs = (isPirASAS: boolean): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabLoads.ShearDesignOption.DeltaFs';
    const image = isPirASAS ? 'deltaFtdImgAus' : 'deltaFtdImg';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartOne` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartTwo` },
        { type: InfoDialogItemType.Image, value: infoDialogImages[image] },
        { type: InfoDialogItemType.Text, value: `${translationKey}${isPirASAS ? '.AS' : '' }.Message.PartThree` }
    ];
};

const getSurfaceReinforcementInfo = (isPirASAS: boolean): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Overlay.Reinforcement.ConcreteReinforcement';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Tooltip.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}${isPirASAS ? '.AS' : ''}.Message.PartOne` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartTwo` },
        { type: InfoDialogItemType.LineBreak },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartThree` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartFour` },
        { type: InfoDialogItemType.LineBreak },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartFive` }
    ];
};

const getUnfavorableTolerance = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.TransverseReinforcement.UnfavorableTolerance';
    const table: IInfoDialogTable = {
        id: 'unfavorable-tolerance-tooltip-table',
        rows: [
            {
                isHeader: true,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Header.Contact`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Header.NonContact`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    }
                ]
            },
            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: infoDialogImages['unfavorableToleranceContactSplices'],
                                contentType: TableCellContentType.Image
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: infoDialogImages['unfavorableToleranceNonContactSplices'],
                                contentType: TableCellContentType.Image
                            }
                        ]
                    },
                ]
            },
            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.Contact`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.NonContact`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                ]
            },
            {
                isHeader: false,
                cells: [
                    {

                        style: 'text-align: right;',
                        contents: [
                            {
                                value: infoDialogImages['unfavorableTolerancePir'],
                                contentType: TableCellContentType.Image,
                            }
                        ]
                    },
                    {
                        style: 'text-align: left;',
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.Pir`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                ]
            },
            {
                isHeader: false,
                cells: [
                    {
                        style: 'text-align: right;',
                        contents: [
                            {
                                value: infoDialogImages['unfavorableToleranceCir'],
                                contentType: TableCellContentType.Image
                            }
                        ]
                    },
                    {
                        style: 'text-align: left;',
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.Cir`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                ]
            }
        ]
    };

    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartOne` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartTwo` },
        { type: InfoDialogItemType.Table, value: new InfoDialogTable(table) }

    ];
};

const getReinforcementCompression = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Product.Reinforcement.IncludeReinforcementCompression';
    const image = 'includeReinforcementInCompression';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Description` },
        { type: InfoDialogItemType.Image, value: infoDialogImages[image] }
    ];
};

const getDesignMethodGroupsSelectionItems = (connectionType: ConnectionType, isPirASAS: boolean): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabLoads.RegionDesign.DesignMethodSelection.Tooltip';

    if (connectionType == ConnectionType.Splices) {
        return [
            { type: InfoDialogItemType.Title, value: `${translationKey}.Splices.Title` },
            { type: InfoDialogItemType.Text, value: `${translationKey}.Splices.Description.PartOne` },
            { type: InfoDialogItemType.Text, value: `${translationKey}.Splices.Description.PartTwo` },
            { type: InfoDialogItemType.Image, value: infoDialogImages[isPirASAS ? 'designMethodSplicesAs' : 'designMethodSplices'] }
        ];
    }
    else {
        const table: IInfoDialogTable = {
            id: 'design-method-groups-tooltip-table',
            rows: [
                {
                    isHeader: true,
                    cells: [
                        {
                            contents: [
                                {
                                    value: `${translationKey}.Joints.Table.Header.DesignMethod`,
                                    contentType: TableCellContentType.TranslatableString
                                }
                            ]
                        },
                        {
                            contents: [
                                {
                                    value: `${translationKey}.Joints.Table.Header.LoadSituation`,
                                    contentType: TableCellContentType.TranslatableString
                                }
                            ]
                        },
                        {
                            contents: [
                                {
                                    value: `${translationKey}.Joints.Table.Header.Verification`,
                                    contentType: TableCellContentType.TranslatableString
                                }
                            ]
                        }
                    ]
                },
                {
                    isHeader: false,
                    cells: [
                        {
                            contents: [
                                {
                                    value: `${translationKey}${isPirASAS ? '.Joints.Table.Data.AS5216-AS3600' : '.Joints.Table.Data.EN1992-1-1-Anchorage'}`,
                                    contentType: TableCellContentType.TranslatableString
                                }
                            ]
                        },
                        {
                            contents: [
                                {
                                    value: `${translationKey}.Joints.Table.Data.EN1992-1-1-Anchorage.LoadSituation`,
                                    contentType: TableCellContentType.TranslatableString
                                }
                            ]
                        },
                        {
                            contents: [
                                {
                                    value: `${translationKey}${isPirASAS ? '.Joints.Table.Data.AS5216-AS3600.Verification' : '.Joints.Table.Data.EN1992-1-1-Anchorage.Verification'}`,
                                    contentType: TableCellContentType.TranslatableString
                                }
                            ]
                        }
                    ]
                },
                {
                    isHeader: false,
                    isHidden: isPirASAS,
                    cells: [
                        {
                            contents: [
                                {
                                    value: `${translationKey}.Joints.Table.Data.EN1992-1-1-StrutAndTie`,
                                    contentType: TableCellContentType.TranslatableString
                                }
                            ]
                        },
                        {
                            contents: [
                                {
                                    value: `${translationKey}.Joints.Table.Data.EN1992-1-1-StrutAndTie.LoadSituation`,
                                    contentType: TableCellContentType.TranslatableString
                                }
                            ]
                        },
                        {
                            contents: [
                                {
                                    value: `${translationKey}.Joints.Table.Data.EN1992-1-1-StrutAndTie.Verification`,
                                    contentType: TableCellContentType.TranslatableString
                                }
                            ]
                        }
                    ]
                },
                {
                    isHeader: false,
                    cells: [
                        {
                            contents: [
                                {
                                    value: `${translationKey}${isPirASAS ? '.Joints.Table.Data.AEFACTN08' : '.Joints.Table.Data.TR069'}`,
                                    contentType: TableCellContentType.TranslatableString
                                }
                            ]
                        },
                        {
                            contents: [
                                {
                                    value: `${translationKey}.Joints.Table.Data.TR069.LoadSituation`,
                                    contentType: TableCellContentType.TranslatableString
                                }
                            ]
                        },
                        {
                            contents: [
                                {
                                    value: `${translationKey}.Joints.Table.Data.TR069.Verification`,
                                    contentType: TableCellContentType.TranslatableString
                                }
                            ]
                        }
                    ]
                },
                {
                    isHeader: false,
                    isHidden: isPirASAS,
                    cells: [
                        {
                            contents: [
                                {
                                    value: `${translationKey}.Joints.Table.Data.HiltiMethodAnchorage`,
                                    contentType: TableCellContentType.TranslatableString
                                }
                            ]
                        },
                        {
                            contents: [
                                {
                                    value: `${translationKey}.Joints.Table.Data.HiltiMethodAnchorage.LoadSituation`,
                                    contentType: TableCellContentType.TranslatableString
                                }
                            ]
                        },
                        {
                            contents: [
                                {
                                    value: `${translationKey}.Joints.Table.Data.HiltiMethodAnchorage.Verification`,
                                    contentType: TableCellContentType.TranslatableString
                                }
                            ]
                        }
                    ]
                },
                {
                    isHeader: false,
                    isHidden: isPirASAS,
                    cells: [
                        {
                            contents: [
                                {
                                    value: `${translationKey}.Joints.Table.Data.HiltiMethodStrutAndTie`,
                                    contentType: TableCellContentType.TranslatableString
                                }
                            ]
                        },
                        {
                            contents: [
                                {
                                    value: `${translationKey}.Joints.Table.Data.HiltiMethodStrutAndTie.LoadSituation`,
                                    contentType: TableCellContentType.TranslatableString
                                }
                            ]
                        },
                        {
                            contents: [
                                {
                                    value: `${translationKey}.Joints.Table.Data.HiltiMethodStrutAndTie.Verification`,
                                    contentType: TableCellContentType.TranslatableString
                                }
                            ]
                        }
                    ]
                },
            ]
        };

        return [
            { type: InfoDialogItemType.Title, value: `${translationKey}.Joints.Title` },
            { type: InfoDialogItemType.Text, value: `${translationKey}.Joints.Description` },
            { type: InfoDialogItemType.Table, value: new InfoDialogTable(table) }
        ];
    }
};

const getDesignMethodGroupsOverlayItems = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabLoads.RegionDesign.DesignMethodSelection.Tooltip';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Overlay.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Overlay.Description` }
    ];
};

const getSustainability = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabLoads.SustainabilityPopup';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message` }
    ];
};

const getMaxAreaReinforcementItems = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabLoads.MaxAreaReinforcementPopup';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartOne` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartTwo` }
    ];
};

const getMinAreaReinforcementItems = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabLoads.MinAreaReinforcementPopup';
    const table: IInfoDialogTable = {
        id: 'min-area-reinforcement-table',
        rows: [
            {
                isHeader: true,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Header.MinReinforcement`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Header.ForGeneralLc`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Header.Seismic`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    }
                ]
            },
            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.Beam`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '$$A_{s,min} = 0,26\\frac{f_{ctm}}{f_{yk}}bt\\cdot d,$$',
                                contentType: TableCellContentType.LaTex
                            },
                            {
                                value: `${translationKey}.Table.Data.NotLessThan`,
                                contentType: TableCellContentType.TranslatableString
                            },
                            {
                                value: '0,0013btd.',
                                contentType: TableCellContentType.Constant
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '$$\\rho_{min} = 0,5\\cdot\\frac{f_{ctm}}{f_{yk}}$$',
                                contentType: TableCellContentType.LaTex
                            }
                        ]
                    }
                ]
            },
            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.Slab`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '$$A_{s,min} = 0,26\\frac{f_{ctm}}{f_{yk}}bt\\cdot d,$$',
                                contentType: TableCellContentType.LaTex
                            },
                            {
                                value: `${translationKey}.Table.Data.NotLessThan`,
                                contentType: TableCellContentType.TranslatableString
                            },
                            {
                                value: '0,0013btd',
                                contentType: TableCellContentType.Constant
                            },
                            {
                                value: `${translationKey}.Table.Data.MaximumSpacingIs`,
                                contentType: TableCellContentType.TranslatableString
                            },
                            {
                                value: '$$S_{max, slab} = min\\{3h; 400mm\\}.$$',
                                contentType: TableCellContentType.LaTex
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '$$A_{s,min} = 0,26\\frac{f_{ctm}}{f_{yk}}bt\\cdot d,$$',
                                contentType: TableCellContentType.LaTex
                            },
                            {
                                value: `${translationKey}.Table.Data.NotLessThan`,
                                contentType: TableCellContentType.TranslatableString
                            },
                            {
                                value: '0,0013btd',
                                contentType: TableCellContentType.Constant
                            },
                            {
                                value: `${translationKey}.Table.Data.MaximumSpacingIs`,
                                contentType: TableCellContentType.TranslatableString
                            },
                            {
                                value: '$$S_{max, slab} = min\\{3h; 400mm\\}.$$',
                                contentType: TableCellContentType.LaTex
                            }
                        ]
                    }
                ]
            },
            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.Column`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '$$A_{s,min} = max \\left\\{ \\frac{0,1\\cdot N_{Ed}}{f_{yd}};0,002\\cdot A_{c} \\right\\},$$',
                                contentType: TableCellContentType.LaTex
                            },
                            {
                                value: `${translationKey}.Table.Data.Column.LeastBars`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '$$A_{s,min} = 0,01 \\cdot A_{c}$$',
                                contentType: TableCellContentType.LaTex
                            }
                        ]
                    }
                ]
            },
            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.Wall`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '$$A_{s,min} = 0,002 \\cdot A_{c}$$',
                                contentType: TableCellContentType.LaTex
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '$$A_{s,min} = 0,002 \\cdot A_{c}$$',
                                contentType: TableCellContentType.LaTex
                            }
                        ]
                    }
                ]
            },
            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.Single`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        colspan: 2,
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.Single.NoMinimum`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                ]
            }
        ]
    };

    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartOne` },
        { type: InfoDialogItemType.Table, value: new InfoDialogTable(table) },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartTwo` }
    ];
};

const getMinAreaReinforcementItemsAs = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabLoads.MinAreaReinforcementPopup';
    const table: IInfoDialogTable = {
        id: 'min-area-reinforcement-table',
        rows: [
            {
                isHeader: true,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Header.MinReinforcement`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Header.ForGeneralLc`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    }
                ]
            },
            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.Beam`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '$$A_{st,min} \\geq \\left [ \\alpha _{b}\\left ( D/d \\right )^{2} {f}\'_{ct.f}/f_{sy} \\right ]b_{w}d$$',
                                contentType: TableCellContentType.LaTex
                            }
                        ]
                    }
                ]
            },
            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.Slab`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '$$A_{st} \\geq \\left [ 0.24\\left ( D/d \\right )^{2} {f}\'_{ct.f}/f_{sy} \\right ]bd$$',
                                contentType: TableCellContentType.LaTex
                            }
                        ]
                    }
                ]
            },
            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.Column`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '$$A_{st} \\geq 0.01A_{g}$$',
                                contentType: TableCellContentType.LaTex
                            }
                        ]
                    }
                ]
            },
            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.Wall`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '$$p_{w}> 0.0025$$',
                                contentType: TableCellContentType.LaTex,
                                style: 'display: inline-block;'
                            },
                            {
                                value: ', if&nbsp;',
                                contentType: TableCellContentType.Constant,
                                style: 'display: inline-block;'
                            },
                            {
                                value: '$$\\frac{N}{b\\cdot D}> min(0.03{f}\'_{c};2MPa) $$',
                                contentType: TableCellContentType.LaTex,
                                style: 'display: inline-block;'
                            }
                        ]
                    }

                ]
            },
            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.Single`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.Single.NoMinimum`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                ]
            }
        ]
    };

    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartOne` },
        { type: InfoDialogItemType.Table, value: new InfoDialogTable(table) },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartTwo` }
    ];
};

const getShearDesignOptionInterfaceItems = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabLoads.InterfaceShearCheckPopup';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartOne` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartTwo` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartThree` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartFour` },
        { type: InfoDialogItemType.LineBreak },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartFive` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartSix` },
        { type: InfoDialogItemType.LineBreak },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartSeven` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartEight` }
    ];
};

const getShearDesignOptionInterfaceItemsAs = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabLoads.InterfaceShearCheckPopup';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.AS.Message.PartOne` },
        { type: InfoDialogItemType.LineBreak },
        { type: InfoDialogItemType.Text, value: `${translationKey}.AS.Message.PartTwo` },
        { type: InfoDialogItemType.LineBreak },
        { type: InfoDialogItemType.Text, value: `${translationKey}.AS.Message.PartThree` },
        { type: InfoDialogItemType.LineBreak },
        { type: InfoDialogItemType.Text, value: `${translationKey}.AS.Message.PartFour` },
        { type: InfoDialogItemType.LineBreak },
        { type: InfoDialogItemType.Text, value: `${translationKey}.AS.Message.PartFive` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.AS.Message.PartSix` },
    ];
};

const getRebarPosition = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabProduct.CalculationMode.RebarPosition';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartOne` },
        { type: InfoDialogItemType.LineBreak },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartTwo` },
        { type: InfoDialogItemType.LineBreak },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartThree` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartFour` },
        { type: InfoDialogItemType.LineBreak },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartFive` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartSix` }
    ];
};

const getSurfaceTreatment = (isSurfaceTreatmentDisabled: boolean): IInfoDialogItem[] => {
    if (isSurfaceTreatmentDisabled) {
        const titleTranslationKey = 'Agito.Hilti.C2C.TabExistingStructure.SurfaceTreatment.HiltiRebarRoughness';
        const contentTranslationKey = 'Agito.Hilti.C2C.TabExistingStructure.SurfaceTreatment.HiltiRebarRoughness.Tooltip.Text';
        return [
            { type: InfoDialogItemType.Title, value: `${titleTranslationKey}` },
            { type: InfoDialogItemType.Text, value: `${contentTranslationKey}` }
        ];
    }

    const titleTranslationKey = 'Agito.Hilti.C2C.TabExistingStructure.SurfaceTreatment.HiltiRebarRoughness.Tooltip.Text.Title';
    const contentTranslationKey = 'Agito.Hilti.C2C.TabExistingStructure.SurfaceTreatment.HiltiRebarRoughness.Tooltip.Text.Content';
    const translationKey = 'Agito.Hilti.C2C.TabExistingStructure.SurfaceTreatment.HiltiRebarRoughness.Tooltip.Text.Table';
    const table: IInfoDialogTable = {
        id: 'surface-treatment-table',
        rows: [
            {
                isHeader: false,
                cells: [
                    createCell(`${translationKey}.Row1Cell1`, TableCellContentType.TranslatableString, 3, 3),
                    createCell(`${translationKey}.Row1Cell3`, TableCellContentType.TranslatableString, 2),
                ]
            },
            {
                isHeader: false,
                cells: [
                    createCell('B<sub>f</sub>', TableCellContentType.Constant, 2),
                ]
            },
            {
                isHeader: false,
                cells: [
                    createCell(`${translationKey}.Row3Cell3`, TableCellContentType.TranslatableString),
                    createCell(`${translationKey}.Row3Cell4`, TableCellContentType.TranslatableString),
                ]
            },
            {
                isHeader: false,
                cells: [
                    createCell(`${translationKey}.Row4Cell1`, TableCellContentType.TranslatableString, 3),
                    createCell('0.8', TableCellContentType.Constant),
                    createCell('0.4', TableCellContentType.Constant),
                ]
            },
            {
                isHeader: false,
                cells: [
                    createCell(`${translationKey}.Row5Cell1`, TableCellContentType.TranslatableString, 3),
                    createCell('0.8', TableCellContentType.Constant),
                    createCell('0.4', TableCellContentType.Constant),
                ]
            },
            {
                isHeader: false,
                cells: [
                    createCell(`${translationKey}.Row6Cell1`, TableCellContentType.TranslatableString, 1, 2),
                    createCell(`${translationKey}.Row6Cell2`, TableCellContentType.TranslatableString, 2),
                    createCell('0.6', TableCellContentType.Constant),
                    createCell('0.2', TableCellContentType.Constant),
                ]
            },
            {
                isHeader: false,
                cells: [
                    createCell(`${translationKey}.Row7Cell2`, TableCellContentType.TranslatableString, 2),
                    createCell('0.4', TableCellContentType.Constant),
                    createCell('0.1', TableCellContentType.Constant),
                ]
            },
            {
                isHeader: false,
                cells: [
                    createCell(`${translationKey}.Row8Cell1`, TableCellContentType.TranslatableString, 1, 2),
                    createCell(`${translationKey}.Row8Cell2`, TableCellContentType.TranslatableString, 2),
                    createCell('0.5', TableCellContentType.Constant),
                    createCell('0.2', TableCellContentType.Constant),
                ]
            },
            {
                isHeader: false,
                cells: [
                    createCell(`${translationKey}.Row9Cell2`, TableCellContentType.TranslatableString, 2),
                    createCell('0.4', TableCellContentType.Constant),
                    createCell('0.1', TableCellContentType.Constant),
                ]
            },
            {
                isHeader: false,
                cells: [
                    createCell(`${translationKey}.Row10Cell1`, TableCellContentType.TranslatableString, 3),
                    createCell('0.2', TableCellContentType.Constant),
                    createCell('n/a', TableCellContentType.Constant),
                ]
            },
        ]
    };

    function createCell(cellValue: string, cellType: TableCellContentType, colSpan = 1, rowSpan = 1) {
        return {
            colspan: colSpan,
            rowspan: rowSpan,
            contents: [
                {
                    value: cellValue,
                    contentType: cellType
                }
            ]
        };
    }

    return [
        { type: InfoDialogItemType.Title, value: `${titleTranslationKey}` },
        { type: InfoDialogItemType.Text, value: `${contentTranslationKey}` },
        { type: InfoDialogItemType.LineBreak },
        { type: InfoDialogItemType.Table, value: new InfoDialogTable(table) },
    ];
};

const getSurfaceRoughnessItems = (isGermanNationalAnnex: boolean): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabConcrete.SurfaceRoughnessPopup';
    const table: IInfoDialogTable = {
        id: 'surface-roughness-table',
        rows: [
            {
                isHeader: true,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Header.Roughness`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Header.Description`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: 'c',
                                contentType: TableCellContentType.Constant
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '&#181;',
                                contentType: TableCellContentType.Constant
                            }
                        ]
                    }
                ]
            },
            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.VerySmooth`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.VerySmooth.Description`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: isGermanNationalAnnex ? '0.00' : '0.025',
                                contentType: TableCellContentType.Constant
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '0.5',
                                contentType: TableCellContentType.Constant
                            }
                        ]
                    }
                ]
            },
            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.Smooth`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.Smooth.Description`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '0.20',
                                contentType: TableCellContentType.Constant
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '0.6',
                                contentType: TableCellContentType.Constant
                            }
                        ]
                    }
                ]
            },
            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.Rough`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.Rough.Description`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '0.4',
                                contentType: TableCellContentType.Constant
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '0.7',
                                contentType: TableCellContentType.Constant
                            }
                        ]
                    }
                ]
            },
            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.Indented`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.Indented.Description`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '0.5',
                                contentType: TableCellContentType.Constant
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '0.9',
                                contentType: TableCellContentType.Constant
                            }
                        ]
                    }
                ]
            }
        ]
    };

    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartOne` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartTwo` },
        { type: InfoDialogItemType.Table, value: new InfoDialogTable(table) },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.PartThree` }
    ];
};

const getSurfaceRoughnessItemsAsAs = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabConcrete.SurfaceRoughnessPopup.AS';

    const table: IInfoDialogTable = {
        id: 'surface-roughness-table',
        rows: [
            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Header.Roughness`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ],
                        rowspan: 2,
                        style: 'border-bottom: none;'
                    },
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Header.Coefficients`,
                                contentType: TableCellContentType.TranslatableString,
                            }
                        ],
                        colspan: 2,
                        style: 'border: 1px solid black;border-right: none;'
                    }
                ],
                style: 'border-top: 4px solid black;font-weight: bold;'
            },
            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: '<i>&#181;</i>',
                                contentType: TableCellContentType.Constant
                            }
                        ],
                        style: 'border: 1px solid black;border-bottom: none;'
                    },
                    {
                        contents: [
                            {
                                value: '<i>k<sub>co</sub></i>',
                                contentType: TableCellContentType.Constant
                            }
                        ],
                        style: 'border: 1px solid black;border-bottom: none;border-right: none;'
                    }
                ],
                style: 'border: none;background-color: transparent;font-weight: bold;'
            },
            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.Smooth.Description`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ],
                        style: 'text-align: left;line-height: 1.5;'
                    },
                    {
                        contents: [
                            {
                                value: '0.6',
                                contentType: TableCellContentType.Constant
                            }
                        ],
                        style: 'border-left: 1px solid black;border-right: 1px solid black;'
                    },
                    {
                        contents: [
                            {
                                value: '0.1',
                                contentType: TableCellContentType.Constant
                            }
                        ]
                    }
                ],
                style: 'border: none;background-color: transparent;'
            },
            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.VerySmooth.Description`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ],
                        style: 'text-align: left;line-height: 1.5;'
                    },
                    {
                        contents: [
                            {
                                value: '0.6',
                                contentType: TableCellContentType.Constant
                            }
                        ],
                        style: 'border-left: 1px solid black;border-right: 1px solid black;'
                    },
                    {
                        contents: [
                            {
                                value: '0.2',
                                contentType: TableCellContentType.Constant
                            }
                        ]
                    }
                ],
                style: 'border: none;background-color: transparent;'
            },
            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.Rough.Description`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ],
                        style: 'text-align: left;line-height: 1.5;'
                    },
                    {
                        contents: [
                            {
                                value: '0.7',
                                contentType: TableCellContentType.Constant
                            }
                        ],
                        style: 'border-left: 1px solid black;border-right: 1px solid black;'
                    },
                    {
                        contents: [
                            {
                                value: '0.4',
                                contentType: TableCellContentType.Constant
                            }
                        ]
                    }
                ],
                style: 'border: none;background-color: transparent;'
            },
            {
                isHeader: false,
                cells: [
                    {
                        contents: [
                            {
                                value: `${translationKey}.Table.Data.MechanicalConcreteShearKey.Description`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ]
                    },
                    {
                        contents: [
                            {
                                value: '0.9',
                                contentType: TableCellContentType.Constant
                            }
                        ],
                        style: 'border-left: 1px solid black;border-right: 1px solid black;'
                    },
                    {
                        contents: [
                            {
                                value: '0.5',
                                contentType: TableCellContentType.Constant
                            }
                        ]
                    }
                ],
                style: 'border: none;border-bottom: 4px solid black;background-color: transparent;'
            },
        ]
    };

    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title`},
        { type: InfoDialogItemType.Text, value: `${translationKey}.TableTitle`, style: 'font-size: 1.1rem;font-weight: bold;text-align: center;text-transform: uppercase;' },
        { type: InfoDialogItemType.Table, value: new InfoDialogTable(table) },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Note` },
        { type: InfoDialogItemType.ThematicBreak },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Reference`, style: 'font-weight: bold;text-align: center;' },
    ];

};

const getTransverseReinforcementDefineItems = (isPirASAS: boolean): IInfoDialogItem[] => {

    const translationKey = 'Agito.Hilti.C2C.Navigation.TabExistingReinforcement.TransverseReinforcementDefineInfoPopup';

    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title`},
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message`, appendText: `\u00A0${isPirASAS ? 'AS3600 13.1.2.3.' : 'EN1992-1-1 8.7.4.'}` }
    ];
};

const getAlphaSustainedItems = (isPirASAS: boolean): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Loads.AlphaSus';
    const translationKeyDescription = isPirASAS
        ? `${translationKey}.Description.AS`
        : `${translationKey}.Description`;

    return [
        {
            type: InfoDialogItemType.Title,
            value: {
                translationString: `${translationKey}.Title`,
                constants: { '{alpha}': 'α' }
            },
            style: PREVENT_UPPERCASE_CONSTANTS_CLASS
        },
        {
            type: InfoDialogItemType.Text,
            value: `${translationKeyDescription}`
        }
    ];
};

const getSpliceClasses = (designStandard: DesignStandard, designMethodGroup: DesignMethodGroup): IInfoDialogItem[] => {
    const titleTranslationKey = 'Agito.Hilti.C2C.Overlay.Reinforcement.SpliceClasses';
    const contentTranslationKey = designStandard == DesignStandard.ACI && designMethodGroup != DesignMethodGroup.ACI31811 ?
        'Agito.Hilti.C2C.Overlay.Reinforcement.SpliceClasses.ACI.Tooltip' :
        'Agito.Hilti.C2C.Overlay.Reinforcement.SpliceClasses.CSA.Tooltip';

    return [
        { type: InfoDialogItemType.Title, value: `${titleTranslationKey}` },
        { type: InfoDialogItemType.Text, value: `${contentTranslationKey}` }
    ];
};

const getOpenDistanceCipPir = (designMethodGroup: DesignMethodGroup, designStandard: DesignStandard): IInfoDialogItem[] => {
    const titleTranslationKey = 'Agito.Hilti.C2C.Product.DistanceCipPir';
    const contentTranslationKey = 'Agito.Hilti.C2C.Product.DistanceCipPir.Text';

    return [
        { type: InfoDialogItemType.Title, value: `${titleTranslationKey}` },
        { type: InfoDialogItemType.Text, value: `${contentTranslationKey}.${designMethodGroupName(designMethodGroup, designStandard)}` },
    ];
};

const getCrackWidth = (): IInfoDialogItem[] => {
    const titleTranslationKey = 'Agito.Hilti.C2C.Navigation.TabLoads.CrackWidth.Title';
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabLoads.CrackWidthPopup';
    const greaterThenOrEqual08Cell = createCell('&ge; 0,8', TableCellContentType.Constant);
    const lesserThen08Cell = createCell('&lt; 0,8', TableCellContentType.Constant);
    const emptyCell = createCell('', TableCellContentType.Constant);
    const cell03 = createCell('0,3', TableCellContentType.Constant);
    const cell05 = createCell('0,5', TableCellContentType.Constant);
    const cell08 = createCell('0,8', TableCellContentType.Constant);
    const table: IInfoDialogTable = {
        id: 'crack-width-table',
        rows: [
            {
                isHeader: true,
                cells: [
                    createCell(`${translationKey}.Table.Header.DuctilityClass`, TableCellContentType.TranslatableString),
                    createCell(`${translationKey}.Table.Header.BehaviourFactor`, TableCellContentType.TranslatableString),
                    createCell('l<sub>b</sub> / h[-]', TableCellContentType.Constant),
                    createCell(`${translationKey}.Table.Header.AssumedCrackWidth`, TableCellContentType.TranslatableString),
                    createCell(`${translationKey}.Table.Header.Comment`, TableCellContentType.TranslatableString)
                ]
            },
            {
                isHeader: false,
                cells: [
                    createCell(`${translationKey}.Table.DCL`, TableCellContentType.TranslatableString),
                    createCell('1,0', TableCellContentType.Constant),
                    createCell(`${translationKey}.Table.All`, TableCellContentType.TranslatableString),
                    cell03,
                    createCell(`${translationKey}.Table.StaticDesignApplies`, TableCellContentType.TranslatableString),
                ]
            },
            {
                isHeader: false,
                cells: [
                    createCell(`${translationKey}.Table.DCM`, TableCellContentType.TranslatableString),
                    createCell('1,0 - 1,5', TableCellContentType.Constant),
                    greaterThenOrEqual08Cell,
                    cell03,
                    emptyCell
                ]
            },
            {
                isHeader: false,
                cells: [
                    emptyCell,
                    emptyCell,
                    lesserThen08Cell,
                    cell05,
                    emptyCell
                ]
            },
            {
                isHeader: false,
                cells: [
                    createCell(`${translationKey}.Table.DCM-DCH`, TableCellContentType.TranslatableString),
                    createCell('1,5 - 3,0', TableCellContentType.Constant),
                    greaterThenOrEqual08Cell,
                    cell05,
                    emptyCell
                ]
            },
            {
                isHeader: false,
                cells: [
                    emptyCell,
                    emptyCell,
                    lesserThen08Cell,
                    cell08,
                    emptyCell
                ]
            },
            {
                isHeader: false,
                cells: [
                    createCell(`${translationKey}.Table.DCM-DCH`, TableCellContentType.TranslatableString),
                    createCell('&gt; 3,0', TableCellContentType.Constant),
                    createCell('&ge; 0,8', TableCellContentType.Constant),
                    cell08,
                    emptyCell
                ]
            },
            {
                isHeader: false,
                cells: [
                    emptyCell,
                    emptyCell,
                    createCell('&lt; 0,8', TableCellContentType.Constant),
                    createCell(`${translationKey}.Table.NotCovered`, TableCellContentType.TranslatableString),
                    emptyCell
                ]
            }
        ]
    };

    function createCell(cellValue: string, cellType: TableCellContentType) {
        return {
            contents: [
                {
                    value: cellValue,
                    contentType: cellType
                }
            ]
        };
    }

    return [
        { type: InfoDialogItemType.Title, value: `${titleTranslationKey}` },
        { type: InfoDialogItemType.SubHeading, value: `${translationKey}.Subtitle` },
        { type: InfoDialogItemType.LineBreak },
        { type: InfoDialogItemType.Table, value: new InfoDialogTable(table) },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Note` },
        { type: InfoDialogItemType.LineBreak },
        { type: InfoDialogItemType.SubHeading, value: `${translationKey}.Footer` }
    ];
};

const getStrutAndTieItems = (modeLayout: StrutAndTieModeLayout): IInfoDialogItem[] => {
    const translationKey = 'Nolasoft.Hilti.Profis3.Report.STRUT_AND_TIE_MODEL';
    let imageToShow = '';

    switch (modeLayout) {
        case StrutAndTieModeLayout.FourTiesLeft:
            imageToShow = 'truss-four-ties-left';
            break;
        case StrutAndTieModeLayout.FourTiesRight:
            imageToShow = 'truss-four-ties-right';
            break;
        case StrutAndTieModeLayout.SixTiesLeft:
            imageToShow = 'truss-six-ties-left';
            break;
        case StrutAndTieModeLayout.SixTiesRight:
            imageToShow = 'truss-six-ties-right';
            break;
    }

    return [
        { type: InfoDialogItemType.Title, value: translationKey },
        { type: InfoDialogItemType.Image, value: infoDialogImages[imageToShow] }
    ];
};

const getHiltiMethodRebarDesignItems = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabLoads.UseHiltiMethod';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message.Alternative` }
    ];
};

const getAnchoringBarYieldItems = (designStandard: DesignStandard): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.CodeList.DesignMethodEntity.AnchoringBarYield';
    const standardSuffix = designStandard == DesignStandard.CSA ? 'CSA' : 'ACI';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.PopUp.Text.${standardSuffix}` }
    ];
};

const getAnchoringExternalLoadsItems = (designStandard: DesignStandard): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.CodeList.DesignMethodEntity.AnchoringExternalLoads';
    const standardSuffix = designStandard == DesignStandard.CSA ? 'CSA' : 'ACI';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.PopUp.Text.${standardSuffix}` }
    ];
};

const getAllowResearchBasedItems = (projectDesign: ProjectDesignEntityC2C | undefined): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabLoads.RegionDesign.AllowResearchBased';

    const unorderedUnlockedMethodList = new InfoDialogUnorderedList({
        id: 'unlocked-methods',
        translationKeys: [
            `${translationKey}.PopUp.HiltiMethodRebarDesign`
        ]
    } as IInfoDialogUnorderedList);

    if (projectDesign?.options.connectionType == ConnectionType.ConcreteOverlay)
        unorderedUnlockedMethodList.addItem(`${translationKey}.PopUp.AnchoringYield`);

    if (projectDesign?.options.connectionType == ConnectionType.StructuralJoints) {
        unorderedUnlockedMethodList.addItem(`${translationKey}.PopUp.AnchoringYield`);
        unorderedUnlockedMethodList.addItem(`${translationKey}.PopUp.AnchoringExternalLoads`);
    }

    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.PopUp.MainText` },
        { type: InfoDialogItemType.LineBreak },
        { type: InfoDialogItemType.LineBreak },
        { type: InfoDialogItemType.Text, value: `${translationKey}.PopUp.UnlockedText` },
        { type: InfoDialogItemType.UnorderedList, value: unorderedUnlockedMethodList },
    ];
};

const getSpliceClassesAlternativeItemsACI31811 = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabLoads.SpliceClasses.PopUp.ACI31811';
    return [
        { type: InfoDialogItemType.Title, value: 'Agito.Hilti.C2C.Overlay.Reinforcement.SpliceClasses' },
        { type: InfoDialogItemType.Text, value: `${translationKey}.12.15` },
        { type: InfoDialogItemType.LineBreak },
        { type: InfoDialogItemType.Text, value: `${translationKey}.12.15.1` },
        { type: InfoDialogItemType.LineBreak },
        { type: InfoDialogItemType.Text, value: `${translationKey}.12.15.2` }
    ];
};

const getSpliceClassesAlternativeItemsACI31819 = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabLoads.SpliceClasses.PopUp.ACI31819';

    const table: IInfoDialogTable = {
        id: 'loads-spliceclasses-table',
        rows: []
    };

    table.rows = [
        {
            isHeader: false,
            cells: [
                {
                    contents: [
                        {
                            value: `${translationKey}.ColumnHeader1`,
                            contentType: TableCellContentType.TranslatableString
                        }
                    ]
                },
                {
                    contents: [
                        {
                            value: `${translationKey}.ColumnHeader2`,
                            contentType: TableCellContentType.TranslatableString
                        }
                    ]
                },
                {
                    contents: [
                        {
                            value: `${translationKey}.ColumnHeader3`,
                            contentType: TableCellContentType.TranslatableString
                        }
                    ]
                },
                {
                    colspan: 2,
                    contents: [
                        {
                            value: `${translationKey}.ColumnHeader4`,
                            contentType: TableCellContentType.TranslatableString
                        }
                    ]
                },
            ]
        },
        {
            isHeader: false,
            cells: [
                {
                    rowspan: 2,
                    contents: [
                        {
                            value: '≥ 2.0',
                            contentType: TableCellContentType.Constant
                        }
                    ]
                },
                {
                    contents: [
                        {
                            value: '50',
                            contentType: TableCellContentType.Constant
                        }
                    ]
                },
                {
                    contents: [
                        {
                            value: `${translationKey}.ClassA`,
                            contentType: TableCellContentType.TranslatableString
                        }
                    ]
                },
                {
                    contents: [
                        {
                            value: `${translationKey}.GreaterOf`,
                            contentType: TableCellContentType.TranslatableString
                        }
                    ]
                },
                {
                    contents: [
                        {
                            value: `${translationKey}.TableValue1`,
                            contentType: TableCellContentType.TranslatableString
                        }
                    ]
                },
            ]
        },
        {
            isHeader: false,
            cells: [
                {
                    contents: [
                        {
                            value: '100',
                            contentType: TableCellContentType.Constant
                        }
                    ]
                },
                {
                    contents: [
                        {
                            value: `${translationKey}.ClassB`,
                            contentType: TableCellContentType.TranslatableString
                        }
                    ]
                },
                {
                    rowspan: 2,
                    contents: [
                        {
                            value: `${translationKey}.GreaterOf`,
                            contentType: TableCellContentType.TranslatableString
                        }
                    ]
                },
                {
                    rowspan: 2,
                    contents: [
                        {
                            value: `${translationKey}.TableValue2`,
                            contentType: TableCellContentType.TranslatableString
                        }
                    ]
                },
            ]
        },
        {
            isHeader: false,
            cells: [
                {
                    contents: [
                        {
                            value: '< 2.0',
                            contentType: TableCellContentType.Constant
                        }
                    ]
                },
                {
                    contents: [
                        {
                            value: `${translationKey}.AllCases`,
                            contentType: TableCellContentType.TranslatableString
                        }
                    ]
                },
                {
                    contents: [
                        {
                            value: `${translationKey}.ClassB`,
                            contentType: TableCellContentType.TranslatableString
                        }
                    ]
                }
            ]
        },
    ];

    const result = [
        { type: InfoDialogItemType.Title, value: 'Agito.Hilti.C2C.Overlay.Reinforcement.SpliceClasses' },
        { type: InfoDialogItemType.Text, value: `${translationKey}.MainText` },
        { type: InfoDialogItemType.LineBreak },
        { type: InfoDialogItemType.Text, value: `${translationKey}.TableTitle` },
        { type: InfoDialogItemType.Table, value: new InfoDialogTable(table) },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Note` },
    ];

    return result;
};

const getSpliceClassesAlternativeItems = (designMethodGroup: DesignMethodGroup): IInfoDialogItem[] => {
    if (designMethodGroup == DesignMethodGroup.ACI31811)
        return getSpliceClassesAlternativeItemsACI31811();
    else if (designMethodGroup == DesignMethodGroup.ACI31814 || designMethodGroup == DesignMethodGroup.ACI31819)
        return getSpliceClassesAlternativeItemsACI31819();

    const translationKey = 'Agito.Hilti.C2C.Navigation.TabLoads.SpliceClasses.PopUp.CSA';
    return [
        { type: InfoDialogItemType.Title, value: 'Agito.Hilti.C2C.Overlay.Reinforcement.SpliceClasses' },
        { type: InfoDialogItemType.Text, value: `${translationKey}.12.15.1` },
        { type: InfoDialogItemType.LineBreak },
        { type: InfoDialogItemType.Text, value: `${translationKey}.12.15.2` }
    ];
};

const getConnectorPositionItems = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabProduct.RegionConnectorPosition.ConnectorPosition.Tooltip.Overlay';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Description` }
    ];
};

const getConcreteMaterialSettings = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Options.ConcreteMaterialSettings';

    const table: IInfoDialogTable = {
        id: 'concrete-material-settings-table',
        rows: [
            {
                isHeader:false,
                cells: [
                {
                        contents: [
                            {
                                value: 'n',
                                contentType: TableCellContentType.Constant
                            }
                        ],
                        style: 'border: none;text-align:left;padding-bottom: 0px;'
                    },
                    {
                        contents: [
                            {
                                value: `${translationKey}.List.First`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ],
                        style: 'border: none;text-align:left;padding-bottom: 0px;'
                    },
                ],
                style: 'background-color: transparent;'
            },
            {
                isHeader:false,
                cells: [
                {
                        contents: [
                            {
                                value: 'ε<sub>c2</sub>',
                                contentType: TableCellContentType.Constant
                            }
                        ],
                        style: 'border: none;text-align:left;padding-bottom: 0px;'
                    },
                    {
                        contents: [
                            {
                                value: `${translationKey}.List.Second`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ],
                        style: 'border: none;text-align:left;padding-bottom: 0px;'
                    },
                ],
                style: 'background-color: transparent;'
            },
            {
                isHeader:false,
                cells: [
                {
                        contents: [
                            {
                                value: 'ε<sub>cu2</sub>',
                                contentType: TableCellContentType.Constant
                            }
                        ],
                        style: 'border: none;text-align:left;padding-bottom: 0px;'
                    },
                    {
                        contents: [
                            {
                                value: `${translationKey}.List.Third`,
                                contentType: TableCellContentType.TranslatableString
                            }
                        ],
                        style: 'border: none;text-align:left;padding-bottom: 0px;'
                    },
                ],
                style: 'border: none;text-align:left;'
            }
        ],
    };

    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Description1` },
        { type: InfoDialogItemType.Image, value: infoDialogImages['concrete-material-settings-equation']  },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Description2` },
        { type: InfoDialogItemType.Table, value: new InfoDialogTable(table), style:'border:none' },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Description3` },
        { type: InfoDialogItemType.Image, value: infoDialogImages['concrete-material-settings-graph']  },
        { type: InfoDialogItemType.SubHeading, value: `${translationKey}.Legend` },
    ];
};

const getNetCompressionItems = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabNewConcrete.CalculationData.NetCompression.CSA.Tooltip.Text';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Description.First` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Description.Second` },
        { type: InfoDialogItemType.Image, value: infoDialogImages['permanent-load-csa']  },
    ];
};

const getLapSpliceLengthItems = (): IInfoDialogItem[] => {
    const translationKey = 'Agito.Hilti.C2C.Navigation.TabLoads.LapSpliceLength';
    return [
        { type: InfoDialogItemType.Title, value: `${translationKey}.Title` },
        { type: InfoDialogItemType.Text, value: `${translationKey}.Message` }
    ];
};

const commandMethodDictionary: { [id: string]: (commandAdditionalData: CommandAdditionalData) => IInfoDialogItem[] } = {
    'OpenContactSurfaceConditionPopup': ({ designMethodGroup, designStandard }) => getContactSurfaceConditionItems(designMethodGroup, designStandard),
    'OpenEpoxyCoatedReinforcementPopup': ({ designMethodGroup, designStandard }) => getRebarEpoxyCoatedItems(designMethodGroup, designStandard, true),
    'OpenLocationFactorPopup': ({ designMethodGroup, designStandard }) => getLocationFactorItems(designMethodGroup, designStandard),
    'OpenCastInSplicedReinforcementPopup': ({ designMethodGroup, designStandard }) => getCastInSplicedReinforcementItems(designMethodGroup, designStandard),
    'OpenDetailedDefinitionPopup': ({ designMethodGroup, designStandard }) => getDetailedDefinitionItems(designMethodGroup, designStandard),
    'OpenOptimizedValuesPopup': ({ designMethodGroup, designStandard }) => getOptimizedValuesItems(designMethodGroup, designStandard),
    'OpenUseFullCrossSectionPopup': getUseFullCrossSectiontems,
    'OpenRebarEpoxyCoatedPopup': ({ designMethodGroup, designStandard }) => getRebarEpoxyCoatedItems(designMethodGroup, designStandard),
    'OpenSpliceToExistingReinforcementPopup': getSpliceToExistingReinforcementItems,
    'OpenOverstrengthPopup': ({ designStandard, projectDesign }) => getOverstrengthItems(projectDesign?.options.connectionType ?? ConnectionType.Unknown, designStandard, projectDesign?.loads.specialMomentsFrame ?? false),
    'OpenCompressionConfinementPopup': ({ designMethodGroup, designStandard }) => getCompressionConfinementItems(designMethodGroup, designStandard),
    'OpenPhiTensionPopup': ({ designMethodGroup, designStandard }) => getPhiTensionItems(designMethodGroup, designStandard),
    'OpenBondBreakoutPopup': ({ designMethodGroup, designStandard }) => getBondBreakoutItems(designMethodGroup, designStandard),
    'OpenIsYieldDesignPopup': ({ designMethodGroup, designStandard, projectDesign }) => getIsYieldDesignItems(designMethodGroup, designStandard, projectDesign?.options.connectionType == ConnectionType.ConcreteOverlay),
    'OpenAnchorTheoryDataPopup': ({ designMethodGroup, designStandard }) => getAnchorTheoryDataItems(designMethodGroup, designStandard),
    'OpenPermanentNetCompressionPopup': ({ designMethodGroup }) => getPermanentNetCompressionItems(designMethodGroup),
    'OpenRatioBInfoBox': getRatioBInfoItems,
    'OpenConcreteDensityPopup': ({ designMethodGroup, designStandard }) => getConcreteDensityItems(designMethodGroup, designStandard),
    'OpenTensileStrengthPopup': ({ designMethodGroup, designStandard }) => getTensileStrengthItems(designMethodGroup, designStandard),
    'OpenExtensionAtSupportPopup': getExtensionAtSupportItems,
    'OpenContinuousInXPopup': getContinuousInXDirectionItems,
    'OpenCrackedConcretePopup': getCrackedConcreteItems,
    'OpenDesignForYieldPopup': ({ isPirASAS }) => getDesignForYieldItems(isPirASAS),
    'OpenShearDesignOptionDeltaFsPopup': ({ isPirASAS }) => getShearDesignOptionDeltaFs(isPirASAS),
    'OpenSurfaceReinforcementInfoPopup': ({ isPirASAS }) => getSurfaceReinforcementInfo(isPirASAS),
    'OpenMaxAreaReinforcementPopup': getMaxAreaReinforcementItems,
    'OpenShearDesignOptionInterfacePopup': ({ isPirASAS }) => isPirASAS ? getShearDesignOptionInterfaceItemsAs() : getShearDesignOptionInterfaceItems(),
    'OpenSurfaceRoughnessPopup': ({ isPirASAS, projectDesign }) => isPirASAS ? getSurfaceRoughnessItemsAsAs() : getSurfaceRoughnessItems(projectDesign?.loads.useGermanNationalAnnex ?? false),
    'OpenMinAreaReinforcementItemsC2C': ({ isPirASAS }) => isPirASAS ? getMinAreaReinforcementItemsAs() : getMinAreaReinforcementItems(),
    'OpenAdditionalOffsetDistancePopup': getAdditionalOffsetDistanceItems,
    'OpenFireResistanceDurationPopup': ({ designStandard, projectDesign }) => getFireResistanceDuration(designStandard, projectDesign?.options.applicationType ?? ApplicationType.Unknown, projectDesign?.loads.fireApplicationType ?? ApplicationType.Unknown, projectDesign?.application.isCustomDesign ?? false),
    'OpenDrillingAidPopup': getDrillingAidItems,
    'OpenTransverseReinforcementUnfavorableTolerancePopup': getUnfavorableTolerance,
    'OpenIncludeReinforcementCompressionPopup': getReinforcementCompression,
    'OpenDesignMethodGroupsSelectionPopup': ({ projectDesign, isPirASAS }) => getDesignMethodGroupsSelectionItems(projectDesign?.options.connectionType ?? ConnectionType.Unknown, isPirASAS),
    'OpenRebarPositionPopup': getRebarPosition,
    'OpenSurfaceTreatmentPopup': ({ isSurfaceTreatmentDisabled }) => getSurfaceTreatment(isSurfaceTreatmentDisabled),
    'OpenSpliceClassesPopup': ({ designStandard, designMethodGroup }) => getSpliceClasses(designStandard, designMethodGroup),
    'OpenCrackWidthPopup': getCrackWidth,
    'OpenDistanceCipPir': ({ designMethodGroup, designStandard }) => getOpenDistanceCipPir(designMethodGroup, designStandard),
    'OpenAnchorTheoryPopup': ({ designStandard }) => getAnchorTheoryItems(designStandard),
    'OpenRebarSpacingPopup': getRebarSpacing,
    'OpenStrutAndTiePopup': ({ reportData }) => getStrutAndTieItems(reportData?.strutAndTieModeLayout ?? StrutAndTieModeLayout.None),
    'OpenDesignMethodGroupsOverlayPopup': getDesignMethodGroupsOverlayItems,
    'OpenHiltiMethodRebarDesignPopup': getHiltiMethodRebarDesignItems,
    'OpenAnchoringBarYieldPopup': ({ designStandard }) => getAnchoringBarYieldItems(designStandard),
    'OpenAnchoringExternalLoadsPopup': ({ designStandard }) => getAnchoringExternalLoadsItems(designStandard),
    'OpenAllowResearchBasedPopup': ({ projectDesign }) => getAllowResearchBasedItems(projectDesign),
    'OpenSpliceClassesAlternativePopup': ({ designMethodGroup }) => getSpliceClassesAlternativeItems(designMethodGroup),
    'OpenConnectorPositionOverlayPopup': getConnectorPositionItems,
    'OpenTransverseReinforcementDefineInfoPopup': ({ isPirASAS }) => getTransverseReinforcementDefineItems(isPirASAS),
    'OpenConcreteMaterialSettingsInfoPopup': getConcreteMaterialSettings,
    'OpenAlphaSustained': ({ isPirASAS }) => getAlphaSustainedItems(isPirASAS),
    'OpenSustainability': getSustainability,
    'OpenNetCompressionPopup': getNetCompressionItems,
    'OpenLapSpliceLengthPopup': getLapSpliceLengthItems,
};