import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DesignEvent } from '@profis-engineering/pe-ui-common/entities/design';
import {
    IDesignSectionExportComponentInput, Loading
} from '@profis-engineering/pe-ui-common/entities/design-section-export';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import { Project } from '@profis-engineering/pe-ui-common/entities/project';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import {
    MODAL_DISMISS_REASON_BACKDROP, MODAL_DISMISS_REASON_ESC
} from '@profis-engineering/pe-ui-common/helpers/modal-helper';

import { environment } from '../../../environments/environmentC2C';
import { CommonRegion as Region } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import {
    ProjectDesignEntityC2C
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { DocumentHelper } from '../../helpers/document-helper';
import { ApiService } from '../../services/api.service';
import { BrowserService } from '../../services/browser.service';
import { CalculationServiceC2C } from '../../services/calculation-c2c.service';
import { DocumentServiceC2C } from '../../services/document.service';
import { ImportService } from '../../services/import.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { OfflineService } from '../../services/offline.service';
import { QueryService } from '../../services/query.service';
import { TranslationFormatService } from '../../services/translation-format.service';
import { TrimbleConnectService } from '../../services/trimble-connect.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { getSpriteAsIconStyle, includeSprites } from '../../sprites';
import { IExportReportSupportMethods } from '../export-report/export-report.component';

@Component({
    templateUrl: './design-section.component.html',
    styleUrls: ['./design-section.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class DesignSectionComponent implements OnInit {
    @Input()
    public exportReportSupportMethods!: IExportReportSupportMethods;

    @ViewChild('openDesignInput')
    public inputElement!: ElementRef<HTMLInputElement>;

    public loading = false;
    public duplicating = false;
    public openDesign = false;

    private designSectionExportInput!: IDesignSectionExportComponentInput;

    constructor(
        public localizationService: LocalizationService,
        public offlineService: OfflineService,
        private userService: UserService,
        private modalService: ModalService,
        private documentService: DocumentServiceC2C,
        private importService: ImportService,
        private apiService: ApiService,
        private browser: BrowserService,
        private queryService: QueryService,
        private calculationServiceC2C: CalculationServiceC2C,
        private elementRef: ElementRef<HTMLElement>,
        private trimbleConnect: TrimbleConnectService,
        private userSettingsService: UserSettingsService,
        private translationFormatService: TranslationFormatService
    ) { }

    public get design() {
        return this.userService.design;
    }

    public get generateReportDisabled() {
        if (this.design.isTemplate) {
            return true;
        }

        return this.design.pendingCalculation || !this.design.designData.reportDataC2C?.canExportReport;
    }

    public get isStateChanged() {
        return this.design.isStateChanged;
    }

    public get isDesignInReadOnlyMode() {
        return this.design.isTemplate || this.design.isReadOnlyDesignMode;
    }

    private get specificationTextTooltip() {
        return this.localizationService.getString('Agito.Hilti.Profis3.SpecificationText.Tooltip');
    }

    private get specificationTextDisabled() {
        return !this.userService.design.designData.reportDataC2C?.specificationText;
    }

    private get specificationTextTooltipEnabled() {
        return this.specificationTextDisabled && !this.isCustomSpecificationText;
    }

    private get isCustomSpecificationText() {
        if (this.onlineTenderTextsUrl != null) {
            return true;
        }

        return false;
    }

    public get specificationTextKey() {
        if (this.isCustomSpecificationText) {
            return 'Agito.Hilti.Profis3.Region.Link.OnlineTenderTextsUrl';
        }

        return 'Agito.Hilti.Profis3.DesignSectionExport.SpecificationText';
    }

    public get specificationTextDescriptionKey() {
        if (!this.isCustomSpecificationText) {
            return 'Agito.Hilti.Profis3.SpecificationText.Tooltip';
        }

        return '';
    }

    private get onlineTenderTextsUrl() {
        return (this.userSettingsService
            .getRegionById(this.userSettingsService.settings.application.general.regionId.value as number) as Region)
            .onlineTenderTextsUrl;
    }

    private get fileToDeviceExportItem() {
        return {
            condition: () => { return !this.offlineService.isOffline; },
            buttonClick: () => {
                // C2C doesn't track DownloadSourceFile

                let ret = true;

                DocumentHelper.download(this.apiService, this.browser, this.localizationService, this.documentService, this.offlineService, this.design)
                    .then(() => {
                        this.design.usageCounterC2C.DesignExportedToDevice++;
                    })
                    .catch((err) => {
                        if (err instanceof Error) {
                            console.error(err);
                        }

                        ret = false;
                    });
                return ret;
            },
            buttonDisabled: () => { return this.design.pendingCalculation; },
            buttonLoading: Loading.localFile,
            imageClass: 'sprite-profis-file',
            imageStyle: getSpriteAsIconStyle('sprite-profis-file'),
            messageKey: 'Agito.Hilti.Profis3.DesignSectionExport.ProfisFile',
            descriptionKey: 'Agito.Hilti.Profis3.DesignSectionExport.ProfisFile.Description',
            hasLoading: false
        };
    }

    private get fileToTrimbleConnectExportItem() {
        return {
            condition: () => { return true; },
            tooltip: this.localizationService.getString('Agito.Hilti.Profis3.Browser.NoInternetConnection'),
            tooltipDisabled: !this.trimbleConnect.isEnabled || this.browser.isOfflineOnLine || true /* C2C-1667: Trimble connect disabled for C2C for now */,
            buttonClick: () => {
                let ret = true;
                DocumentHelper.generateBlob(this.design, this.apiService, this.browser, this.documentService)
                    .then(projectDesign => {
                        this.design.usageCounterC2C.DesignExportedToTrimple++;
                        this.trimbleConnect.uploadDesign(projectDesign)
                            .catch(err =>
                                (err != 'cancel-click' && err != MODAL_DISMISS_REASON_ESC && err != MODAL_DISMISS_REASON_BACKDROP)
                                    ? console.error(err)
                                    : undefined
                            );
                    }, () => null)
                    .catch((err) => {
                        if (err instanceof Error) {
                            console.error(err);
                        }

                        ret = false;
                    });
                return ret;
            },
            buttonDisabled: () => {
                return !this.trimbleConnect.isEnabled || !this.browser.isOfflineOnLine || true /* C2C-1667: Trimble connect disabled for C2C for now */;
            },
            buttonLoading: Loading.trimbleConnect,
            imageClass: 'sprite-trimble-file',
            imageStyle: getSpriteAsIconStyle('sprite-trimble-file'),
            messageKey: 'Agito.Hilti.Profis3.DesignSectionExport.TrimbleConnect',
            descriptionKey: 'Agito.Hilti.Profis3.DesignSectionExport.TrimbleConnect.Description' + (this.offlineService.isOffline ? '.Offline' : ''),
            hasLoading: true
        };
    }

    private get ausscheibungsTextExportItem() {
        return {
            condition: () => { return true; },
            tooltip: this.specificationTextTooltip,
            tooltipDisabled: this.specificationTextTooltipEnabled,
            buttonClick: () => {
                let ret = true;
                this.design.usageCounterC2C.SpecificationTextExport++;

                if (this.isCustomSpecificationText) {
                    const tab = window.open(this.onlineTenderTextsUrl, '_blank');
                    if (tab != null) {
                        tab.focus();
                    }

                    ret = false;
                }
                else {
                    // Remove the template when the design is C2C HNA, as it's only used directly by the report service.
                    if (this.userService.design.isC2CHNA &&
                        this.userService.design.designData.reportDataC2C &&
                        this.userService.design.designData.reportDataC2C.specificationText &&
                        this.userService.design.designData.reportDataC2C.specificationText.template) {
                        this.userService.design.designData.reportDataC2C.specificationText.template = undefined;
                    }

                    const specificationText = this.translationFormatService.getLocalizedStringWithTranslationFormat(this.userService.design.designData.reportDataC2C?.specificationText);

                    this.modalService.openSpecificationText(specificationText ?? '');

                    setTimeout(() => {
                        // do nothing
                    });
                }

                return ret;
            },
            buttonDisabled: () => { return this.specificationTextDisabled; },
            buttonLoading: this.isCustomSpecificationText ? undefined : Loading.specificationText,
            imageClass: 'prite-specification-text',
            imageStyle: getSpriteAsIconStyle('sprite-specification-text'),
            messageKey: this.specificationTextKey,
            descriptionKey: this.specificationTextDescriptionKey,
            hasLoading: false
        };
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-long-arrow-right-white',
            'sprite-save-design',
            'sprite-export-design',
            'sprite-export-design-desktop',
            'sprite-duplicate-design',
            'sprite-duplicate-design-desktop',
            'sprite-openfile-d-light',
            'sprite-openfile-desktop'
        );

        this.setDesignSectionExportInput();
    }

    public openExport() {
        this.modalService.openDesignSectionExport(this.designSectionExportInput);
    }

    public generateReport() {
        this.loading = true;

        this.openGenerateReportPopup();
    }

    public saveDesignOffline(): Promise<boolean> {
        const deferred = new Deferred<boolean>();
        if (this.design.isStateChanged) {
            DocumentHelper.download(this.apiService, this.browser, this.localizationService, this.documentService, this.offlineService, this.design, true).then((response) => {
                if (response != null) {
                    this.design.setSavedStateIdx();
                    deferred.resolve(true);
                }
                else {
                    deferred.resolve(false);
                }
            });
        }
        return deferred.promise;
    }

    public selectOpenFile() {
        if (this.openDesign) {
            return;
        }

        this.design.usageCounterC2C.ImportExistingDesign++;

        if (this.inputElement.nativeElement.value == null || this.inputElement.nativeElement.value == '') {
            setTimeout(() => {
                this.inputElement.nativeElement.click();
            });
        }
    }

    public importFileSelected() {
        if (this.inputElement.nativeElement.value != null
            && this.inputElement.nativeElement.value != ''
            && this.inputElement.nativeElement.files != null) {
            const file = this.inputElement.nativeElement.files[0];

            // clear input
            this.inputElement.nativeElement.value = '';

            this.saveChangesBeforeImport(file);
        }
    }

    public duplicateDesign() {
        if (this.offlineService.isOffline) {
            DocumentHelper.download(this.apiService, this.browser, this.localizationService, this.documentService, this.offlineService, this.design, false).then(() => {
                this.design.setSavedStateIdx();
            });
        }
        else {
            if (this.duplicating) {
                return;
            }

            this.duplicating = true;

            this.design.usageCounterC2C.DuplicateDesign++;

            const projectId = this.design.projectId;
            const projectDesign = this.design.designData.projectDesignC2C as ProjectDesignEntityC2C;
            projectDesign.designName += ' (1)';
            this.calculationServiceC2C.createAndOpenFromProjectDesignC2C(projectDesign, projectId, this.design.templateId)
                .finally(() => {
                    this.duplicating = false;
                })
                .then((design) => {
                    const openUrl = `${environment.baseUrl.replace(/\/+$/, '')}${UrlPath.main}${design.id}${this.queryService.getInitialQuery()}`;
                    window.open(openUrl, '_blank');

                    design.dispose();
                });
        }
    }

    private saveChangesBeforeImport(projectDesign: File | Blob, name?: string) {
        if (this.offlineService.isOffline && this.design.isStateChanged) {
            this.modalService.openConfirmChange({
                id: 'offline-design-perserve-changes',
                title: this.localizationService.getString('Agito.Hilti.Profis3.Main.PerserveDesignChangesOffline.Title'),
                message: this.localizationService.getString('Agito.Hilti.Profis3.Main.PerserveDesignChangesOffline.Message'),
                confirmButtonText: this.localizationService.getString('Agito.Hilti.Profis3.Main.PerserveDesignChangesOffline.Confirm'),
                cancelButtonText: this.localizationService.getString('Agito.Hilti.Profis3.Main.PerserveDesignChangesOffline.Cancel'),
                onConfirm: (modal) => {
                    modal.close();
                    this.saveDesignOffline().then((saved: boolean) => {
                        if (saved) {
                            this.import(projectDesign, name);
                        }
                        else {
                            this.saveChangesBeforeImport(projectDesign, name);
                        }
                    });
                },
                onCancel: (modal) => {
                    modal.close();
                    this.design.savedStateIdx = this.design.currentStateIdx;
                    this.import(projectDesign, name);
                }
            });
        } else {
            this.import(projectDesign, name);
        }
    }

    private import(projectDesign: File | Blob, name?: string) {
        this.openDesign = true;
        const currentProject = Object.values(this.documentService.projects).find((proj) => proj.id == this.design.projectId) as Project;
        return this.importService.import(currentProject, this.design, projectDesign, name as string, !this.offlineService.isOffline)
            .finally(() => {
                this.openDesign = false;
            });
    }

    private openGenerateReportPopup() {
        // CR: no comment for why is this timeout here
        setTimeout(() => {
            // TODO: ReportsCreated counter not implemented for C2C
            this.design.trigger(DesignEvent.exportReport);

            this.modalService.openExportReportC2C({
                createGlModelScreenshot2D: this.exportReportSupportMethods.createGlModelScreenshot2D,
                createGlModelScreenshot: this.exportReportSupportMethods.createGlModelScreenshot,
                createZoneDiagramScreenshot: this.exportReportSupportMethods.createZoneDiagramScreenshot,
                onModelImagesCreated: () => {
                    // do nothing
                }
            });

            this.loading = false;
        }, 1000);
    }

    private setDesignSectionExportInput() {
        this.designSectionExportInput = {
            exportItems: [
                // PROFIS file to device
                this.fileToDeviceExportItem,
                // PROFIS File to Trimble Connect
                this.fileToTrimbleConnectExportItem,
                // Ausscheibungstext
                this.ausscheibungsTextExportItem
            ]
        };
    }
}
