import { Component, ElementRef, EventEmitter, Input, Output, TrackByFunction, ViewEncapsulation } from '@angular/core';
import { includeSprites } from '../../sprites';

const MAX_ITEMS_WIDTH = 100;
const MAX_COLUMN_WIDTH = 20;

export interface IGridImageSelectItem {
    id?: number;
    image?: string;
    name?: string;
    disabled?: boolean;
    displayKey?: string;
    hidden?: boolean;
}

export interface IGridImageSelectProps {
    numberOfColumns?: number;
    isVertical?: boolean;
    items?: IGridImageSelectItem[];
    selectedValue?: number;
}

@Component({
    templateUrl: './grid-image-select.component.html',
    styleUrls: ['./grid-image-select.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class GridImageSelectComponent {

    @Input()
    public numberOfColumns!: number;

    @Input()
    public isVertical!: boolean;

    @Input()
    public items!: IGridImageSelectItem[];

    @Input()
    public selectedValue!: number;

    @Output()
    public selectedValueChange = new EventEmitter<number>();

    public trackItemById: TrackByFunction<IGridImageSelectItem> = ((_: number, item: IGridImageSelectItem) => {
        return item.id;
    });

    public select(item: IGridImageSelectItem) {
        if (!item || item.disabled) {
            return;
        }

        this.selectedValue = item.id ?? 0;
        this.selectedValueChange.emit(this.selectedValue);
    }

    public isFirstColumn(index: number) {
        return index % this.numberOfColumns == 0;
    }

    public isLastColumn() {
        return false;
    }

    public isLastRow() {
        return false;
    }

    public get itemsWidth(): number {
        // there is overlapping inside modal on the UI side if width is more than 100%.
        return this.numberOfColumns * MAX_COLUMN_WIDTH <= MAX_ITEMS_WIDTH
            ? this.numberOfColumns * MAX_COLUMN_WIDTH
            : MAX_ITEMS_WIDTH;
    }

    constructor(
        private elementRef: ElementRef<HTMLElement>
    ) {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-slab-to-slab',
            'sprite-beam-to-beam',
            'sprite-wall-extention',
            'sprite-column-extention',
            'sprite-beam-to-slab',
            'sprite-column-to-wall',
            'sprite-single',
            'sprite-wall-to-foundation',
            'sprite-column-to-foundation',
            'sprite-slab-to-wall',
            'sprite-beam-to-wall',
            'sprite-beam-to-column',
            'sprite-splices',
            'sprite-structural-joints'
        );
    }
}
