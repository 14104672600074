<pe-modal-header
id="design-consideration-dialog"
[modalInstance]="modalInstance"
[title]="translate('Agito.Hilti.C2C.DesignConsideration.Title')"
[closeButtonDisabled]="submitted"
[closeButtonId]="'design-consideration-close-button'">
</pe-modal-header>
<form class="form" name="form" #ngForm="ngForm" (ngSubmit)="save(ngForm)" novalidate>
<div class="modal-body">
   <div class="modal-body-content">
      <div *ngIf="this.design.isPirEu" class="connection-application-section">
        <label class="control-label">{{ translate('Agito.Hilti.C2C.Navigation.TabApplication.Connections') }}</label>
         <c2c-grid-image-select
         [numberOfColumns]="selectConnectionTypeC2C.numberOfColumns"
         [isVertical]="true"
         [items]="selectConnectionTypeC2C.items"
         [selectedValue]="selectConnectionTypeC2C.selectedValue"
         (selectedValueChange)="onSelectConnectionTypeC2C($any($event).detail)">
         </c2c-grid-image-select>
         <div class="application-types">
            <label class="control-label">{{ translate('Agito.Hilti.C2C.Navigation.TabApplication.Applications') }}</label>
            <c2c-grid-image-select
            [numberOfColumns]="selectApplicationTypeC2C.numberOfColumns"
            [isVertical]="true"
            [items]="selectApplicationTypeC2C.items"
            [selectedValue]="selectApplicationTypeC2C.selectedValue"
            (selectedValueChange)="onSelectApplicationTypeC2C($any($event).detail)">
            </c2c-grid-image-select>
         </div>
         <div class="check-box-info-button" *ngIf="isExtensionAtSupportVisible">
            <pe-checkbox-button *ngIf="isExtensionAtSupportVisible"
            [items]="isExtensionAtSupportCheckbox.items"
            [selectedValues]="isExtensionAtSupportCheckbox.selectedValues"
            (selectedValuesChange)="isExtensionAtSupportCheckbox.selectedValues = $any($event).detail"
            [disabled]="submitted">
            </pe-checkbox-button>
            <button type="button" (click)="infoExtensionAtSupportPopup()" *ngIf="isExtensionAtSupportVisible" class="info-button pe-ui-c2c-sprite pe-ui-c2c-sprite-info-tooltip"></button>
         </div>
         <div class="check-box-info-button" *ngIf="isXDirectionContinuousVisible">
            <pe-checkbox-button *ngIf="isXDirectionContinuousVisible"
            [items]="isXDirectionContinuousCheckbox.items"
            [selectedValues]="isXDirectionContinuousCheckbox.selectedValues"
            (selectedValuesChange)="isXDirectionContinuousCheckbox.selectedValues = $any($event).detail"
            [disabled]="submitted">
            </pe-checkbox-button>
            <button type="button" (click)="infoContinuousXDirectionPopup()" *ngIf="isXDirectionContinuousVisible" class="info-button pe-ui-c2c-sprite pe-ui-c2c-sprite-info-tooltip"></button>
         </div>
      </div>
      <div *ngIf="hideFireAndSeismicSection()" class="single-warning-msg">
         <span>{{ translate('Agito.Hilti.C2C.DesignConsideration.SingleRebarWarning') }}</span>
      </div>
      <div *ngIf="!hideFireAndSeismicSection()" [style.width]="this.design.isPirEu ? '70%' : '100%'" class="design-consideration-section">
         <div class="content-controls">
            <label class="control-label-radio">{{ fireResistanceRequirementRadio.title }}</label>
            <pe-radio-button
            [look]="fireResistanceRequirementRadio.look"
            [items]="fireResistanceRequirementRadio.items"
            [selectedValue]="fireResistanceRequirementRadio.selectedValue"
            (selectedValueChange)="onChangeFireResistanceRequirement($any($event).detail)"
            [disabled]="submitted || fireResistanceRequirementRadio.disabled">
            </pe-radio-button>
            <div [ngClass]="this.design.isPirHna ? 'hna-flex-row' : ''">
               <div *ngIf="this.design.isPirHna" class="hna-flex-item">
                  <div class="dropdown-control-warning-label">
                     <pe-dropdown class="dropdown" [id]="fireApplicationTypeDropdown.id" [title]="fireApplicationTypeDropdown.title"
                     [notSelectedText]="fireApplicationTypeDropdown.notSelectedText"
                     [items]="fireApplicationTypeDropdown.items"
                     [selectedValue]="fireApplicationTypeDropdown.selectedValue"
                     (selectedValueChange)="onChangeFireApplicationTypeDropdown($any($event).detail)"
                     [validators]="[requiredValidator]"
                     [disabled]="submitted || isFireResistanceDropdownDisabled">
                     </pe-dropdown>
                  </div>
                  <div id="extensionAtSupportHna" class="check-box-info-button" *ngIf="this.design.isHnaExtensionsC2C">
                     <pe-checkbox-button
                     [items]="isExtensionAtSupportCheckbox.items"
                     [selectedValues]="isExtensionAtSupportCheckbox.selectedValues"
                     (selectedValuesChange)="onChangeExtensionAtSupportCheckbox($any($event).detail)"
                     [disabled]="submitted || isFireResistanceDropdownDisabled">
                     </pe-checkbox-button>
                  </div>
               </div>
               <div class="dropdown-control-warning-label" [ngClass]="this.design.isPirHna ? 'hna-flex-item' : ''">
                  <pe-dropdown class="dropdown" [id]="fireResistanceDropdown.id" [title]="fireResistanceDropdown.title"
                  [notSelectedText]="fireResistanceDropdown.notSelectedText"
                  [items]="fireResistanceDropdown.items"
                  [selectedValue]="fireResistanceDropdown.selectedValue"
                  (selectedValueChange)="onChangeFireResistanceDropdown($any($event).detail)"
                  [validators]="[requiredValidator]"
                  [disabled]="isFireResistanceDropdownDisabled">
                  </pe-dropdown>
                  <span class="warning-label" [ngStyle]="{'color': showRequiredLabel ? 'red' : 'transparent'}">{{ translate('Agito.Hilti.C2C.DesignConsideration.FireResistanceRating.Warning') }}</span>
               </div>
            </div>
         </div>
         <div class="content-controls">
            <div class="seismic-conditions">
                <label class="control-label-radio">{{ seismicConditionsRadio.title }}</label>
               <pe-radio-button
               [look]="seismicConditionsRadio.look"
               [items]="seismicConditionsRadio.items"
               [selectedValue]="seismicConditionsRadio.selectedValue"
               (selectedValueChange)="seismicConditionsRadio.selectedValue = $any($event).detail"
               [disabled]="submitted || seismicConditionsRadio.disabled">
               </pe-radio-button>
            </div>
            <div *ngIf="this.design.isPirHna" id="seismicLoadMultiplierHna" class="seismic-load-multiplier-text-box">
               <pe-numeric-text-box
                  [id]="seismicLoadMultiplierProp.id"
                  [title]="seismicLoadMultiplierProp.title"
                  [value]="seismicLoadMultiplierProp.value"
                  (valueChange)="seismicLoadMultiplierProp.value = $any($event).detail"
                  [unit]="seismicLoadMultiplierProp.unit"
                  [minValue]="seismicLoadMultiplierProp.minValue"
                  [maxValue]="seismicLoadMultiplierProp.maxValue"
                  [decimalSeparator]="seismicLoadMultiplierProp.decimalSeparator"
                  [groupSeparator]="seismicLoadMultiplierProp.groupSeparator"
                  [precision]="seismicLoadMultiplierProp.precision"
                  [fixedDecimals]="seismicLoadMultiplierProp.fixedDecimals"
                  [required]="true"
                  [disabled]="submitted || !seismicConditionsRadio.selectedValue">
               </pe-numeric-text-box>
           </div>
         </div>
      </div>
   </div>
</div>
<pe-modal-footer>
   <pe-button
   [id]="'design-consideration-cancel-button'"
   [look]="'Default'"
   class="modal-button"
   (buttonClick)="close()"
   [text]="translate('Agito.Hilti.C2C.DesignConsideration.Cancel')"
   [disabled]="submitted">
   </pe-button>
   <pe-button
   [id]="'design-consideration-ok-button'"
   [look]="'Cta'"
   class="modal-button"
   type="submit"
   [disabled]="submitted || (ngForm.enabled && !ngForm.valid)">
   <pe-loading-button
   [loading]="submitted"
   [text]="translate('Agito.Hilti.C2C.DesignConsideration.OK')">
   </pe-loading-button>
   </pe-button>
</pe-modal-footer>
</form>
