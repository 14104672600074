import { Injectable } from '@angular/core';
import { Design, IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import { Project } from '@profis-engineering/pe-ui-common/entities/project';
import {
    DesignExternalMetaData,
    DocumentAccessMode, IDesignListItem
} from '@profis-engineering/pe-ui-common/services/document.common';

import {
    ProjectDesignEntityC2C
} from '../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Entities';
import { DocumentServiceBaseC2C } from '../../shared/services/document.service.base';
import { IDetailedDisplayDesign } from '@profis-engineering/pe-ui-common/entities/display-design';

@Injectable({
    providedIn: 'root'
})
export class DocumentServiceC2C extends DocumentServiceBaseC2C {
    protected baseService!: DocumentServiceBaseC2C;

    public setBaseService(baseService: DocumentServiceBaseC2C) {
        this.baseService = baseService;

        this.findProjectByDesignId = baseService.findProjectByDesignId.bind(baseService);
        this.findDesignById = baseService.findDesignById.bind(baseService);
        this.openDesignExclusiveC2C = baseService.openDesignExclusiveC2C.bind(baseService);
        this.findProjectById = baseService.findProjectById.bind(baseService);
        this.addDesignCommon = baseService.addDesignCommon.bind(baseService);
        this.updateDesignWithNewContentCommon = baseService.updateDesignWithNewContentCommon.bind(baseService);
        this.updateDesignThumbnailImage = baseService.updateDesignThumbnailImage.bind(baseService);
        this.publish = baseService.publish.bind(baseService);
        this.copyDesign = baseService.copyDesign.bind(baseService);
        this.moveDesign = baseService.moveDesign.bind(baseService);
        this.openDesignExclusive = baseService.openDesignExclusive.bind(baseService);
        this.getSessionKeyForDesign = baseService.getSessionKeyForDesign.bind(baseService);
        this.updateDesignWithNewContentC2C = baseService.updateDesignWithNewContentC2C.bind(baseService);
        this.openDesignC2C = baseService.openDesignC2C.bind(baseService);
        this.createUniqueName = baseService.createUniqueName.bind(baseService);
    }

    public get projects() {
        return this.baseService.projects;
    }

    public get draftsProject() {
        return this.baseService.draftsProject;
    }

    /* eslint-disable @typescript-eslint/no-unused-vars */
    public createUniqueName(_oldName: string, _usedNames: string[]): string {
        return '';
    }

    public openDesignC2C(_design: IBaseDesign): Promise<ProjectDesignEntityC2C> {
        return {} as Promise<ProjectDesignEntityC2C>;
    }

    public updateDesignWithNewContentC2C(_designId: string, _projectId: string, _designName: string, _contentOverride: ProjectDesignEntityC2C, _metadataOverride: DesignExternalMetaData, _displayDesign?: IDetailedDisplayDesign, _unlock?: boolean, _exclusiveLock?: boolean, _documentAccessMode?: DocumentAccessMode): Promise<void> {
        return {} as Promise<void>;
    }

    public findProjectByDesignId(_designId: string) {
        return {} as Project;
    }

    public uploadDesignImage(_designId: string, _imageContent: string, _thumbnailContent: string): Promise<void> {
        return {} as Promise<void>;
    }

    public findDesignById(_designId: string): IDesignListItem {
        return {} as IDesignListItem;
    }

    public openDesignExclusiveC2C(_design: IBaseDesign): Promise<ProjectDesignEntityC2C> {
        return {} as Promise<ProjectDesignEntityC2C>;
    }

    public findProjectById(_projectId: string): Project {
        throw new Error('Method not implemented.');
    }

    public addDesignCommon(_projectId: string, _design: Design, _canGenerateUniqueName: boolean, _ignoreConflict: boolean): Promise<IDesignListItem> {
        throw new Error('Method not implemented.');
    }

    public updateDesignWithNewContentCommon(design: Design<unknown>, displayDesign: unknown, unlock: boolean, exclusiveLock: boolean, documentAccessMode?: DocumentAccessMode): Promise<void> {
        throw new Error('Method not implemented.');
    }

    public updateDesignThumbnailImage(designId: string, thumbnailContent: string, respond: boolean): Promise<void> {
        throw new Error('Method not implemented.');
    }

    public publish(id: string): Promise<void> {
        throw new Error('Method not implemented.');
    }

    public copyDesign(documentId: string, documentName: string, projectId: string): Promise<void> {
        throw new Error('Method not implemented.');
    }

    public moveDesign(documentId: string, projectId: string): Promise<void> {
        throw new Error('Method not implemented.');
    }

    public openDesignExclusive<TProjectDesign>(_design: IBaseDesign, _adjustContent?: (content: TProjectDesign, designName: string, projectName: string) => TProjectDesign): Promise<TProjectDesign> {
        throw new Error('Method not implemented.');
    }

    public getSessionKeyForDesign(_designId: string): string {
        throw new Error('Method not implemented.');
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
