import { IControlProps } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { TooltipType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';

import { MainMenuCommandC2C } from '../../../shared/entities/command';
import { DesignC2C } from '../../../shared/entities/design-c2c';
import {
    ConnectionType, DesignMethodGroup, DesignStandard
} from '../../../shared/generated-modules/Hilti.PE.CalculationService.Shared.Enums';

export function isPirEU(design: DesignC2C) {
    return isPir(design) && (isETAG(design) || isASEN(design));
}

export function isOverlayEU(design: DesignC2C) {
    return isOverlay(design) && isETAG(design);
}

export function isPir(design: DesignC2C) {
    return (checkConnectionType(design, ConnectionType.Splices) || checkConnectionType(design, ConnectionType.StructuralJoints));
}

export function isPirAS(design: DesignC2C) {
    return isPir(design) && isASBased(design);
}

export function checkConnectionType(design: DesignC2C, connectionType: ConnectionType) {
    return design.connectionType == connectionType;
}

export function isETAG(design: DesignC2C) {
    return design.designStandardC2C?.id == DesignStandard.ETAG;
}

export function isASEN(design: DesignC2C) {
    return design.designStandardC2C?.id == DesignStandard.ASBased && design.designMethodGroup?.id == DesignMethodGroup.EN199211;
}

export function isACICSA(design: DesignC2C) {
    if (design.designStandardC2C?.id == null) {
        return false;
    }

    return [DesignStandard.ACI, DesignStandard.CSA].includes(design.designStandardC2C.id);
}

export function isASBased(design: DesignC2C) {
    return design.designStandardC2C?.id == DesignStandard.ASBased;
}

export function isCSA(design: DesignC2C) {
    return design.designStandardC2C?.id == DesignStandard.CSA;
}

export function isPirHNA(design: DesignC2C) {
    return isPir(design) && isACICSA(design);
}

export function isOverlay(design: DesignC2C) {
    return checkConnectionType(design, ConnectionType.ConcreteOverlay);
}

export function getPopupTooltipConditional(condition: boolean, alternativeTooltip?: TooltipType) {
    if (condition) {
        return TooltipType.Popup;
    }
    return alternativeTooltip;
}

export function getCommandConditional(command: MainMenuCommandC2C, condition: boolean, alternativeCommand?: MainMenuCommandC2C) {
    if (condition) {
        return command;
    }
    return alternativeCommand;
}

export function updateMainMenuControl<TControl extends IControlProps>(menu: IMenu, id: string, control: Partial<TControl>): IMenu {
    return {
        ...menu,
        controls: {
            ...menu.controls,
            [id]: {
                ...menu.controls[id],
                ...control
            }
        }
    };
}
